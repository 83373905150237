import http from '../../../helper/http';
import { Api_request } from '../../global/constants';

export const getProductDetail = async (id, success) => {
  const result = await http.get(Api_request + `/product/${id}`);
  if (result && result.data.errcode === 0) {
    success(result.data.data);
  }
};

export const saveOrderInfo = async (params, success) => {
  const result = await http.post(Api_request + `/order/client`, params);
  if (result && result.data.errcode === 0) {
    success(result.data.data);
  }
};

export const getPrepare = async (params, success) => {
  const result = await http.get(Api_request + `/pay/prepare`, { params: params });
  if (result && result.data.errcode === 0) {
    success(result.data.data);
  }
};
