import React, {useState, useEffect} from 'react';
import intl from 'react-intl-universal';
import {connect} from 'react-redux';
import {Button, Input, Row, Col, Modal, message, Select, TreeSelect, Spin} from 'antd';
import useTable from '../../../hooks/useTable';
import ConditionPanel from '../../..//components/ConditionPanel';
import CommonTable from '../../../components/CommonTable';
import TemplateForm from '../../common/TemplateForm';
import {useParams} from '../../../hooks/utils';
import {USE_STATUS} from '../../global/constants';
import moment from 'moment';
import {isMobileBrowser} from '../../../helper/until';
import {createRole, updateRole, delRole, getRoleMenus, saveRoleMenus} from './service';
import {getAllMenus} from '../../service';

const {SHOW_ALL} = TreeSelect;
const RoleList = (props) => {
    const [actionLoading, setActionLoading] = useState(false);
    const [allMenus, setAllMenus] = useState([]);
    const [roleMenusIds, setRoleMenusIds] = useState([]);
    const [visible, setVisible] = useState(false);
    const [type, setType] = useState('add');
    const [name, setName] = useState('');
    const [param, updateParam] = useParams({
        name: '',
        use_status: true,
    });
    const {tableProps, search, selectedKeys, data} = useTable(
        {
            url: '/role',
            params: {
                name: name,
            },
        },
        {
            pagination: true,
            noFetch: true,
            rowSelection: true,
        }
    );

    useEffect(() => {
        getAllMenus((data) => {
            setAllMenus(data);
        });
        // message.warning("static:"+props.userInfo.is_station)
        // message.warning("company:"+props.userInfo.is_company_user)
        // message.warning("channel:"+props.userInfo.is_channel_user)
    }, []);

    const columns = [
        {title: intl.get('name'), dataIndex: 'name', key: 'name'},
        {
            title: intl.get('status'),
            dataIndex: 'use_status',
            key: 'use_status',
            render: (t, r) =>
                <span>{USE_STATUS.properties[t ? 1 : 0] ? intl.get(USE_STATUS.properties[t ? 1 : 0].text) : '--'}</span>,
        },
        {
            title: intl.get('time_create'),
            dataIndex: 'time_create',
            key: 'time_create',
            render: (t) => <span>{t ? moment(t).format('YYYY-MM-DD HH:mm') : '--'}</span>,
        },
        {title: intl.get('user_create'), dataIndex: 'user_name_create', key: 'user_name_create'},
        {
            title: intl.get('operation'),
            dataIndex: 'action',
            key: 'action',
            className: props.userInfo.is_station ? '' : 'hide-columns',
            render: (t, r) => (
                <Button size='small' type='primary' onClick={() => toggleModal('menus', r)}>
                    {intl.get('assignment_menu')}
                </Button>
            ),
        },
    ];

    const toggleModal = (type, r) => {
        updateParam({
            name: '',
            use_status: true,
        });
        if (type === 'edit' && selectedKeys.length != 1) {
            message.warning(intl.get('onlyOneByEdit'));
            return false;
        }
        if (type === 'edit') {
            let item = data.find((m) => m.id === selectedKeys[0]);
            updateParam(item);
        }
        if (type === 'menus') {
            updateParam(r);
            getRoleMenusList(r.id);
        }
        setVisible(!visible);
        setType(type);
    };

    const getRoleMenusList = (id) => {
        getRoleMenus(id, (data) => {
            setRoleMenusIds(data);
        });
    };

    const saveItem = () => {
        if (!param.name || param.name == '') {
            message.warning(intl.get('nameIsRequired'));
            return false;
        }
        setActionLoading(true);
        if (type === 'add') {
            createRole(param, () => {
                message.success(intl.get('createSuccess'));
                search(1);
                toggleModal();
                setActionLoading(false);
            });
        } else {
            param.id = selectedKeys[0];
            updateRole(param, () => {
                message.success(intl.get('editSuccess'));
                search(1);
                toggleModal();
                setActionLoading(false);
            });
        }
    };

    const saveMenusItem = () => {
        if (!roleMenusIds || roleMenusIds.length === 0) {
            message.warning(intl.get('empty'));
            return false;
        }
        setActionLoading(true);
        let item = {
            id: param.id,
            menuIds: roleMenusIds,
        };
        saveRoleMenus(item, () => {
            message.success(intl.get('operationSuccess'));
            search(1);
            toggleModal();
            setActionLoading(false);
        });
    };

    const delList = () => {
        if (!selectedKeys || selectedKeys.length == 0) {
            message.warning(intl.get('empty'));
            return false;
        }
        setActionLoading(true);
        delRole(selectedKeys, () => {
            message.success(intl.get('deleteSuccess'));
            search(1);
            setActionLoading(false);
        });
        setTimeout(() => {
            setActionLoading(false);
        }, 5000);
    };

    return (
        <Spin spinning={actionLoading}>
            <ConditionPanel labelWidth={90}>
                <ConditionPanel.Item label={intl.get('name') + ':'}>
                    <Input
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                        style={{width: '100%'}}
                        placeholder={intl.get('placeholder')}
                        allowClear
                    />
                </ConditionPanel.Item>
                <ConditionPanel.Item enterSearch>
                    <Button type='primary' block onClick={() => search(1)}>
                        {intl.get('btn_search')}
                    </Button>
                </ConditionPanel.Item>
            </ConditionPanel>
            {props.userInfo.is_station && (
                <Row className='margin-bottom-5'>
                    <Col>
                        <Button type='primary' size='small' onClick={() => toggleModal('add')}>
                            {intl.get('btn_add')}
                        </Button>
                        <Button className='margin-left-5' type='primary' size='small'
                                onClick={() => toggleModal('edit')}>
                            {intl.get('btn_edit')}
                        </Button>
                        <Button className='margin-left-5' type='primary' size='small' onClick={delList}>
                            {intl.get('btn_del')}
                        </Button>
                    </Col>
                </Row>
            )
            }

            <CommonTable columns={columns} {...tableProps} />
            {/* 操作 */}
            <Modal
                width={isMobileBrowser() ? '100%' : '50%'}
                title={intl.get('operation')}
                visible={type !== 'menus' && visible}
                destroyOnClose={true}
                maskClosable={false}
                onOk={saveItem}
                onCancel={toggleModal}
            >
                <TemplateForm
                    title={intl.get('name')}
                    span={[8, 12, 0]}
                    condition={<Input value={param.name} onChange={(e) => updateParam({name: e.target.value})}/>}
                />
                <TemplateForm
                    title={intl.get('status')}
                    span={[8, 12, 0]}
                    condition={
                        <Select defaultValue={param.use_status ? 1 : 0} onChange={(e) => updateParam({use_status: e})}
                                style={{width: '100%'}}>
                            {Object.values(USE_STATUS.properties).map((e) => (
                                <Select.Option key={e.key} value={e.key}>
                                    {intl.get(e.text)}
                                </Select.Option>
                            ))}
                        </Select>
                    }
                />
            </Modal>
            <Modal
                width={isMobileBrowser() ? '100%' : '50%'}
                title={intl.get('assignment_menu')}
                visible={type === 'menus' && visible}
                destroyOnClose={true}
                maskClosable={false}
                onOk={saveMenusItem}
                onCancel={toggleModal}
            >
                <TemplateForm
                    title={intl.get('menu')}
                    span={[8, 12, 0]}
                    condition={
                        <TreeSelect
                            style={{width: '100%'}}
                            value={roleMenusIds}
                            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                            treeData={allMenus}
                            treeCheckable={true}
                            showCheckedStrategy={SHOW_ALL}
                            placeholder='Please select'
                            treeDefaultExpandAll
                            onChange={(e) => setRoleMenusIds(e)}
                        />
                    }
                />
            </Modal>
        </Spin>
    );
};
const mapStateToProps = (state) => {
    const {GlobalReducer} = state;
    return {
        userInfo: GlobalReducer.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleList);
