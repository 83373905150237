import React, {useState} from 'react';
import intl from 'react-intl-universal';
import {connect} from 'react-redux';
import {Button, Input, Row, Col, Modal, Select, message, DatePicker} from 'antd';
import useTable from '../../../hooks/useTable';
import ConditionPanel from '../../..//components/ConditionPanel';
import CommonTable from '../../../components/CommonTable';
import TemplateForm from '../../common/TemplateForm';
import Description from '../../common/Description';
import moment from 'moment';
import {useParams} from '../../../hooks/utils';
import copy from 'copy-to-clipboard';
import {
    createChannel,
    updateChannel,
    delChannel,
    getChannelProduct,
    createChannelProduct,
    delChannelProduct,
    updateChannelProduct,
    getChannelUser,
    createChannelUser,
    delChannelUser,
    getWebLink,
} from './service';
import {isMobileBrowser} from '../../../helper/until';
import {USE_STATUS} from '../../global/constants';

const ChannelConfigList = (props) => {
    const [visible, setVisible] = useState(false);
    const [type, setType] = useState('add');
    const [searchParam, updateSearchParam] = useParams({
        code: '',
        name: '',
    });
    const [param, updateParam] = useParams({
        name: '',
        code: '',
        email: '',
        use_status: true,
        phone: '',
        user_id: '',
        connect_user_name: '',
        rate_template_id: '',
    });
    const [productList, setProductList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedUserRowKeys, setSelectedUserRowKeys] = useState([]);
    const [addLoading, setAddLoading] = useState(false);

    const {tableProps, search, selectedKeys, data} = useTable(
        {
            url: '/channel',
            params: searchParam,
        },
        {
            pagination: true,
            noFetch: true,
            rowSelection: true,
        }
    );

    const templateData = useTable(
        {
            url: '/rate_template',
        },
        {
            pagination: false,
        }
    );

    const productData = useTable(
        {
            url: '/product',
            params: {
                product_name: '',
                product_code: '',
                classify: -1,
                classify_sub: -1,
                use_status: '',
                insure_company_id: '',
            },
        },
        {
            pagination: false,
        }
    );

    const userData = useTable(
        {
            url: '/channel_user',
            params: {
                name: '',
                nick_name: '',
            },
        },
        {
            pagination: false,
        }
    );

    const getLinks = (id) => {
        getWebLink(id, (data) => {
            copy(data);
            message.success(intl.get('copySuccess'));
        });
    };

    const columns = [
        {title: intl.get('channel_code'), dataIndex: 'code', key: 'code'},
        {title: intl.get('channel_name'), dataIndex: 'name', key: 'name'},
        {title: intl.get('channel_user'), dataIndex: 'user_name', key: 'user_name'},
        {title: intl.get('template_name'), dataIndex: 'rate_template_name', key: 'rate_template_name'},
        {
            title: intl.get('status'),
            dataIndex: 'use_status',
            key: 'use_status',
            render: (t, r) =>
                <span>{USE_STATUS.properties[t ? 1 : 0] ? intl.get(USE_STATUS.properties[t ? 1 : 0].text) : '--'}</span>,
        },
        {title: intl.get('contact'), dataIndex: 'connect_user_name', key: 'connect_user_name'},
        {title: intl.get('phone'), dataIndex: 'phone', key: 'phone'},
        {title: intl.get('email'), dataIndex: 'email', key: 'email'},
        {
            title: intl.get('time_create'),
            dataIndex: 'time_create',
            key: 'time_create',
            render: (t) => <span>{t ? moment(t).format('YYYY-MM-DD HH:mm') : '--'}</span>,
        },
        {
            title: intl.get('operation'),
            dataIndex: 'action',
            key: 'action',
            render: (t, r) => (
                <span className='font-link' onClick={() => getLinks(r.id)}>
          {intl.get('copyLink')}
        </span>
            ),
        },
    ];

    const toggleModal = (type) => {
        updateParam({
            name: '',
            code: '',
            email: '',
            use_status: true,
            phone: '',
            user_id: '',
            connect_user_name: '',
            rate_template_id: '',
        });
        if (type === 'edit' || type === 'product' || type === 'user') {
            if (selectedKeys.length !== 1) {
                message.warning(intl.get('empty'));
                return false;
            }
            let item = data.find((m) => m.id === selectedKeys[0]);
            updateParam(item);
            if (type === 'product') {
                getChannelProductRequest(item.id);
            }
            if (type === 'user') {
                //TODO:看下返回结果什么样
                getChannelUser(item.id, (data) => {
                    setUserList(data || []);
                });
            }
        }

        setVisible(!visible);
        setType(type);
    };

    const saveItem = () => {
        if (!param.name || param.name === '') {
            message.warning(intl.get('nameIsRequired'));
            return false;
        }
        if (!param.code || param.code === '') {
            message.warning(intl.get('channelCodeIsRequired'));
            return false;
        }
        if (!param.rate_template_id || param.rate_template_id === '') {
            message.warning(intl.get('RateTemplateIsRequired'));
            return false;
        }
        // if (!param.user_id || param.user_id === '') {
        //   message.warning('请选择渠道用户');
        //   return false;
        // }
        if (!param.phone || param.phone === '') {
            message.warning(intl.get('phoneIsRequired'));
            return false;
        }
        if (!param.connect_user_name || param.connect_user_name === '') {
            message.warning(intl.get('contactIsRequired'));
            return false;
        }
        if (!param.email || param.email === '') {
            message.warning(intl.get('emailIsRequired'));
            return false;
        }
        if (type === 'add') {
            createChannel(param, () => {
                message.success(intl.get('createSuccess'));
                search(1);
                toggleModal();
            });
        } else {
            param.id = selectedKeys[0];
            updateChannel(param, () => {
                message.success(intl.get('editSuccess'));
                search(1);
                toggleModal();
            });
        }
    };

    const delList = () => {
        if (!selectedKeys || selectedKeys.length === 0) {
            message.warning(intl.get('empty'));
            return false;
        }
        delChannel(selectedKeys, () => {
            message.success(intl.get('deleteSuccess'));
            search(1);
        });
    };

    const addProduct = () => {
        let a = [...productList];
        a.push({
            product_code: '',
            product_name: '',
            product_id: '',
            time_from: moment().startOf().valueOf(),
            time_to: moment().startOf().valueOf(),
        });
        setProductList(a);
    };

    const addUser = () => {
        let a = [...userList];
        a.push({
            name: '',
            nick_name: '',
            user_id: '',
        });
        setUserList(a);
    };

    const onChangeDate = (date, index, key) => {
        let a = [...productList];
        let item = a[index];
        item[key] = moment(date).startOf().valueOf();
        setProductList(a);
    };

    //product
    const onChangeProduct = (value, index) => {
        let a = [...productList];
        let item = a[index];
        let productItem = productData.data.find((m) => m.id === value);
        item.product_id = value;
        item.product_code = productItem ? productItem.product_code : '';
        item.product_name = productItem ? productItem.product_name : '';
        setProductList(a);
    };

    const getChannelProductRequest = (id) => {
        getChannelProduct(id, (data) => {
            setProductList(data || []);
        });
    };

    const createChannelProductItem = () => {
        setAddLoading(true);
        if (!selectedRowKeys || selectedRowKeys.length === 0) {
            message.warning(intl.get('empty'));
            setAddLoading(false);
            return false;
        }
        let item = [];

        selectedRowKeys.map((e, i) => {
            if (productList[e]) {
                item.push(productList[e]);
            }
        });
        let a = item.filter((e) => !e.id && e.product_id);

        if (a && a.length > 0) {
            createChannelProduct(param.id, a, () => {
                productData.search();
                message.success(intl.get('createSuccess'));
                getChannelProductRequest(param.id);
                setAddLoading(false);
            });
        } else {
            message.warning(intl.get('empty'));
            setAddLoading(false);
        }
        setTimeout(() => {
            setAddLoading(false);
        }, 5000);
    };

    const delChannelProductItem = () => {
        setAddLoading(true);
        if (!selectedRowKeys || selectedRowKeys.length === 0) {
            message.warning(intl.get('empty'));
            setAddLoading(false);
            return false;
        }
        let a = productList.filter((e) => selectedRowKeys.includes(e.id));
        delChannelProduct(param.id, selectedRowKeys, () => {
            setProductList(a);
            message.success(intl.get('deleteSuccess'));
            setAddLoading(false);
        });
        setTimeout(() => {
            setAddLoading(false);
        }, 5000);
    };

    const editChannelProductItem = (i) => {
        setAddLoading(true);
        let item = productList[i];
        updateChannelProduct(item, () => {
            // setProductList(a);
            getChannelProductRequest(param.id);
            message.success(intl.get('editSuccess'));
            setAddLoading(false);
        });
        setTimeout(() => {
            setAddLoading(false);
        }, 5000);
    };

    //user
    const onChangeUser = (value, index) => {
        let a = [...userList];
        let item = a[index];
        let userItem = userData.data.find((m) => m.id === value);
        item.user_id = value;
        item.name = userItem ? userItem.name : '';
        item.nick_name = userItem ? userItem.nick_name : '';
        setUserList(a);
    };

    const createChannelUserItem = () => {
        if (!selectedUserRowKeys || selectedUserRowKeys.length === 0) {
            message.warning(intl.get('empty'));
            return false;
        }
        let item = [];
        selectedUserRowKeys.map((e, i) => {
            item.push(userList[e]);
        });
        let a = item.filter((e) => !e.id && e.user_id);
        let ids = [];
        a.forEach((e) => {
            ids.push(e.user_id);
        });
        if (a && a.length > 0) {
            createChannelUser(param.id, ids, () => {
                userData.search();
                message.success(intl.get('createSuccess'));
            });
        } else {
            message.warning(intl.get('empty'));
        }
    };

    const delChannelUserItem = () => {
        if (!selectedUserRowKeys || selectedUserRowKeys.length === 0) {
            message.warning(intl.get('empty'));
            return false;
        }
        let a = userList.filter((e) => selectedUserRowKeys.includes(e.id));
        delChannelUser(param.id, selectedUserRowKeys, () => {
            setUserList(a);
            message.success(intl.get('deleteSuccess'));
        });
    };

    return (
        <div>
            <ConditionPanel labelWidth={90}>
                <ConditionPanel.Item label={intl.get('channel_code') + ':'}>
                    <Input onChange={(e) => updateSearchParam({code: e.target.value})} style={{width: '100%'}}
                           placeholder={intl.get('placeholder')} allowClear/>
                </ConditionPanel.Item>
                <ConditionPanel.Item label={intl.get('channel_name') + ':'}>
                    <Input onChange={(e) => updateSearchParam({name: e.target.value})} style={{width: '100%'}}
                           placeholder={intl.get('placeholder')} allowClear/>
                </ConditionPanel.Item>
                <ConditionPanel.Item enterSearch type='end'>
                    <Button type='primary' onClick={() => search(1)} block>
                        {intl.get('btn_search')}
                    </Button>
                </ConditionPanel.Item>
            </ConditionPanel>

            {props.userInfo.is_station && (
                <Row className='margin-bottom-5'>
                    <Col>
                        <Button type='primary' size='small' onClick={() => toggleModal('add')}>
                            {intl.get('btn_add')}
                        </Button>
                        <Button className='margin-left-5' type='primary' size='small'
                                onClick={() => toggleModal('edit')}>
                            {intl.get('btn_edit')}
                        </Button>
                        <Button className='margin-left-5' type='primary' size='small' onClick={delList}>
                            {intl.get('btn_del')}
                        </Button>
                        <Button className='margin-left-5' type='primary' size='small'
                                onClick={() => toggleModal('product')}>
                            {intl.get('btn_config')}
                        </Button>
                    </Col>
                </Row>)
            }


            <CommonTable columns={columns} {...tableProps} />
            {/* 操作 */}
            <Modal
                width={isMobileBrowser() ? '100%' : '50%'}
                title={intl.get('operation')}
                visible={visible && (type === 'add' || type === 'edit')}
                destroyOnClose={true}
                maskClosable={false}
                onOk={saveItem}
                onCancel={toggleModal}
            >
                <TemplateForm
                    title={intl.get('channel_code')}
                    span={[8, 12, 0]}
                    condition={<Input value={param.code} onChange={(e) => updateParam({code: e.target.value})}/>}
                />
                <TemplateForm
                    title={intl.get('channel_name')}
                    span={[8, 12, 0]}
                    condition={<Input value={param.name} onChange={(e) => updateParam({name: e.target.value})}/>}
                />

                <TemplateForm
                    title={intl.get('Rate_template')}
                    span={[8, 12, 0]}
                    condition={
                        <Select style={{width: '100%'}} value={param.rate_template_id}
                                onChange={(e) => updateParam({rate_template_id: e})}>
                            {templateData &&
                            templateData.data.map((e) => (
                                <Select.Option key={e.id} value={e.id}>
                                    {e.rate_name}
                                </Select.Option>
                            ))}
                        </Select>
                    }
                />
                <TemplateForm
                    title={intl.get('contact')}
                    span={[8, 12, 0]}
                    condition={<Input value={param.connect_user_name}
                                      onChange={(e) => updateParam({connect_user_name: e.target.value})}/>}
                />
                <TemplateForm
                    title={intl.get('phone')}
                    span={[8, 12, 0]}
                    condition={<Input value={param.phone} onChange={(e) => updateParam({phone: e.target.value})}/>}
                />
                <TemplateForm
                    title={intl.get('email')}
                    span={[8, 12, 0]}
                    condition={<Input value={param.email} onChange={(e) => updateParam({email: e.target.value})}/>}
                />
                <TemplateForm
                    title={intl.get('status')}
                    span={[8, 12, 0]}
                    condition={
                        <Select value={param.use_status ? 1 : 0} onChange={(e) => updateParam({use_status: e})}
                                style={{width: '100%'}}>
                            {Object.values(USE_STATUS.properties).map((e) => (
                                <Select.Option key={e.key} value={e.key}>
                                    {intl.get(e.text)}
                                </Select.Option>
                            ))}
                        </Select>
                    }
                />
            </Modal>
            {/* 渠道产品配置 */}
            <Modal
                width={isMobileBrowser() ? '100%' : '50%'}
                title={intl.get('operation')}
                visible={visible && type === 'product'}
                destroyOnClose={true}
                maskClosable={false}
                footer={null}
                onCancel={toggleModal}
            >
                <Description label={intl.get('channel_code')} span={12}>
                    {param.code}
                </Description>
                <Description label={intl.get('channel_name')} span={12}>
                    {param.name}
                </Description>
                <Row className='margin-top-16'>
                    {props.userInfo.is_station && (
                        <Col className='margin-bottom-5'>
                            <Button type='primary' size='small' onClick={createChannelProductItem}>
                                {intl.get('btn_add')}
                            </Button>
                            <Button className='margin-left-5' type='primary' size='small'
                                    onClick={delChannelProductItem}>
                                {intl.get('btn_del')}
                            </Button>
                        </Col>
                    )}

                    <Col>
                        <CommonTable
                            loading={addLoading}
                            columns={[
                                {
                                    title: intl.get('product_code'),
                                    dataIndex: 'product_id',
                                    key: 'product_id',
                                    render: (t, r, i) => (
                                        <Select style={{width: '100px'}} value={t} disabled={r.id ? true : false}
                                                onChange={(e) => onChangeProduct(e, i)}>
                                            {productData.data.map((e) => (
                                                <Select.Option key={e.id} value={e.id}>
                                                    {e.product_code}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    ),
                                },
                                {title: intl.get('product_name'), dataIndex: 'product_name', key: 'product_name'},
                                {
                                    title: intl.get('valid_begin'),
                                    dataIndex: 'time_from',
                                    key: 'time_from',
                                    render: (t, r, i) => (
                                        <DatePicker
                                            disabled={!props.userInfo.is_station}
                                            format="DD-MM-YYYY"
                                            value={moment(t)}
                                            onChange={(date, dateString) => onChangeDate(date, i, 'time_from')}
                                        />
                                    ),
                                },
                                {
                                    title: intl.get('valid_end'),
                                    dataIndex: 'time_to',
                                    key: 'time_to',
                                    render: (t, r, i) => (
                                        <DatePicker
                                            disabled={!props.userInfo.is_station}
                                            value={moment(t)}
                                            format="DD-MM-YYYY"
                                            onChange={(date, dateString) => onChangeDate(date, i, 'time_to')}
                                        />
                                    ),
                                },
                                {
                                    title: '',
                                    dataIndex: 'action',
                                    key: 'action',
                                    className: props.userInfo.is_station ? '' : 'hide-columns',
                                    render: (t, r, i) =>
                                        r.id && (
                                            <span onClick={() => editChannelProductItem(i)} className='font-link'>
                        修改
                      </span>
                                        ),
                                },
                            ]}
                            pagination={false}
                            dataSource={productList}
                            rowKey={(r) => r.id}
                            rowSelection={{
                                onChange: (selectedRowKeys, selectedRows) => {
                                    setSelectedRowKeys(selectedRowKeys);
                                },
                                // getCheckboxProps: (record) => ({
                                //   disabled: record.id && record.id !== '', // Column configuration not to be checked
                                //   name: record.id,
                                // }),
                            }}
                        />
                        {props.userInfo.is_station && (
                            <Button block type='dashed' className='margin-top-5' onClick={addProduct}>
                                {intl.get('btn_add')}
                            </Button>
                        )}
                    </Col>
                </Row>
            </Modal>
            {/* 渠道用户配置 */}
            <Modal
                width={isMobileBrowser() ? '100%' : '50%'}
                title={intl.get('operation')}
                visible={visible && type === 'user'}
                destroyOnClose={true}
                maskClosable={false}
                onCancel={toggleModal}
                footer={null}
            >
                <Description label={intl.get('channel_code')} span={12}>
                    {param.code}
                </Description>
                <Description label={intl.get('channel_name')} span={12}>
                    {param.name}
                </Description>
                <Row className='margin-top-16'>
                    {props.userInfo.is_station && (
                        <Col className='margin-bottom-5'>
                            <Button type='primary' size='small' onClick={createChannelUserItem}>
                                {intl.get('btn_add')}
                            </Button>
                            <Button className='margin-left-5' type='primary' size='small' onClick={delChannelUserItem}>
                                {intl.get('btn_del')}
                            </Button>
                        </Col>
                    )}

                    <Col>
                        <CommonTable
                            columns={[
                                {
                                    title: intl.get('user_name'),
                                    dataIndex: 'user_id',
                                    key: 'user_id',
                                    render: (t, r, i) => (
                                        <Select style={{width: '100px'}} value={t} disabled={r.id ? true : false}
                                                onChange={(e) => onChangeUser(e, i)}>
                                            {userData.data.map((e) => (
                                                <Select.Option key={e.id} value={e.id}>
                                                    {e.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    ),
                                },
                                {title: intl.get('nickName'), dataIndex: 'nick_name', key: 'nick_name'},
                            ]}
                            pagination={false}
                            dataSource={userList}
                            rowSelection={{
                                onChange: (selectedRowKeys, selectedRows) => {
                                    setSelectedUserRowKeys(selectedRowKeys);
                                },
                            }}
                        />
                        {props.userInfo.is_station && (
                            <Button block type='dashed' className='margin-top-5' onClick={addUser}>
                                {intl.get('btn_add')}
                            </Button>
                        )}
                    </Col>
                </Row>
            </Modal>
        </div>
    );
};
const mapStateToProps = (state) => {
    const {GlobalReducer} = state;
    return {
        userInfo: GlobalReducer.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelConfigList);
