import NotFound404 from '../common/NotFound404';
import Dashboard from '../dashboard';
//---start 系统管理
import UserChannelList from '../systemManage/userChannel/index';
import UserCompanyList from '../systemManage/userCompany/index';
import UserStationList from '../systemManage/userStation/index';
import RoleList from '../systemManage/role/index';
import MenusList from '../systemManage/menus/index';
import MailList from '../systemManage/mail/index';
//---end 系统管理

//---start 保险公司管理
import CompanyConfigList from '../companyManage/companyConfig/index';
//---end 保险公司管理

//---start 产品管理
import ProductConfigList from '../productManage/productConfig/index';
//---end 产品管理

//---start 渠道管理
import ChannelConfigList from '../channelManage/channelConfig/index';
//---end 渠道管理

//---start 经纪业务
import InsuredNavList from '../businessManage/insuredNav/index';
import InsurancePoliciesList from '../businessManage/insurancePolicies/index';
import ChannelPoliciesList from '../businessManage/insurancePolicies/channel';
import CompanyPoliciesList from '../businessManage/insurancePolicies/company';
import InsuranceAutoList from '../businessManage/InsuranceAuto/index';
import InsuranceImportList from '../businessManage/InsuranceImport/index';
//---end 经纪业务

//---start 费率管理
import premiumRateConfigList from '../premiumRateManage/premiumRateConfig/index';
//---end 费率管理

import TeamList from '../teamManage/index';

export const menu = [
  { id: '101', name: '首页', icon: 'dashboard', path: '/page/dashboard', component: Dashboard },
  { id: '201', name: '渠道用户管理', icon: 'contacts', path: '/page/user_channel_list', component: UserChannelList },
  { id: '201', name: '保险公司用户管理', icon: 'contacts', path: '/page/user_company_list', component: UserCompanyList },
  { id: '201', name: 'SAI用户管理', icon: 'contacts', path: '/page/user_station_list', component: UserStationList },
  { id: '202', name: '角色管理', icon: 'contacts', path: '/page/role_list', component: RoleList },
  { id: '203', name: '菜单管理', icon: 'contacts', path: '/page/menus_list', component: MenusList },
  { id: '204', name: '邮箱管理', icon: 'contacts', path: '/page/mail_list', component: MailList },
  { id: '301', name: '保险公司配置', icon: 'desktop', path: '/page/company_config_list', component: CompanyConfigList },
  { id: '401', name: '产品配置', icon: 'desktop', path: '/page/product_config_list', component: ProductConfigList },
  { id: '501', name: '渠道配置', icon: 'desktop', path: '/page/channel_config_list', component: ChannelConfigList },
  { id: '601', name: '投保导航', icon: 'desktop', path: '/page/insured_nav_list', component: InsuredNavList },
  { id: '602', name: '非车保单查询', icon: 'desktop', path: '/page/insurance_policies_list', component: InsurancePoliciesList },
  { id: '602', name: '非车保单查询', icon: 'desktop', path: '/page/channel_policies_list', component: ChannelPoliciesList },
  { id: '602', name: '非车保单查询', icon: 'desktop', path: '/page/company_policies_list', component: CompanyPoliciesList },
  { id: '701', name: '车险保单查询', icon: 'desktop', path: '/page/insurance_auto_list', component: InsuranceAutoList },
  { id: '603', name: '保单导入', icon: 'desktop', path: '/page/insurance_import_list', component: InsuranceImportList },
  { id: '801', name: '费率配置', icon: 'desktop', path: '/page/premium_rate_config_list', component: premiumRateConfigList },
  { id: '901', name: '团单数据', icon: 'desktop', path: '/page/team_list', component: TeamList },
];
