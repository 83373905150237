import {
	setUserInfo,
} from './constants';
import { fromJS, List } from 'immutable';

let isExistUserInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : []
const initialState = {
	userInfo:isExistUserInfo.length!==0 ? isExistUserInfo[0] : {}
};

function reducer(state = initialState, action) {
	switch (action.type) {
		case setUserInfo:
			return { ...state,userInfo:action.result }
		default:
			return state;
	}
}
export default reducer;