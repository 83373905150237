import React, { useState, useEffect, Toast } from 'react';
import intl from 'react-intl-universal';
import {
    Descriptions,
    Card,
    Row,
    Col,
    Tooltip,
    Input,
    Steps,
    Select,
    DatePicker,
    Button,
    Radio,
    Form,
    message,
    Spin,
    Icon,
    Tag,
} from 'antd';
import CommonTable from '../../../components/CommonTable';
import { useParams } from '../../../hooks/utils';
import { isMobileBrowser, getUrlParam, dateGoOn } from '../../../helper/until';
import moment from 'moment';
import TemplateUpload from '../../common/TemplateUpload';
import { getProductDetail, saveOrderInfo, getPrepare } from './service';
import { CREDENTIAL_TYPE, RELATIONSHIP, PAY_TYPE } from '../../global/constants';
import payLogo from '../../../image/LogoPartner/WechatIMG1873.png';
import './index.css';
import Zmage from 'react-zmage';
import SelectLanguage from "../../common/SelectLanguage";

const { Step } = Steps;

const PayStepsList = (props) => {
    const { getFieldDecorator, getFieldsError, getFieldError, setFieldsValue, getFieldValue } = props.form;
    const [actionLoading, setActionLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [policyholderInfo, updatePolicyholderInfo] = useParams({
        policyholders_name: '',
        policyholders_sex: 1,
        policyholders_birthday: moment().year(moment().year() - 18).valueOf(),
        policyholders_credential_type: 4,
        policyholders_credential: '',
        policyholders_email: '',
        policyholders_phone: '',
        term_time: '',
        programme_end_day: '',
        end_day: '',
        end_time: '',
        programme_select: 1,
        departure_time: moment().add('1', 'days').valueOf(),
    });
    const [detail, setDetail] = useState({});
    const [insurerInfo, setInsurerInfo] = useState([1]);
    const [orderDetail, setOrderDetail] = useState({});
    const [formInfo, setFormInfo] = useState({});
    const [isShowPayType, setIsShowPayType] = useState(false);
    const [isShowPayType1, setIsShowPayType1] = useState(false);
    const [isShowPayType2, setIsShowPayType2] = useState(false);
    const [isShowPayType3, setIsShowPayType3] = useState(false);
    const [isShowPayType4, setIsShowPayType4] = useState(false);
    const [isShowPayType5, setIsShowPayType5] = useState(false);
    const [payType, setPayType] = useState(233);
    const [prePareData, setPrePareData] = useState({});

    useEffect(() => {
        getProductDetail(getUrlParam('id'), (data) => {
            setDetail(data);
        });
    }, []);

    useEffect(() => {
        let insurer = getFieldValue('insurerInfo');
        let b = getFieldValue('policyholderInfo');
        // debugger
        // let item = (insurer || []).find((m) => m.insurer_relation_ship == 1);
        // if (item) {
        //     item.insurer_name = b.policyholders_name;
        //     item.insurer_sex = b.policyholders_sex;
        //     item.insurer_birthday = moment(b.policyholders_birthday);
        //     item.insurer_credential_type = b.policyholders_credential_type;
        //     // item.insurer_credential_url = "urlllll";
        //     item.insurer_credential = b.policyholders_credential;
        //     item.mobile_phone = b.policyholders_phone;
        //     item.term_time = b.term_time;
        //     setFieldsValue({
        //         insurerInfo: insurer,
        //     });
        // }
    }, [JSON.stringify(getFieldValue('policyholderInfo'))]);

    const clickStep = (e) => {
        // message.warning(step + " " + moment(policyholderInfo.policyholders_birthday).format('YYYY-MM-DD'))
        if (step == 0) {
            let age = moment().diff(moment(policyholderInfo.policyholders_birthday), 'years');
            if (age > 80) {
                message.warning(intl.get('age_range'))
            } else {
                getData(e);
            }
        } else {
            getData(e);
        }
    };
    const getData = (e) => {
        // debugger
        if (policyholderInfo.term_time) {
            let stepNow = step + 1;
            if (stepNow == 1) {
                e && e.preventDefault();
                props.form.validateFields((err, values) => {
                    if (!err) {
                        setStep(stepNow);
                        setFormInfo(values);
                    }
                });
            }
            if (stepNow == 2) {
                clickOk();
            }
        } else {
            message.warning(intl.get("choose_insurance_plan"));
        }
    };

    const handleValidator = (rule, val, callback) => {
        // if (!val) {
        //     callback();
        // }
        // let validateResult = false;  // 自定义规则
        // debugger
        // message.warning(val)
        if (!val) {
            callback('请输入正确的内容！');
        }
        callback();
    };

    const handleStartOpenChange = (date) => {
        policyholderInfo.policyholders_birthday = date;
        let age = moment().diff(moment(date), 'years');
        if (age > 80) {
            message.warning(intl.get("age_range"))
        }
        return age;
    }

    const onRelationBirthDayChange = (date, relpaceIndex) => {
        // message.warning(relpaceIndex + "--0000")
        let reAge = moment().diff(moment(date), 'years');
        let age = moment().diff(moment(policyholderInfo.policyholders_birthday), 'years');
        if (relpaceIndex <= 7 && relpaceIndex >= 3 && age < reAge) {
            message.warning(intl.get("their_children"));
        }

        if (relpaceIndex == 8 && age > reAge) {
            message.warning(intl.get("their_children"));
        }
    };


    const getPrepareRequest = (orderId) => {
        let policyholder = formInfo.policyholderInfo;

        let params = {
            order_id: orderId,
            payment_id: payType,
            policyholders_credential: policyholder.policyholders_credential,
            policyholders_email: policyholder.policyholders_email,
            policyholders_birthday: moment(policyholder.policyholders_birthday).format('YYYY-MM-DD HH:mm:ss'),
            policyholders_name: policyholder.policyholders_name,
        };
        getPrepare(params, (data) => {
            setPrePareData(data);
        });
    };

    useEffect(() => {
        if (step == 0) {
            setFieldsValue({
                policyholderInfo: formInfo.policyholderInfo,
                insurerInfo: formInfo.insurerInfo,
            });
            updatePolicyholderInfo(formInfo.policyholderInfo);
        }
    }, [step]);


    const clickBackStep = () => {
        let stepNow = step - 1;
        updatePolicyholderInfo(formInfo.policyholderInfo);
        setStep(stepNow);
    };

    const delInsurerList = (i) => {
        let a = [...insurerInfo];
        a.splice(i, 1);
        setInsurerInfo(a);
    };

    const addItem = () => {
        let a = [...insurerInfo];
        a.push(a.length);
        setInsurerInfo(a);
    };

    const changeUserInfo = (value, key, index, birthDay) => {
        let a = getFieldValue('insurerInfo');
        let b = getFieldValue('policyholderInfo');
        a[index][key] = value;
        if (key === 'insurer_relation_ship' && value == 1) {
            a[index].insurer_name = b.policyholders_name;
            a[index].insurer_sex = b.policyholders_sex;
            a[index].insurer_birthday = moment(b.policyholders_birthday);
            a[index].insurer_credential_type = b.policyholders_credential_type;
            // a[index].insurer_credential_url = 'wwww';
            a[index].insurer_credential = b.policyholders_credential;
            a[index].mobile_phone = b.policyholders_phone;
            a[index].term_time = b.term_time;
        }
        setFieldsValue({
            insurerInfo: a,
        });
        // message.warning(value);
        onRelationBirthDayChange(birthDay, value);
    };

    const changeUserInfo1 = (value, index, url) => {
        let a = getFieldValue('insurerInfo');
        // debugger
        // let b = getFieldValue('policyholderInfo');
        // a[index][key] = value;
        // if (key === 'insurer_relation_ship' && value == 1) {
        // a[index].insurer_name = b.policyholders_name;
        // a[index].insurer_sex = b.policyholders_sex;
        // a[index].insurer_birthday = moment(b.policyholders_birthday);
        // a[index].insurer_credential_type = b.policyholders_credential_type;
        a[index].insurer_credential_url = url;
        // a[index].insurer_credential = b.policyholders_credential;
        // a[index].mobile_phone = b.policyholders_phone;
        // a[index].term_time = b.term_time;
        // }
        setFieldsValue({
            insurerInfo: a,
        });
        // message.warning(value);
        // onRelationBirthDayChange(birthDay, value);
    };

    const changeDiv = () => {
        detail.min_price = 999;
    };

    const onTimeChang = (date) => {
        policyholderInfo.departure_time = date;
        policyholderInfo.term_time = moment(date).format('YYYY-MM-DD') +
            ' ' + intl.get('lingshi') + ' ' + intl.get('to') + ' ' +
            moment(date).add(policyholderInfo.end_day - 1, 'days').format('YYYY-MM-DD') +
            ' ' + intl.get('ershisixoaszhi') + '' + intl.get('total') + ' ' +
            policyholderInfo.end_day +
            ' ' + intl.get('minDay');
    };


    const gettermTimeUpdate1 = (day, amount) => {
        if (intl.get('language') === 'CN') {
            return day + intl.get("minDay") + amount + intl.get("dotol");
        } else {
            return amount + '$ ' + 'for ' + day + ' ' + intl.get("minDay");
        }
    };
    const gettermTimeUpdate2 = (day, amount) => {
        if (intl.get('language') === 'CN') {
            return day + intl.get("minDay") + amount + intl.get("dotol");
        } else {
            return amount + '$ ' + 'for ' + day + ' ' + intl.get("minDay");
        }
    };
    const gettermTimeUpdate3 = (day, amount) => {
        if (intl.get('language') === 'CN') {
            return day + intl.get("minDay") + amount + intl.get("dotol");
        } else {
            return amount + '$ ' + 'for ' + day + ' ' + intl.get("minDay");
        }
    };
    const gettermTimeUpdate4 = (day, amount) => {
        if (intl.get('language') === 'CN') {
            return day + intl.get("minDay") + amount + intl.get("dotol");
        } else {
            return amount + '$ ' + 'for ' + day + ' ' + intl.get("minDay");
        }
    };
    const gettermTimeUpdate5 = (day, amount) => {
        if (intl.get('language') === 'CN') {
            return day + intl.get("minDay") + amount + intl.get("dotol");
        } else {
            return amount + '$ ' + 'for ' + day + ' ' + intl.get("minDay");
        }
    };

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    }
    const birthdayDisabledDate = (current) => {
        return current > moment().year(moment().year() - 18).endOf('day');
    }


    const clickOk = () => {
        setActionLoading(true);
        let insurer = formInfo.insurerInfo;
        let policyholder = formInfo.policyholderInfo;
        let param = {
            channel_id: getUrlParam('channel_id'),
            channel_user_id: getUrlParam('user_id'),
            policyholders_birthday: moment(policyholder.policyholders_birthday).valueOf(),
            departure_time: moment(policyholder.policyholders_birthday, 'YYYY-MM-DD').valueOf(),
            term_time: policyholderInfo.term_time,
            time_effect: moment(policyholder.departure_time).valueOf(),
            time_envalid: moment(moment(policyholderInfo.departure_time).add(policyholderInfo.programme_end_day - 1).format("YYY-MM-DD").valueOf() + " 23:59:59").unix(),
            programme_select: policyholderInfo.programme_select,
            policyholders_credential: policyholder.policyholders_credential,
            policyholders_credential_type: policyholder.policyholders_credential_type,
            policyholders_name: policyholder.policyholders_name,
            policyholders_sex: policyholder.policyholders_sex,
            policyholders_email: policyholder.policyholders_email,
            policyholders_phone: policyholder.policyholders_phone,
            product_id: getUrlParam('id'),
            order_amount: (detail.min_price * insurer.length).toFixed(2),
            pay_amount: (detail.min_price * insurer.length).toFixed(2),
        };
        insurer.map((e) => {
            e.order_amount = detail.min_price;
            e.order_count = 1;
        });
        param.order_buyer_dtolist = insurer;
        if (param && param.order_buyer_dtolist && param.order_buyer_dtolist.length > 0) {
            saveOrderInfo(param, (data) => {
                getPrepareRequest(data.id);
                setOrderDetail(data);
                setStep(2);
                setActionLoading(false);
            });
        } else {
            message.warning(intl.get('empty'));
            setActionLoading(false);
        }
        setTimeout(() => {
            setActionLoading(false);
        }, 5000);
    };
    const formItemLayout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
    };

    const stepFirstRender = () => {
        return (
            <React.Fragment>
                <Descriptions bordered column={1}>
                    <Descriptions.Item
                        label={
                            <Row style={{ textAlign: 'center' }}>
                                <Col style={{ fontSize: '30px' }}>1</Col>
                                <Col style={{ fontSize: '15px' }}>{intl.get('order_info')}</Col>
                            </Row>
                        }
                    >
                        <Form.Item style={{ marginTop: '20px' }} label={intl.get('product_name')} {...formItemLayout}>
                            <div style={{ width: '100%' }} onChange={(e) => changeDiv()}>
                                {detail.product_name}
                            </div>
                        </Form.Item>
                        <Form.Item label={intl.get('insurance_premiums')} {...formItemLayout}>
                            <div style={{ width: '100%' }}>USD{(detail.min_price * 1).toFixed(2)}</div>
                        </Form.Item>

                        <Form.Item label={intl.get('departure_time')} {...formItemLayout}>
                            {getFieldDecorator('policyholderInfo.departure_time', {
                                rules: [
                                    {
                                        type: 'object',
                                        required: true,
                                    },
                                ],
                                initialValue: moment(policyholderInfo.departure_time),
                            })(<DatePicker allowClear={false} style={{ width: '100%' }} format="DD-MM-YYYY"
                                onChange={date => onTimeChang(date)} disabledDate={disabledDate} />)}
                        </Form.Item>

                        {/*保险期限：*/}
                        <Form.Item label={intl.get('term_insurance')} {...formItemLayout}>
                            <span style={{ width: '100%' }}>{policyholderInfo.term_time}</span>
                        </Form.Item>

                        <Form.Item label={intl.get('plans')}  {...formItemLayout}>
                            {detail.programme_ones && (
                                <Button type='danger' disabled={!detail.programme_ones} size='small'
                                    onClick={() => setIsShowPayType1(!isShowPayType1)}>
                                    {intl.get('programme_one')}
                                    <Icon type={isShowPayType1 ? 'up' : 'down'} />
                                </Button>
                            )}
                            {isShowPayType1 && (
                                <div className='priceDiv'>
                                    {detail.programme_ones.map((m) => (
                                        <span
                                            value={m.end_day}
                                            // value={gettermTimeUpdate(m.end_day,m.tex)}
                                            key={m.text}
                                            className='programme_payBox'
                                            onClick={() => {
                                                policyholderInfo.programme_select = '一';
                                                detail.min_price = m.text;
                                                policyholderInfo.end_day = m.end_day;
                                                policyholderInfo.term_time =
                                                    moment(policyholderInfo.departure_time).format('YYYY-MM-DD') +
                                                    ' ' + intl.get('lingshi') + ' ' + intl.get('to') + ' ' +
                                                    moment(policyholderInfo.departure_time)
                                                        .add(m.end_day - 1, 'days')
                                                        .format('YYYY-MM-DD') +
                                                    ' ' + intl.get('ershisixoaszhi') + ' ' + intl.get('total') + ' ' +
                                                    m.end_day +
                                                    ' ' + intl.get('minDay');
                                                setIsShowPayType1(false);
                                            }}
                                        >
                                            {gettermTimeUpdate1(m.end_day, m.text)}
                                        </span>
                                    ))}
                                </div>
                            )}

                            {detail.programme_twos && (
                                <Button
                                    type='danger'
                                    size='small'
                                    disabled={!detail.programme_twos}
                                    style={{ marginLeft: '10px' }}
                                    onClick={() => setIsShowPayType2(!isShowPayType2)}
                                >
                                    {intl.get('programme_two')}
                                    <Icon type={isShowPayType2 ? 'up' : 'down'} />
                                </Button>
                            )}
                            {isShowPayType2 && (
                                <div className='priceDiv'>
                                    {detail.programme_twos.map((m) => (
                                        <span
                                            value={m.key}
                                            key={m.text}
                                            className='programme_payBox'
                                            onClick={() => {
                                                policyholderInfo.end_day = m.end_day;
                                                policyholderInfo.programme_select = '二';
                                                detail.min_price = m.text;
                                                policyholderInfo.term_time =
                                                    moment(policyholderInfo.departure_time).format('YYYY-MM-DD') +
                                                    ' ' + intl.get('lingshi') + ' ' + intl.get('to') + ' ' +
                                                    moment(policyholderInfo.departure_time)
                                                        .add(m.end_day - 1, 'days')
                                                        .format('YYYY-MM-DD') +
                                                    ' ' + intl.get('ershisixoaszhi') + ' ' + intl.get('total') + ' ' +
                                                    m.end_day +
                                                    ' ' + intl.get('minDay');
                                                setIsShowPayType2(false);
                                            }}
                                        >
                                            {gettermTimeUpdate2(m.end_day, m.text)}
                                        </span>
                                    ))}
                                </div>
                            )}

                            {/*{detail.programme_two = null ? setPro2('h') : setPro2('d')}*/}
                            {detail.programme_threes && (
                                <Button
                                    type='danger'
                                    size='small'
                                    disabled={!detail.programme_threes}
                                    onClick={() => setIsShowPayType3(!isShowPayType3)}
                                    style={{ marginLeft: '10px' }}
                                >
                                    {intl.get('programme_three')}
                                    <Icon type={isShowPayType3 ? 'up' : 'down'} />
                                </Button>
                            )}
                            {isShowPayType3 && (
                                <div className='priceDiv'>
                                    {detail.programme_threes.map((m) => (
                                        <span
                                            value={m.key}
                                            key={m.text}
                                            className='programme_payBox'
                                            onClick={() => {
                                                policyholderInfo.programme_select = '三';
                                                policyholderInfo.end_day = m.end_day;
                                                detail.min_price = m.text;
                                                policyholderInfo.term_time =
                                                    moment(policyholderInfo.departure_time).format('YYYY-MM-DD') +
                                                    ' ' + intl.get('lingshi') + ' ' + intl.get('to') + ' ' +
                                                    moment(policyholderInfo.departure_time)
                                                        .add(m.end_day - 1, 'days')
                                                        .format('YYYY-MM-DD') +
                                                    ' ' + intl.get('ershisixoaszhi') + ' ' + intl.get('total') + ' ' +
                                                    m.end_day +
                                                    ' ' + intl.get('minDay');
                                                setIsShowPayType3(false);
                                            }}
                                        >
                                            {gettermTimeUpdate3(m.end_day, m.text)}
                                        </span>
                                    ))}
                                </div>
                            )}

                            {detail.programme_fours && (
                                <Button
                                    type='danger'
                                    size='small'
                                    disabled={!detail.programme_fours}
                                    onClick={() => setIsShowPayType4(!isShowPayType4)}
                                    style={{ marginLeft: '10px' }}
                                >
                                    {intl.get('programme_four')}
                                    <Icon type={isShowPayType4 ? 'up' : 'down'} />
                                </Button>
                            )}
                            {isShowPayType4 && (
                                <div className='priceDiv'>
                                    {detail.programme_fours.map((m) => (
                                        <span
                                            value={m.key}
                                            key={m.text}
                                            className='programme_payBox'
                                            onClick={() => {
                                                policyholderInfo.programme_select = '四';
                                                policyholderInfo.end_day = m.end_day;
                                                detail.min_price = m.text;
                                                policyholderInfo.term_time =
                                                    moment(policyholderInfo.departure_time).format('YYYY-MM-DD') +
                                                    ' ' + intl.get('lingshi') + ' ' + intl.get('to') + ' ' +
                                                    moment(policyholderInfo.departure_time)
                                                        .add(m.end_day - 1, 'days')
                                                        .format('YYYY-MM-DD') +
                                                    ' ' + intl.get('ershisixoaszhi') + ' ' + intl.get('total') + ' ' +
                                                    m.end_day +
                                                    ' ' + intl.get('minDay');
                                                setIsShowPayType4(false);
                                            }}
                                        >
                                            {gettermTimeUpdate4(m.end_day, m.text)}
                                        </span>
                                    ))}
                                </div>
                            )}

                            {detail.programme_fives && (
                                <Button
                                    type='danger'
                                    size='small'
                                    disabled={!detail.programme_fives}
                                    onClick={() => setIsShowPayType5(!isShowPayType5)}
                                    style={{ marginLeft: '10px' }}
                                >
                                    {intl.get('programme_five')}
                                    <Icon type={isShowPayType5 ? 'up' : 'down'} />
                                </Button>
                            )}
                            {isShowPayType5 && (
                                <div className='priceDiv'>
                                    {detail.programme_fives.map((m) => (
                                        <span
                                            value={m.key}
                                            key={m.text}
                                            className='programme_payBox'
                                            onClick={() => {
                                                policyholderInfo.programme_select = '五';
                                                policyholderInfo.end_day = m.end_day;
                                                detail.min_price = m.text;
                                                policyholderInfo.term_time =
                                                    moment(policyholderInfo.departure_time).format('YYYY-MM-DD') +
                                                    ' ' + intl.get('lingshi') + ' ' + intl.get('to') + ' ' +
                                                    moment(policyholderInfo.departure_time)
                                                        .add(m.end_day - 1, 'days')
                                                        .format('YYYY-MM-DD') +
                                                    ' ' + intl.get('ershisixoaszhi') + ' ' + intl.get('total') + ' ' +
                                                    m.end_day +
                                                    ' ' + intl.get('minDay');
                                                setIsShowPayType5(false);
                                            }}
                                        >
                                            {gettermTimeUpdate5(m.end_day, m.text)}
                                        </span>
                                    ))}
                                </div>
                            )}
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={
                            <Row className='text-algin-center'>
                                <Col style={{ fontSize: '30px' }}>2</Col>
                                <Col style={{ fontSize: '15px' }}>{intl.get('policyholders')}</Col>
                            </Row>
                        }
                    >
                        <Form.Item label={intl.get('name_1')} {...formItemLayout}>
                            {getFieldDecorator('policyholderInfo.policyholders_name', {
                                rules: [
                                    {
                                        required: true,
                                        message: intl.get('policyholdersIsRequired'),
                                        pattern: new RegExp(/^[a-zA-Z0-9_\s]*$/),//要匹配等正则
                                    },
                                ],
                                initialValue: policyholderInfo.policyholders_name,
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label={intl.get('Gender')} {...formItemLayout}>
                            {getFieldDecorator('policyholderInfo.policyholders_sex', {
                                rules: [
                                    {
                                        required: true,
                                        message: intl.get('genderIsRequired'),
                                    },
                                ],
                                initialValue: policyholderInfo.policyholders_sex,
                            })(
                                <Select style={{ width: '100%' }}>
                                    <Select.Option key={1} value={1}>
                                        {intl.get('male')}
                                    </Select.Option>
                                    <Select.Option key={0} value={0}>
                                        {intl.get('female')}
                                    </Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label={intl.get('Birthday')} {...formItemLayout}>
                            {getFieldDecorator('policyholderInfo.policyholders_birthday', {
                                rules: [
                                    {
                                        type: 'object',
                                        required: true,
                                        message: intl.get('BirthdayIsRequired'),
                                    },
                                ],
                                initialValue: moment(policyholderInfo.policyholders_birthday),
                            })(<DatePicker allowClear={false} style={{ width: '100%' }} format="DD-MM-YYYY"
                                onChange={date => handleStartOpenChange(date)}
                                disabledDate={birthdayDisabledDate} />)}
                        </Form.Item>
                        <Form.Item label={intl.get('phone')} {...formItemLayout}>
                            {getFieldDecorator(`policyholderInfo.policyholders_phone`, {
                                rules: [
                                    {
                                        required: true,
                                        message: intl.get('phoneIsRequired'),
                                        pattern: new RegExp(/^[0-9_()（）+\-\s]*$/),//要匹配等正则
                                    },
                                ],
                                initialValue: policyholderInfo.policyholders_phone,
                            })(<Input />)}
                        </Form.Item>
                        <span className='my-wrap'>
                            <Form.Item label={intl.get('email')} {...formItemLayout}>
                                {getFieldDecorator(`policyholderInfo.policyholders_email`, {
                                    rules: [
                                        {
                                            message: intl.get('valid_email'),
                                            pattern: new RegExp(/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/),
                                        },
                                        {
                                            required: true,
                                            message: intl.get('emailIsRequired'),
                                        },
                                    ],
                                    initialValue: policyholderInfo.policyholders_email,
                                })(<Input placeholder={intl.get('valid_email')}></Input>)}
                            </Form.Item>
                        </span>
                        <Form.Item label={intl.get('credential_type')} {...formItemLayout}>
                            {getFieldDecorator('policyholderInfo.policyholders_credential_type', {
                                rules: [
                                    {
                                        required: true,
                                        message: intl.get('credential_type_is_required'),
                                    },
                                ],
                                initialValue: policyholderInfo.policyholders_credential_type,
                            })(
                                <Select style={{ width: '100%' }}>
                                    {Object.values(CREDENTIAL_TYPE.properties).map((e) => (
                                        <Select.Option key={e.key} value={e.key}>
                                            {intl.get(e.text)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label={intl.get('credential')} {...formItemLayout}>
                            {getFieldDecorator('policyholderInfo.policyholders_credential', {
                                rules: [
                                    {
                                        required: true,
                                        message: intl.get('credentialIsRequired'),
                                        pattern: new RegExp(/^[a-zA-Z0-9_\s]*$/),
                                    },
                                ],
                                initialValue: policyholderInfo.policyholders_credential,
                            })(<Input />)}
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={
                            <Row className='text-algin-center'>
                                <Col style={{ fontSize: '30px' }}>3</Col>
                                <Col style={{ fontSize: '15px' }}>{intl.get('insurer')}</Col>
                            </Row>
                        }>
                        <Row>
                            <Col style={{ height: '40px' }}>
                                <Button size='small' type='primary' className='pull-right' onClick={addItem}>
                                    {intl.get('btn_add')}
                                </Button>
                            </Col>
                            {insurerInfo.map((e, i) => (
                                <Col style={{ borderTop: '1px solid #eaeaea' }}>
                                    <Tag style={{ marginBottom: '15px' }} key={i} closable
                                        onClose={() => delInsurerList(i)}>
                                        {intl.get('insurer')}
                                        {i + 1}
                                    </Tag>
                                    <Form.Item label={intl.get('insurer_relation_ship')} {...formItemLayout}>
                                        {getFieldDecorator(`insurerInfo[${i}].insurer_relation_ship`, {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: intl.get('insurer_relation_ship_is_required'),
                                                },
                                            ],
                                        })(
                                            <Select
                                                style={{ width: '100px' }}
                                                value={e.insurer_relation_ship}
                                                onChange={(e) => changeUserInfo(e, 'insurer_relation_ship', i, getFieldValue('insurerInfo')[i]['insurer_birthday'])}>
                                                {Object.values(RELATIONSHIP.properties).map((e) => (
                                                    <Select.Option
                                                        key={e.key}
                                                        value={e.key}
                                                        disabled={e.key == 1 && getFieldValue('insurerInfo').filter((m) => m.insurer_relation_ship === 1)?.length > 0}>
                                                        {intl.get(e.text)}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    {/*{e.insurer_relation_ship != 1 &&*/}
                                    <Form.Item label={intl.get('name_1')} {...formItemLayout}>
                                        {getFieldDecorator(`insurerInfo[${i}].insurer_name`, {
                                            rules: [
                                                {
                                                    required: true,
                                                    pattern: new RegExp(/^[a-zA-Z0-9_\s]*$/),//要匹配等正则
                                                    message: intl.get('insurerIsRequired'),
                                                },
                                            ],
                                            initialValue: e.insurer_name,
                                        })(<Input
                                            disabled={getFieldValue('insurerInfo')[i]['insurer_relation_ship'] === 1} />)}
                                    </Form.Item>
                                    <Form.Item label={intl.get('Gender')} {...formItemLayout}>
                                        {getFieldDecorator(`insurerInfo[${i}].insurer_sex`, {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: intl.get('genderIsRequired'),
                                                },
                                            ],
                                            initialValue: e.insurer_sex,
                                        })(
                                            <Select
                                                disabled={getFieldValue('insurerInfo')[i]['insurer_relation_ship'] === 1}
                                                style={{ width: '100%' }}>
                                                <Select.Option key={1} value={1}>
                                                    {intl.get('male')}
                                                </Select.Option>
                                                <Select.Option key={0} value={0}>
                                                    {intl.get('female')}
                                                </Select.Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item label={intl.get('Birthday')} {...formItemLayout}>
                                        {getFieldDecorator(`insurerInfo[${i}].insurer_birthday`, {
                                            rules: [
                                                {
                                                    type: 'object',
                                                    required: true,
                                                    message: intl.get('BirthdayIsRequired'),
                                                },
                                            ],
                                            initialValue: moment().year(moment().year() - 1),
                                        })(<DatePicker allowClear={false}
                                            format="DD-MM-YYYY"
                                            disabled={getFieldValue('insurerInfo')[i]['insurer_relation_ship'] === 1}
                                            onChange={date => onRelationBirthDayChange(date, getFieldValue('insurerInfo')[i]['insurer_relation_ship'])}
                                            style={{ width: '100%' }} disabledDate={birthdayDisabledDate} />)}
                                    </Form.Item>
                                    <Form.Item label={intl.get('phone')} {...formItemLayout}>
                                        {getFieldDecorator(`insurerInfo[${i}].mobile_phone`, {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: intl.get('phoneIsRequired'),
                                                    pattern: new RegExp(/^[0-9_()（）+\-\s]*$/),//要匹配等正则
                                                },
                                            ],
                                            initialValue: e.mobile_phone,
                                        })(<Input
                                            disabled={getFieldValue('insurerInfo')[i]['insurer_relation_ship'] === 1} />)}
                                    </Form.Item>
                                    <Form.Item label={intl.get('credential_type')} {...formItemLayout}>
                                        {getFieldDecorator(`insurerInfo[${i}].insurer_credential_type`, {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: intl.get('credential_type_is_required'),
                                                },
                                            ],
                                            initialValue: e.insurer_credential_type,
                                        })(
                                            <Select
                                                disabled={getFieldValue('insurerInfo')[i]['insurer_relation_ship'] === 1}
                                                style={{ width: '100%' }}>
                                                {Object.values(CREDENTIAL_TYPE.properties).map((e) => (
                                                    <Select.Option key={e.key} value={e.key}>
                                                        {intl.get(e.text)}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item label={intl.get('credential')} {...formItemLayout}>
                                        {getFieldDecorator(`insurerInfo[${i}].insurer_credential`, {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: intl.get('credentialIsRequired'),
                                                    pattern: new RegExp(/^[a-zA-Z0-9_\s]*$/),
                                                },
                                            ],
                                            initialValue: e.insurer_credential,
                                        })(<Input
                                            disabled={getFieldValue('insurerInfo')[i]['insurer_relation_ship'] === 1} />)}
                                    </Form.Item>
                                    <Form.Item label={intl.get('credential_url')} {...formItemLayout}>
                                        {getFieldDecorator(`insurerInfo[${i}].insurer_credential_url`, {
                                            rules: [
                                                {   
                                                    required: getFieldValue('insurerInfo')[i]['insurer_credential_url']?false:true,
                                                    message: intl.get('credential_url_is_required'),
                                                },
                                                {
                                                    validator:(rules,value,callback)=>{handleValidator(rules,getFieldValue('insurerInfo')[i]['insurer_credential_url'],callback)}
                                                },
                                            ],
                                            // initialValue: e.insurer_credential_url,
                                        })(<TemplateUpload url={getFieldValue('insurerInfo')[i]['insurer_credential_url']}
                                            fileSuccess={(url) =>
                                                changeUserInfo1(e, i, url)
                                            } />)}

                                    </Form.Item>
                                </Col>
                            ))}
                        </Row>
                    </Descriptions.Item>
                </Descriptions>
                <div className='text-algin-center'>
                    <Button onClick={clickStep} className='margin-top-8 margin-right-8'>
                        {intl.get('btn_next')}
                    </Button>
                    <Button
                        onClick={() => {
                            props.history.go(-1);
                        }}
                        className='margin-top-8'
                    >
                        {intl.get('btn_cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            props.history.go(-2);
                        }}
                        style={{ marginTop: '8px', marginLeft: '8px' }}
                        className='margin-top-8'
                    >
                        {intl.get('Back_Home')}
                    </Button>
                </div>
            </React.Fragment>
        );
    };

    const stepSecondRender = () => {
        let insurer = formInfo.insurerInfo;
        let policyholder = formInfo.policyholderInfo;
        return (
            <React.Fragment>
                <Card style={{ width: '100%' }} title={intl.get('order_info')}
                    headStyle={{ backgroundColor: '#f6f8f9', border: 0 }}>
                    <Descriptions column={isMobileBrowser() ? 1 : 3}>
                        <Descriptions.Item
                            label={intl.get('product_name')}>{detail.product_name}</Descriptions.Item>
                        <Descriptions.Item
                            label={intl.get('insurance_premiums')}>USD{(detail.min_price * 1).toFixed(2)}</Descriptions.Item>
                        <Descriptions.Item
                            label={intl.get('Total_cost')}>USD{(detail.min_price * insurer.length * 1).toFixed(2)}</Descriptions.Item>
                    </Descriptions>
                </Card>
                <Card style={{ width: '100%', marginTop: '16px' }} title={intl.get('policyholders_Info')}
                    headStyle={{ backgroundColor: '#f6f8f9', border: 0 }}>
                    <Descriptions column={isMobileBrowser() ? 1 : 3}>
                        <Descriptions.Item
                            label={intl.get('name_1')}>{policyholder.policyholders_name}</Descriptions.Item>
                        <Descriptions.Item
                            label={intl.get('Gender')}>{policyholder.policyholders_sex ? intl.get('male') : intl.get('female')}</Descriptions.Item>
                        <Descriptions.Item
                            label={intl.get('Birthday')}>{moment(policyholder.policyholders_birthday).format('YYYY-MM-DD')}</Descriptions.Item>
                        <Descriptions.Item label={intl.get('credential_type')}>
                            {intl.get(CREDENTIAL_TYPE.properties[policyholder.policyholders_credential_type]?.text)}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={intl.get('credential')}>{policyholder.policyholders_credential}</Descriptions.Item>
                        <Descriptions.Item
                            label={intl.get('phone')}>{policyholder.policyholders_phone}</Descriptions.Item>
                        <Descriptions.Item
                            label={intl.get('term_insurance')}>{policyholderInfo.term_time}</Descriptions.Item>
                    </Descriptions>
                </Card>
                <Card className='sp-style' style={{ width: '100%', marginTop: '16px' }}
                    headStyle={{ backgroundColor: '#f6f8f9', border: 0 }}
                    title={intl.get('insurer') + intl.get('info')}>
                    <CommonTable
                        dataSource={insurer}
                        columns={[
                            {
                                title: intl.get('insurer_relation_ship'),
                                dataIndex: 'insurer_relation_ship',
                                key: 'insurer_relation_ship',
                                render: (t, r) => <span>{intl.get(RELATIONSHIP.properties[t].text)}</span>,
                            },
                            { title: intl.get('name_1'), dataIndex: 'insurer_name', key: 'insurer_name' },
                            {
                                title: intl.get('Gender'),
                                dataIndex: 'insurer_sex',
                                key: 'insurer_sex',
                                render: (t, r) => <span>{t ? intl.get('male') : intl.get('female')}</span>,
                            },
                            { title: intl.get('phone'), dataIndex: 'mobile_phone', key: 'mobile_phone' },
                            {
                                title: intl.get('Birthday'),
                                dataIndex: 'insurer_birthday',
                                key: 'insurer_birthday',
                                render: (t, r) => <span>{t ? moment(t).format('YYYY-MM-DD') : '--'}</span>,
                            },
                            {
                                title: intl.get('credential_type'),
                                dataIndex: 'insurer_credential_type',
                                key: 'insurer_credential_type',
                                render: (t, r) => <span>{intl.get(CREDENTIAL_TYPE.properties[t]?.text)}</span>,
                            },
                            {
                                title: intl.get('credential'),
                                dataIndex: 'insurer_credential',
                                key: 'insurer_credential'
                            }, {
                                title: intl.get('credential_url'),
                                align: "center",
                                dataIndex: 'insurer_credential_url',
                                key: 'insurer_credential_url',
                                render: (text) =>
                                    <Zmage src={text} width="30px" alt="" />
                            },
                        ]}
                        pagination={false}
                    />
                </Card>
                <div className='text-algin-center margin-top-8'>

                    <Tooltip title={intl.get('modify_policy')}>
                        <Button type='primary' onClick={clickStep} className='margin-top-8 margin-right-8'>
                            {intl.get('btn_next')}
                        </Button>
                    </Tooltip>
                    <Button
                        onClick={clickBackStep}
                        className='margin-top-8'
                    >
                        {intl.get('btn_cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            props.history.go(-2);
                        }}
                        style={{ marginTop: '8px', marginLeft: '8px' }}
                        className='margin-top-8'
                    >
                        {intl.get('Back_Home')}
                    </Button>


                </div>
            </React.Fragment>
        );
    };

    const stepThirdRender = () => {
        return (
            <React.Fragment>
                <Card className='sp-style' style={{ width: '100%' }} title={intl.get('order_info')}
                    headStyle={{ backgroundColor: '#f6f8f9', border: 0 }}>
                    <CommonTable
                        pagination={false}
                        dataSource={[{}]}
                        columns={[
                            {
                                title: intl.get('pay_info'),
                                dataIndex: 'pay_info',
                                key: 'pay_info',
                                render: (t, r) => <span>{detail.product_name}</span>,
                            },
                            {
                                title: intl.get('custom_name'),
                                dataIndex: 'name',
                                key: 'name',
                                render: (t, r) => <span>{formInfo?.policyholderInfo?.policyholders_name}</span>,
                            },
                            {
                                title: intl.get('term_insurance'),
                                dataIndex: 'time',
                                key: 'time',
                                render: (t, r) => <span>{policyholderInfo?.term_time}</span>,
                            },
                            {
                                title: intl.get('Amount'),
                                dataIndex: 'Amount',
                                key: 'Amount',
                                render: (t, r) => <span>USD{(orderDetail?.order_amount * 1).toFixed(2)}</span>,
                            },
                        ]}
                    />
                </Card>
                <Card
                    style={{ width: '100%' }}
                    title={
                        <div>
                            {intl.get('Amount_paid')}:
                            <span
                                className='font-size-20 font-color-orange'>USD{orderDetail.order_amount ? (orderDetail.order_amount * 1).toFixed(2) : '0.00'}</span>
                        </div>
                    }
                >
                    <form method='post' name='ePayment' action='https://payment.ipay88.com.kh/epayment/entry.asp'>
                        <input type='hidden' name='MerchantCode' value={prePareData.merchant_code} />
                        <input type='hidden' name='PaymentId' value={payType} />
                        <input type='hidden' name='RefNo' value={prePareData.ref_no} />
                        <input type='hidden' name='Amount' value={(prePareData.amount / 100).toFixed(2)} />
                        <input type='hidden' name='Currency' value={prePareData.currency} />
                        <input type='hidden' name='ProdDesc' value={prePareData.prod_desc} />
                        <input type='hidden' name='UserName' value={prePareData.user_name} />
                        <input type='hidden' name='UserEmail' value={prePareData.user_email} />
                        <input type='hidden' name='UserContact' value={prePareData.user_contact} />
                        <input type='hidden' name='Remark' value={prePareData.prod_desc} />
                        <input type='hidden' name='Lang' value='UTF-8' />
                        <input type='hidden' name='SignatureType' value='SHA256' />
                        <input type='hidden' name='Signature' value={prePareData.signature} />
                        <input type='hidden' name='ResponseURL' value={prePareData.response_url} />
                        <input type='hidden' name='BackendURL' value={prePareData.backend_url} />
                        <div style={{ width: '250px', backgroundColor: '#f6f6f6', padding: '16px' }}>
                            {/* <Radio checked>{PAY_TYPE.filter((e) => e.key == payType)[0]?.text}</Radio> */}
                            <div type='danger' size='small' onClick={() => setIsShowPayType(!isShowPayType)}>
                                <img src={payLogo} width='45%' />
                                <Icon type={isShowPayType ? 'up' : 'down'} />
                            </div>
                        </div>
                        {!isShowPayType && (
                            <div className='payDiv' style={{ width: isMobileBrowser() ? '100%' : '700px' }}>
                                {PAY_TYPE.map((e) => (
                                    <div
                                        value={e.key}
                                        key={e.key}
                                        onClick={() => {
                                            setPayType(e.key);
                                            setIsShowPayType(true);
                                        }}
                                        className='payBox'
                                        style={{ width: isMobileBrowser() ? '48%' : '24%' }}
                                    >
                                        {e.img}
                                    </div>
                                ))}
                            </div>
                        )}
                        <div
                            style={{ width: '100%', backgroundColor: '#f6f6f6', padding: '16px', marginTop: '16px' }}>
                            <div style={{
                                width: '140px',
                                float: 'left',
                                height: '80px'
                            }}>{PAY_TYPE.filter((e) => e.key == payType)[0]?.img}</div>
                            <div style={{ width: '100px', height: '60px', float: 'right', textAlign: 'right' }}>
                                <input
                                    type='submit'
                                    name='submit'
                                    value={intl.get('pay_now')}
                                    // align='right'
                                    style={{
                                        padding: '8px 16px',
                                        color: '#fff',
                                        backgroundColor: '#1890ff',
                                        border: '1px solid #eaeaea'
                                    }}
                                />
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                        <div className='text-algin-center margin-top-8'>
                            <Button
                                onClick={() => {
                                    props.history.go(-2);
                                }}
                                style={{ marginTop: '8px', marginLeft: '8px' }}
                            >
                                {intl.get('Back_Home')}
                            </Button>

                        </div>
                    </form>
                </Card>
            </React.Fragment>
        );
    };

    const stepRender = () => {
        switch (step) {
            case 0:
                return stepFirstRender();
            case 1:
                return stepSecondRender();
            case 2:
                return stepThirdRender();
            default:
                break;
        }
    };

    return (
        <div className={'head_Bg'}>
            <div style={{ position: 'fixed', top: '60px', right: '10px', zIndex: '999' }}>
                {/*<Tooltip title={intl.get('Choose_language')}>*/}
                <SelectLanguage style={{ marginLeft: '3px' }} />
                {/*</Tooltip>*/}
            </div>
            <div className={'head_TopIn'} style={{ minHeight: document.body.clientHeight }}>
                <Spin spinning={actionLoading}>
                    <Form layout='horizontal' onSubmit={clickStep}>
                        <div style={{ width: isMobileBrowser() ? '100%' : '100%', margin: '0 auto' }}>
                            <Card style={{ width: '100%' }}>
                                <Steps size='small' current={step}>
                                    <Step title={intl.get('Fill_in_information')} />
                                    <Step title={intl.get('Confirm_information')} />
                                    <Step title={intl.get('Complete')} />
                                </Steps>
                            </Card>
                            <div>{stepRender()}</div>
                        </div>
                    </Form>
                </Spin>
            </div>
        </div>
    );
};
export default Form.create()(PayStepsList);
