import http from '../helper/http';
import { Api_request } from './global/constants';

export const getAllCompany = async (success) => {
  const result = await http.get(Api_request + '/company/tree/all');
  if (result && result.data.errcode === 0) {
    const reSetData = (data) => {
      data.map((e) => {
        e.title = e.company_name;
        e.key = e.id;
        e.value = e.id;
        if (e.children) {
          reSetData(e.children);
        }
      });
    };
    result.data.data && reSetData(result.data.data);
    success(result.data.data);
  }
};

export const getAllMenus = async (success) => {
  const result = await http.get(Api_request + '/sys_menu/tree/all');
  if (result && result.data.errcode === 0) {
    const reSetData = (data) => {
      data.map((e) => {
        e.title = e.menu_name;
        e.key = e.id;
        e.value = e.id;
        if (e.children) {
          reSetData(e.children);
        }
      });
    };
    result.data.data && reSetData(result.data.data);
    success(result.data.data);
  }
};
