import http from '../../helper/http';
import { Api_request } from '../global/constants';

export const getOrderDetail = async (id, success) => {
  const result = await http.get(Api_request + `/order/${id}`);
  if (result && result.data.errcode === 0) {
    success(result.data.data);
  }
};

export const getInsureDetail = async (id, success) => {
  const result = await http.get(Api_request + `/order/insureNo/${id}`);
  if (result && result.data.errcode === 0) {
    success(result.data.data);
  }
};

export const exportExcel = async (params, success) => {
  let url = '/order/insure/export/sai';
  if (params.type == 'station') {
    url = '/order/insure/export/sai';
  }
  if (params.type == 'company') {
    url = '/order/insure/export/company';
  }
  if (params.type == 'channel') {
    url = '/order/insure/export/channel';
  }
  const result = await http.blobGet(`${Api_request}${url}`, { params: params });
  if (result && result.data) {
    success(result.data);
  }
};

export const importExcel = async (file, success) => {
  let url = Api_request + `/order/order_team`;
  let formData = new FormData();
  formData.append('excelFile', file);
  const result = await http.post(url, formData);
  if (result && result.data.errcode === 0) {
    success(result.data);
  }
};
export const testingOrder = async (order_no, params, success) => {
  const result = await http.post(Api_request + `/order/testing/${order_no}`, params);
  if (result && result.data.errcode === 0) {
    success(result.data);
  }
};

export const resendEmailSevice = async (order_no, params, success) => {
  const result = await http.post(Api_request + `/order/resendEmail/${order_no}`, params);
  if (result && result.data.errcode === 0) {
    success(result.data);
  }
};
