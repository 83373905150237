import http from '../../../helper/http';
import { Api_request } from '../../global/constants';

export const exportExcel = async (file, success) => {
  let url = Api_request + `/order/order_insure_no`;
  let formData = new FormData();
  formData.append('excelFile', file);
  const result = await http.post(url, formData);
  if (result && result.data.errcode === 0) {
    success(result.data);
  }
};
