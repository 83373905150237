import http from '../../../helper/http';
import { Api_request } from '../../global/constants';

export const createRole = async (params, success) => {
  const result = await http.post(Api_request + '/role', params);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const updateRole = async (params, success) => {
  const result = await http.put(Api_request + '/role' + params.id, params);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const delRole = async (ids, success) => {
  const result = await http.post(Api_request + '/role/delete', ids);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const getRoleMenus = async (id, success) => {
  const result = await http.get(Api_request + `/role/${id}/menu`);
  if (result && result.data.errcode === 0) {
    success(result.data.data);
  }
};

export const saveRoleMenus = async (param, success) => {
  const result = await http.post(Api_request + `/role/${param.id}/menu`, param.menuIds);
  if (result && result.data.errcode === 0) {
    success(result.data.data);
  }
};
