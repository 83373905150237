import intl from 'react-intl-universal';
import { Button, Row, Col, Icon, message} from 'antd';
import useTable from '../../../hooks/useTable';
import { getUrlParam } from '../../../helper/until';
import { Link } from 'react-router-dom';
import './index.css';
import CommonTable from '../../../components/CommonTable';
import { imageDomain } from "../../global/constants";
import React from 'react';
import CryptoJS from 'crypto-js';

const OrderResult = (props) => {
    const detail = useTable(
        {
            url: `/order/${getUrlParam('orderId')}`,
        },
        {
            pagination: true,
            noFetch: true,
        }
    );

    const settermTime = (seccess) => {
        if (seccess == 10 || seccess == 50) {
            let times = detail.data.term_time;
            times = times.replace("零时起", intl.get("lingshi"));
            times = times.replace("至", intl.get("to"));
            times = times.replace("二十四时止", intl.get("ershisixoaszhi"));
            times = times.replace("共", intl.get("total"));
            times = times.replace("天", intl.get("minDay"));
            return times;
        } else {
            return '';
        }
    };
    const downPdf = (seccess, r) => {
        if (seccess == 10 || seccess == 50) {
            if (r.insure_no) {
                // let insure_no = r.insure_no?.replace(/\//g, '-');
                // let ins = encodeAesString(insure_no);
                // let url = imageDomain + 'sai/' + insure_no + '.pdf';

                let ins = encodeAesString(r.insure_no);
                let insure_no = (ins?.replace(/\//g, '-')).replaceAll('+','-');
                // let ins = encodeAesString(insure_no);
                let url = imageDomain + 'sai/' + insure_no + '.pdf';

                window.open(url);
            } else {
                message.warning('文件不存在');
            }
        }
    };

    const encodeAesString = (data) => {
        let key = CryptoJS.enc.Utf8.parse("abcdef0123456789");
        let iv = CryptoJS.enc.Utf8.parse("abcdef0123456789");
        let encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        //返回的是base64格式的密文 
        // debugger
        return encrypted.toString();
    }

    // const downPdf = (r) => {
    //     if (r.insure_no) {
    //         let insure_no = r.insure_no?.replace(/\//g, '-');
    //         let ins = encodeAesString(insure_no);
    //         let url = imageDomain + 'sai/' + ins + '.pdf';
    //         window.open(url);
    //     } else {
    //         message.warning('文件不存在');
    //     }
    // };


    return (
        <div className={'head_Top'}>
            <div className={'head_TopIn'}>
                <Row gutter={16} style={{
                    width: '100%',
                    minHeight: '400px',
                    backgroundColor: '#f1f1de',
                    border: '1px solid #eaeaea',
                    padding: '8px'
                }}>
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            {(detail.data.order_status == 10 || detail.data.order_status == 50) ? (
                                <span style={{
                                    display: 'inline-block',
                                    backgroundColor: '#3cb93c',
                                    fontSize: '40px',
                                    color: '#fff',
                                    padding: '2px 10px'
                                }}> <Icon type='check-circle' /> </span>
                            ) : (
                                    <span style={{
                                        display: 'inline-block',
                                        backgroundColor: '#fd6060',
                                        fontSize: '40px',
                                        color: '#fff',
                                        padding: '2px 10px'
                                    }}>
                                        <Icon type='close-circle' />
                                    </span>
                                )}
                            <span style={{ fontSize: '30px', marginLeft: '20px' }}>
                                {(detail.data.order_status == 10 || detail.data.order_status == 50) ? intl.get('paySuccess') : intl.get('payFailed')}
                            </span>
                        </div>
                    </Col>
                    <Col style={{ marginTop: '16px' }}>
                        {intl.get('Total_cost')}：<span
                            className='font-color-orange'>USD{(detail?.data.order_amount * 1).toFixed(2)}</span>
                    </Col>
                    <Col style={{ marginTop: '16px' }}> {intl.get('tip')}：</Col>
                    <Col>
                        <CommonTable
                            pagination={false}
                            size='small'
                            columns={[
                                {
                                    title: intl.get('product_name'),
                                    dataIndex: 'product_name',
                                    key: 'product_name',
                                },
                                { title: intl.get('insurer_no'), dataIndex: 'insure_no', key: 'insure_no' },
                                { title: intl.get('insurer'), dataIndex: 'insurer_name', key: 'insurer_name' },
                                {
                                    title: intl.get('Amount'),
                                    dataIndex: 'Amount',
                                    key: 'Amount',
                                    render: (t, r, i) => {
                                        return <span>USD{(r.order_amount * 1).toFixed(2)}</span>;
                                    },
                                }, {
                                    title: intl.get('term_insurance'),
                                    dataIndex: 'term_time',
                                    key: 'term_time',
                                    render: (t, r, i) => {
                                        // {detail.data.order_status == 10 ? intl.get('paySuccess') : intl.get('payFailed')}
                                        // return <span>{detail.data.term_time}</span>;
                                        return <span>{settermTime(detail.data.order_status)}</span>;
                                    },
                                }, {
                                    title: intl.get('btn_download'),
                                    dataIndex: 'action',
                                    key: 'action',
                                    className: (detail.data.order_status == 10 || detail.data.order_status == 50) ? '' : 'hide-columns',

                                    render: (t, r) => (
                                        <div>
                                            {/*<Tooltip title='您的电子保单也同步已发送到您预留的邮箱'>*/}
                                            {/*   <Button onClick={() => downPdf(detail.data.order_status, r)}*/}
                                            {/*         className='font-link'>{detail.data.order_status == 10 ? intl.get('Policy_download') : ''}</Button>*/}
                                            {/*</Tooltip>*/}
                                            <Button style={{ backgroundColor: "#0086ff", color: "#fefefe" }} onClick={() => downPdf(detail.data.order_status, r)}
                                                className='font-link'>{(detail.data.order_status == 10 || detail.data.order_status == 50) ? intl.get('Policy_download') : ''}</Button>
                                        </div>
                                    ),
                                }
                            ]}
                            dataSource={detail.data.order_insure_dtolist}
                        />
                    </Col>
                    <Col style={{ marginTop: '16px' }}>
                        <Button className='pull-right' style={{ color: '#fff', backgroundColor: 'orange' }}>
                            <Link to={'/client/product_list'}>{intl.get('Buy_again')}</Link>
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default OrderResult;
