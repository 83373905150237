import http from '../../../helper/http';
import { Api_request } from '../../global/constants';

export const getWebLink = async (id, success) => {
  const result = await http.get(Api_request + `/product/${id}/link_web`);
  if (result && result.data.errcode === 0) {
    success(result.data.data);
  }
};
export const getMobileLink = async (id, success) => {
  const result = await http.get(Api_request + `/product/${id}/link_mobile_web`);
  if (result && result.data.errcode === 0) {
    success(result.data.data);
  }
};
