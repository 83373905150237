import http from '../../../helper/http';
import { Api_request } from '../../global/constants';

export const createChannel = async (params, success) => {
  const result = await http.post(Api_request + '/channel', params);
  if (result && result.data.errcode === 0) {
    success();  
  }
};

export const updateChannel = async (params, success) => {
  const result = await http.put(Api_request + '/channel/' + params.id, params);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const delChannel = async (ids, success) => {
  const result = await http.post(Api_request + '/channel/delete', ids);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const getChannelProduct = async (id, success) => {
  const result = await http.get(Api_request + `/channel/${id}/product`);
  if (result && result.data.errcode === 0) {
    success(result.data.data);
  }
};

export const createChannelProduct = async (id, params, success) => {
  const result = await http.post(Api_request + `/channel/${id}/product`, params);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const updateChannelProduct = async (params, success) => {
  const result = await http.put(Api_request + `/channel/${params.channel_id}/product/${params.id}`, params);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const delChannelProduct = async (id, params, success) => {
  const result = await http.post(Api_request + `/channel/${id}/product/delete`, params);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const getChannelUser = async (id, success) => {
  const result = await http.get(Api_request + `/channel/${id}/user`);
  if (result && result.data.errcode === 0) {
    success(result.data.data);
  }
};

export const createChannelUser = async (id, params, success) => {
  const result = await http.post(Api_request + `/channel/${id}/user`, params);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const delChannelUser = async (id, params, success) => {
  const result = await http.post(Api_request + `/channel/${id}/user/delete`, params);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const getWebLink = async (id, success) => {
  const result = await http.get(Api_request + `/channel/${id}/link_web`);
  // debugger;
  if (result && result.data.errcode === 0) {
    success(result.data.data);
  }
};
