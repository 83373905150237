import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Index from '../index/Index';
import Login from '../login';
import ProductList from '../clientPage/product/index';
import ProductDetail from '../clientPage/product/Detail';
import PayStepsList from '../clientPage/product/PaySteps';
import Result from '../clientPage/product/Result';
export default function RouterPath() {
  return (
    <Switch>
      <Route path='/page/login' exact={true} component={Login} />
      <Route path='/page/*' exact={true} component={Index} />
      <Route path='/page' exact={true} component={ProductList} />
      <Route path='/client/product_list' component={ProductList} />
      <Route path='/client/product_detail' component={ProductDetail} />
      <Route path='/client/PaySteps' component={PayStepsList} />
      <Route path={`/client/Result*`} component={Result} />
      <Route path='/*' component={ProductList} />
    </Switch>
  );
}
