import React, { useState, useEffect, Suspense } from 'react';
import { Layout, Menu, Col, Breadcrumb, Modal, Spin, Icon, ConfigProvider, Select } from 'antd';
import intl from 'react-intl-universal';
import { menu } from './constants';
import FreshMenu from './FreshMenu';
import { Route, Switch } from 'react-router-dom';

import http from '../../helper/http';
import { Api_request } from '../global/constants';
import { connect } from 'react-redux';
import classNames from 'classnames';
import ProductCreate from '../productManage/productConfig/Create';
import ProductEdit from '../productManage/productConfig/Edit';
import PremiumRateDetail from '../premiumRateManage/premiumRateConfig/Detail';
import InsurancePoliciesDetail from '../businessManage/insurancePolicies/Detail';
import ChannelPoliciesList from '../businessManage/insurancePolicies/Channel_detail';
import InsuranceAutoDetail from '../businessManage/InsuranceAuto/Detail';
import SelectLanguage from '../common/SelectLanguage';

import './index.css';

const locales = {
  'en-US': require('../../locales/en-US.json'),
  'zh-CN': require('../../locales/zh-CN.json'),
};
const { Header, Content } = Layout;
const { SubMenu } = Menu;

const routes = [];

const Index = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [breadcrumb, setBreadcrumb] = useState([{ id: '37c9add5-7cfc-4a88-88c3-7feb32f99865', name: '首页' }]);
  const [menusList, setMenusList] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [windowHeight, setWindowHeight] = useState(document.body.clientHeight);
  const [antdLang, setAntdLang] = useState('zh-CN');

  useEffect(() => {
    getMenusList();
    let localBreadcrumb = JSON.parse(localStorage.getItem('breadcrumb'));
    if (localBreadcrumb) {
      setBreadcrumb(localBreadcrumb);
    }
    window.onresize = function () {
      setWindowHeight(document.body.clientHeight);
    };
  }, []);
  const getMenusList = async () => {
    const result = await http.get(Api_request + '/sys_menu/tree/role');
    if (result.data && result.data.errcode === 0) {
      let list = result.data.data;
      list.map((e, i) => {
        if (e.children && e.children.length > 0) {
          e.children.map((m) => {
            if (m.menu_url) {
              let a = menu.find((n) => n.path.toUpperCase() === m.menu_url.toUpperCase());
              if (a) {
                m.component = a.component;
                routes.push({
                  path: a.path,
                  component: a.component,
                });
              }
            }
          });
        } else {
          let a = menu.find((n) => n.path.toUpperCase() === e.menu_url.toUpperCase());
          e.component = a.component;
          routes.push({
            path: a.path,
            component: a.component,
          });
        }
      });
      setMenusList(result.data.data);
      // menuItemChange()
    }
  };

  const menuItemChange = (item) => {
    let id = item.key;
    let breadcrumb = [];
    for (let e of menusList) {
      if (e.children && e.children.length > 0) {
        for (let q of e.children) {
          if (q.id === id) {
            breadcrumb.push(
              {
                key: e.id,
                name: e.menu_name,
              },
              {
                key: q.id,
                name: q.menu_name,
              }
            );
            break;
          }
        }
      } else {
        if (e.id === id) {
          breadcrumb.push({
            id: e.id,
            name: e.menu_name,
          });
          break;
        }
      }
    }
    setBreadcrumb(breadcrumb);
    localStorage.setItem('breadcrumb', JSON.stringify(breadcrumb));
  };

  const loginOut = () => {
    Modal.confirm({
      title: '确定要退出系统吗?',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const result = await http.put(Api_request + `/login/admin/out`);
        if (result && result.data.errcode === 0) {
          localStorage.removeItem('userInfo');
          // props.history.push('/');
          window.location.href="https://forte.saiinsure.com"
          // https://forte.saiinsure.com/
        }
      },
    });
  };

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const closeMenu = () => {
    setCollapsed(true);
  };
  // const handleChange = (val) => {
  //   // 发送消息
  //   emit.emit('change_language', val);
  // };

  const getHeader = (isMobile) => {
    return isMobile ? (
      <Header style={{ paddingLeft: 0, backgroundColor: '#fff' }}>
        <div className='trigger' style={{ float: 'left', color: '#000', backgroundColor: '#fff' }} onClick={toggle}>
          <Icon type={collapsed ? 'menu-unfold' : 'menu-fold'} />
        </div>
        <SelectLanguage />
        <Menu mode='horizontal' selectable={false} style={{ lineHeight: '62px', background: '#fff', color: '#000' }}>
          <SubMenu
            title={
              <span className='submenu-title-wrapper'>
                <span className='username username-hide-on-mobile' style={{ color: '#000' }}>
                  {props.userInfo.name || '--'}
                </span>
              </span>
            }
          >
            <Menu.Item style={{ textAlign: 'center' }} onClick={loginOut}>
              {intl.get('loginOut')}
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Header>
    ) : (
      <Header style={{ paddingLeft: 0, backgroundColor: '#fff' }}>
        <Col span={4}>
          <div className='trigger' style={{ float: 'left', color: '#000', backgroundColor: '#fff' }} onClick={toggle}>
            <Icon type={collapsed ? 'menu-unfold' : 'menu-fold'} />
          </div>
          <SelectLanguage />
        </Col>
        <Col span={2}></Col>
        <Col span={12}></Col>
        <Col span={6}>
          <Menu
            className='ant-menu-dark'
            mode='horizontal'
            selectable={false}
            style={{ lineHeight: '62px', float: 'right', background: '#fff', color: '#000' }}
          >
            <SubMenu
              title={
                <span className='submenu-title-wrapper'>
                  <span className='username username-hide-on-mobile' style={{ color: '#000' }}>
                    {props.userInfo.name || '--'}
                  </span>
                </span>
              }
            >
              <Menu.Item style={{ textAlign: 'center' }} onClick={loginOut}>
                {intl.get('loginOut')}
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Col>
      </Header>
    );
  };

  return (
    <ConfigProvider locale={antdLang}>
      <Layout id='mainContainer' className={classNames('main-layout-container', { 'height-screen': !isMobile })}>
        <FreshMenu
          onMenuItemChange={menuItemChange}
          menus={menusList}
          collapsed={collapsed}
          isMobile={isMobile}
          onCollapse={toggle}
          onClose={closeMenu}
          history={props.history}
        />
        <Layout style={{ overflow: 'hidden' }}>
          {getHeader(isMobile)}
          <Content id='watermark_container' className='min-height-screen'>
            <Suspense fallback={<Spin className='margin-top-8 margin-left-8' />}>
              <Breadcrumb style={{ margin: '8px 4px' }}>
                {breadcrumb.map((e) => (
                  <Breadcrumb.Item key={e.id}>{e.name}</Breadcrumb.Item>
                ))}
              </Breadcrumb>
              <div
                id='main-app-router-container'
                style={{
                  height: windowHeight - 90 + 'px',
                  overflow: 'auto',
                  padding: '16px',
                  paddingBottom: 105,
                }}
              >
                <div style={{ backgroundColor: '#fff', padding: '16px' }}>
                  <Switch>
                    {routes.map((e) => (
                      <Route key={e.path} path={e.path} component={e.component} />
                    ))}
                    <Route path='/page/product_config_create' component={ProductCreate} />
                    <Route path='/page/product_config_edit' component={ProductEdit} />
                    <Route path='/page/premium_rate_config_Detail' component={PremiumRateDetail} />
                    <Route path='/page/insurance_policies_detail' component={InsurancePoliciesDetail} />
                    <Route path='/page/insurance_channel_detail' component={ChannelPoliciesList} />
                    <Route path='/page/insurance_auto_detail' component={InsuranceAutoDetail} />
                  </Switch>
                </div>
              </div>
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

const mapStateToProps = (state) => {
  const { GlobalReducer } = state;
  return {
    userInfo: GlobalReducer.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
