import React, { useState, useEffect, Fragment } from 'react';
import { Input, Form } from 'antd';
import CommonTable from './CommonTable';
import './index.css';

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = (e) => {
    const { record, handleSave, editKey } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values }, editKey);
    });
  };

  renderCell = (form) => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title}为必填项`,
            },
          ],
          initialValue: record[dataIndex],
        })(<Input ref={(node) => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
      </Form.Item>
    ) : (
      <div className='editable-cell-value-wrap font-link' style={{ paddingRight: 24, textDecoration: 'underline' }} onClick={this.toggleEdit}>
        {children}
      </div>
    );
  };

  render() {
    const { editable, dataIndex, title, record, index, handleSave, editKey, children, disabled, ...restProps } = this.props;
    return <td {...restProps}>{editable && !disabled ? <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer> : children}</td>;
  }
}

const EditableTable = ({
  dataSource,
  columns,
  rowClassName = '',
  onEditChange,
  editKey,
  editRowKey = (record) => record.id,
  editDisabled,
  ...restProps
}) => {
  const [data, setData] = useState(dataSource);
  const [cols, setCols] = useState(columns);

  useEffect(() => {
    setData(dataSource);
  }, [dataSource]);

  useEffect(() => {
    const _columns = columns.map((col) => {
      if (!col.editable || editDisabled) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
          editKey: editRowKey,
          disabled: editDisabled,
        }),
      };
    });
    setCols(_columns);
  }, [columns, editDisabled]);

  const handleSave = (row, editKey) => {
    const newData = [...data];
    const index = newData.findIndex((item) => editKey(row) === editKey(item));
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    // setData(newData)
    onEditChange && onEditChange({ record: row, index });
  };

  const components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell,
    },
  };

  return (
    <Fragment>
      <CommonTable
        {...restProps}
        dataSource={data}
        components={components}
        rowClassName={(record, index) => 'editable-row' + ' ' + (typeof rowClassName === 'string' ? rowClassName : rowClassName(record, index))}
        columns={cols}
      />
    </Fragment>
  );
};

export default EditableTable;
