import { message } from 'antd';
import intl from 'react-intl-universal';
import axios from 'axios';
import { Utf8ArrayToStr } from './until';

axios.defaults.withCredentials = true;

export async function blobGet(url, config) {
  try {
    config = initialConfig(config);
    config.responseType = 'arraybuffer';
    const response = await axios.get(url, config);
    const contentType = response.headers['content-type'];
    let fileName = response.headers['content-disposition'];
    if (fileName) {
      fileName = fileName.replace(/.+filename=/, '');
      response.name = fileName;
    }
    if (contentType.indexOf('json') != -1) {
      const str = Utf8ArrayToStr(new Uint8Array(response.data));
      response.data = JSON.parse(str);
      errorResponse(response);
      return null;
    }
    return response;
  } catch (error) {
    message.error('导出失败！');
    return null;
  }
}

export async function get(url, config) {
  try {
    config = initialConfig(config);
    const response = await axios.get(url, config);
    if (response.data.errcode !== 0) {
      errorResponse(response);
    }
    return response;
  } catch (error) {
    message.error('网络不给力，请稍后重试！');
    return null;
  }
}

export async function post(url, body, config) {
  try {
    config = initialConfig(config);
    const response = await axios.post(url, body, config);
    if (response.data.errcode !== 0) {
      errorResponse(response);
    }
    return response;
  } catch (error) {
    message.error('网络不给力，请稍后重试！');
    return null;
  }
}

export async function put(url, body, config) {
  try {
    config = initialConfig(config);
    const response = await axios.put(url, body, config);
    if (response.data.errcode !== 0) {
      errorResponse(response);
    }
    return response;
  } catch (error) {
    message.error('网络不给力，请稍后重试！');
    return null;
  }
}

export async function del(url, config) {
  try {
    config = initialConfig(config);
    const response = await axios.delete(url, config);
    if (response.data.errcode !== 0) {
      errorResponse(response);
    }
    return response;
  } catch (error) {
    message.error('网络不给力，请稍后重试！');
    return null;
  }
}

function initialConfig(config) {
  config = config || {};
  config.headers = config.headers || {};
  config.params = config.params || {};
  config.params.language = intl.get('language');
  return config;
}

function errorResponse(response) {
  if (response.data.errcode === -10) {
    localStorage.removeItem('userInfo');
    window.location.href = '/';
    return;
  }
  message.error(response.data.errmsg);
}
export default {
  get,
  post,
  put,
  del,
  blobGet,
};
