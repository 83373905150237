import React, {useState, useEffect} from 'react';
import {Menu, Drawer, Layout, Input, Icon} from 'antd';
import {Link} from 'react-router-dom';
// import $ from 'jquery';
// import { setGlobalLoading } from './action';
// import styles from './index.module.less';
import logo1 from '../../image/fav.png';
import logo2 from '../../image/logo2.jpg';

const SubMenu = Menu.SubMenu;
const Sider = Layout.Sider;

const FreshMenu = (props) => {
    const [nowHeight, setNowHeight] = useState(window.innerHeight);
    const [openKeys, setOpenKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [updateMenuFlag, setUpdateMenuFlags] = useState(true);
    const [formatedMenu, setFormatedMenu] = useState([]);
    const [curPathName, setCurPathName] = useState('');

    useEffect(() => {
        // this.onWindowResize = this.onWindowResize.bind(this);
        window.addEventListener('resize', onWindowResize);

        // 监听 history 事件，显示对应菜单
        props.history.listen((location, action) => {
            const {pathname} = location;
            setCurPathName(pathname);
            setUpdateMenuFlags(true);
        });
    }, []);

    useEffect(() => {
        // 进入页面后自动展开当前页面对应菜单
        const curUrl = window.location.href;
        const curOrigin = window.location.origin;
        const curPath = curPathName ? curPathName : curUrl.replace(curOrigin, '');
        const newMenu = [...props.menus];
        if (newMenu.length && updateMenuFlag) {
            newMenu.forEach((item) => {
                item.parent_path = '';
                item.openKeys = [];
                const map = (items) => {
                    const {children, name, parent_path, path, id, openKeys} = items;
                    if (children && children.length > 0) {
                        items.parent_path += ' > ' + name;
                        items.openKeys = [...openKeys, id];
                        children.forEach((child) => {
                            child.openKeys = items.openKeys;
                            child.parent_path = items.parent_path;
                            map(child);
                        });
                        return;
                    }

                    // 当路径一致，则展开对应菜单
                    if (path === curPath) {
                        setOpenKeys(openKeys);
                        setSelectedKeys([id]);
                        scrollIntoView(id);
                    }
                    item.parent_path = parent_path.replace(/( > ){1}/, '');
                };
                map(item);
            });

            setFormatedMenu(newMenu);
            setUpdateMenuFlags(false);
        }
    }, []);

    /**
     * 处理菜单数据
     */
    const handleMenuData = (menu) => {
        let idArr = [],
            nameArr = [];
        idArr.push(menu.id);
        nameArr.push(menu.name);
        if (menu.parent && Object.keys(menu.parent).length > 0) {
            const {module_ids, module_names} = handleMenuData(menu.parent);
            idArr = [...idArr, ...module_ids];
            nameArr = [...nameArr, ...module_names];
        }
        return {
            module_ids: idArr,
            module_names: nameArr,
        };
    };

    const checkSource = (menu) => {
        //如果是已打开的菜单，直接return
        //if(window.location.href.indexOf(menu.path)>=0)return;
        //无论什么情况，直接刷新页面
        if (props.isMobile) {
            props.onClose();
        }
        if (window.location.href.indexOf(menu.menu_url) === -1) {
            clearCacheParam();
        }
    };
    const onWindowResize = () => {
        setNowHeight(window.innerHeight);
    };

    // 清空缓存的页面搜索数据
    const clearCacheParam = () => {
        let delKeys = [];
        for (let index = 0; index < sessionStorage.length; index++) {
            const cacheStorageKey = sessionStorage.key(index);
            if (cacheStorageKey.startsWith('CacheParams-')) {
                delKeys.push(cacheStorageKey);
            }
        }
        for (const delKey of delKeys) {
            sessionStorage.removeItem(delKey);
        }
    };

    const scrollIntoView = (id) => {
        setTimeout(() => {
            const dom = document.getElementById(id);
            dom && dom.scrollIntoView({behavior: 'smooth'});
        }, 500);
    };

    /**
     * 菜单栏变为受控组件，SubMenu 展开/关闭的回调
     * @param {array} openKeys
     */
    const handleOpenChange = (openKeys) => {
        setOpenKeys(openKeys);
    };

    /**
     * 菜单栏变为受控组件，点击MenuItem调用此函数
     * @param { item, id, keyPath, domEvent } param0
     */
    const handleMenuItemClick = ({id, item}) => {
        setSelectedKeys([id]);
        // console.log('1234', item);
        // onMenuItemChange();
    };

    const renderMenu = (menu) => {
        if (menu.children && menu.children.length > 0) {
            const childMenus = menu.children.map((m) => {
                return renderMenu(m);
            });
            let title = (
                <span>
          <Icon type={menu.menu_icon}></Icon>
          <span>{menu.menu_name}</span>
        </span>
            );
            return (
                <SubMenu key={menu.id} title={title} onClick={(item) => props.onMenuItemChange(item)}>
                    {childMenus}
                </SubMenu>
            );
        } else {
            return (
                <Menu.Item key={menu.id} id={menu.id} onClick={(item) => props.onMenuItemChange(item)}>
                    <Link to={{pathname: menu.menu_url}} target={menu.target_blank ? '_blank' : undefined}
                          onClick={() => checkSource(menu)}>
                        <Icon type={menu.menu_icon ? menu.menu_icon : 'icon-folder'}/>
                        {menu.menu_name}
                    </Link>
                </Menu.Item>
            );
        }
    };
    const menus = props.menus.map((m) => {
        return renderMenu(m);
    });
    const {isMobile, collapsed, onCollapse} = props;

    const otherProps = collapsed ? {} : {openKeys};

    return isMobile ? (
        <Drawer
            className='menu-drawer'
            visible={!collapsed}
            placement='left'
            onClose={() => onCollapse(true)}
            style={{
                padding: 0,
                height: '100%',
            }}
            width='auto'
            bodyStyle={{padding: '0'}}
        >
            <Sider
                className='menu-sider'
                collapsed={isMobile ? false : collapsed}
                onCollapse={onCollapse}
                collapsible
                trigger={null}
                style={{overflow: 'auto', backgroundColor: '#fff', borderRight: '1px solid #eaeaea'}}
                width={235}
            >
                <div className='logo' style={collapsed ? {
                    textAlign: 'center',
                    lineHeight: '64px',
                    fontSize: '22px',
                    color: '#fff'
                } : {paddingLeft: 24}}>
                    {/* {collapsed ? '后台' : '管理后台'} */}
                    {/* <img src={logo1} style={{width: '60px'}}/> */}
                    <img src={logo2} style={{width: '120px', backgroundColor: '#fff', padding: 5}}/>
                </div>
                <Menu mode='inline' theme='light' onOpenChange={handleOpenChange} selectedKeys={selectedKeys}
                      onSelect={handleMenuItemClick} {...otherProps}>
                    {menus}
                </Menu>
                <div style={{height: 100}}></div>
            </Sider>
        </Drawer>
    ) : (
        <Sider
            className='menu-sider'
            collapsed={collapsed}
            onCollapse={onCollapse}
            collapsible
            trigger={null}
            width={328}
            id='menuSider'
            style={{overflow: 'auto', backgroundColor: '#fff', borderRight: '1px solid #eaeaea'}}
        >
            <div className='logo'
                 style={{paddingLeft: 14, lineHeight: '64px', textAlign: 'center', fontSize: '22px', color: '#fff'}}>
                {/* <img src={logo1} style={{width: '60px'}}/> */}
                <img src={logo2} style={{width: '120px', backgroundColor: '#fff', padding: 5}}/>
            </div>
            <Menu mode='inline' theme='light' onOpenChange={handleOpenChange} selectedKeys={selectedKeys}
                  onClick={handleMenuItemClick} {...otherProps}>
                {menus}
            </Menu>
            <div style={{height: 100}}></div>
        </Sider>
    );
};

export default FreshMenu;
