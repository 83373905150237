import React, { useState, useEffect, Toast } from 'react';
import Alipay from '../../image/LogoPartner/Alipay.png';
import WeChat from '../../image/LogoPartner/WeChate.png';
import AcledaXpay from '../../image/LogoPartner/ACLEDABank.png';
import eWllet from '../../image/LogoPartner/Wing.png';
import Pipay from '../../image/LogoPartner/PiPay.png';
import ChipMong from '../../image/LogoPartner/CHIPMONG.png';
import CreditCard from '../../image/LogoPartner/credit.png';
import UnionPay from '../../image/LogoPartner/UnionPay_logo.png';

// export let Api_request = `http://47.254.237.46:8090`;
// export let Api_request = 'http://127.0.0.1:8091';
// export let Api_request = `http://211.149.160.171:8092`;
export let Api_request = 'https://forte.saiinsure.com/api';
export const imageDomain = 'https://sai123.oss-cn-hongkong.aliyuncs.com/';

export const USE_STATUS = {
  // All: -1,
  EFFECTIVE: 1,
  INVALID: 0,
  properties: {
    // 2: { text: 'All', key: -1 },
    1: { text: 'Effective', key: 1 },
    0: { text: 'Invalid', key: 0 },
  },
};

export const USE_SEX = {
  // All: -1,
  male: 1,
  female: 0,
  properties: {
    // 2: { text: 'All', key: -1 },
    1: { text: 'male', key: 1 },
    0: { text: 'female', key: 0 },
  },
};
export const CLASSIFY = {
  All: 1,
  CAR: 10,
  PROPERTY: 20,
  TRANSPORT: 30,
  PROJECT: 40,
  ACCIDENT: 50,
  MEDICAL: 60,
  OTHER: 70,
  properties: {
    1: {
      text: 'All',
      key: 1,
      sub: [
        { text: 'All', key: 1 }
      ],
    },
    10: {
      text: 'cat_insurance',
      key: 10,
      sub: [
        { text: 'All', key: 1 },
        { text: 'insurance_101', key: 101 },
        { text: 'insurance_102', key: 102 },
        { text: 'insurance_103', key: 103 },
        // { text: 'insurance_503', key: 503 },
        // { text: 'insurance_504', key: 504 },
        // { text: 'insurance_505', key: 505 },
      ],
    },
    50: {
      text: 'Accident_insurance',
      key: 50,
      sub: [
        { text: 'All', key: 1 },
        // { text: 'insurance_501', key: 501 },
        // { text: 'cat_insurance', key: 500 },
        { text: 'insurance_502', key: 502 },
        { text: 'insurance_503', key: 503 },
        { text: 'insurance_504', key: 504 },
        { text: 'insurance_505', key: 505 },
        { text: 'insurance_506', key: 506 },
      ],
    },
    60: {
      text: 'Medical_Health_Insurance',
      key: 60,
      sub: [
        { text: 'All', key: 1 },
        { text: 'insurance_601', key: 601 },
        { text: 'insurance_602', key: 602 },
      ],
    },
    70: {
      text: 'other_Insurance',
      key: 70,
      sub: [{ text: ' ', key: 701 }],
    },
  },
};

export const ORDER_STATUS = {
  TOBEPAID: 0,
  PAIDFAILED: 5,
  PAIDSUCCESS: 10,
  CANCEL: 20,
  RERIREDING: 30,
  RERIRED: 40,
  properties: {
    0: { text: 'statics_1', key: 0 },
    5: { text: 'statics_2', key: 5 },
    20: { text: 'statics_3', key: 20 },
    50: { text: 'statics_4', key: 50 },
    60: { text: 'statics_5', key: 60 },
    10: { text: 'statics_6', key: 10 },
  },
};

export const TEAM_STATUS = {
  properties: {
    0: { text: 'personal', key: 0 },
    1: { text: 'team', key: 1 },
  },
};

export const CREDENTIAL_TYPE = {
  // type_1: 1, //身份证
  type_2: 2, //出生证
  type_3: 3, //港澳台回乡证
  type_4: 4, //中国护照
  type_5: 5, //外国护照
  type_6: 6, //其他
  properties: {
    // 1: { text: 'Identity_card', key: 1 },
    // 2: { text: 'Birth_certificate', key: 2 },
    // 3: { text: 'home_certificate', key: 3 },
    4: { text: 'Chinese_passport', key: 4 },
    5: { text: 'Foreign_passports', key: 5 },
    6: { text: 'Other', key: 6 },
  },
};
//relationship
//1 本人、2 配偶、3 子女、4 父子、5 父女、6 母子、7 母女、8 其他
export const RELATIONSHIP = {
  relation_1: 1,
  relation_2: 2,
  relation_3: 3,
  relation_4: 4,
  relation_5: 5,
  relation_6: 6,
  relation_7: 7,
  relation_8: 8,
  properties: {
    1: { text: 'Myself', key: 1 },
    2: { text: 'Spouse', key: 2 },
    3: { text: 'Children', key: 3 },
    4: { text: 'father_and_son', key: 4 },
    5: { text: 'father_and_dauther', key: 5 },
    6: { text: 'mother_and_son', key: 6 },
    7: { text: 'mother_and_dauther', key: 7 },
    8: { text: 'mother_and_father', key: 8 },
    9: { text: 'Other', key: 9 },
  },
};
// import Alipay from '../../image/LogoPartner/Alipay.png';
// import WeChat from '../../image/LogoPartner/WeChate.png';
// import AcledaXpay from '../../image/LogoPartner/ACLEDABank.png';
// import eWllet from '../../image/LogoPartner/Wing.png';
// import Pipay from '../../image/LogoPartner/PiPay.png';
// import ChipMong from '../../image/LogoPartner/CHIPMONG.png';
// import CreditCard from '../../image/LogoPartner/credit.png';
// import UnionPay from '../../image/LogoPartner/UnionPay_logo.png';
export const PAY_TYPE = [
  { key: 233, text: 'Alipay', img: <img src={Alipay} width='140px' height='60px' align='center'/> },
  { key: 240, text: 'WeChat', img: <img src={WeChat} width='140px' height='60px'/> },
  { key: 3, text: 'Acleda Xpay', img: <img src={AcledaXpay} width='140px' height='60px'/> },
  { key: 235, text: 'eWllet', img: <img src={eWllet} width='140px' height='60px'/> },
  { key: 11, text: 'Pipay', img: <img src={Pipay} width='140px' height='60px'/> },
  { key: 238, text: 'Chip Mong', img: <img src={ChipMong} width='140px' height='60px'/> },
  { key: 1, text: 'Credit Card', img: <img src={CreditCard} width='140px' height='60px'/> },
  { key: 15, text: 'UnionPay', img: <img src={UnionPay} width='140px' height='60px'/> },
];

export const INSURESTATUS = [
  { key: -1, text: 'All' },
  { key: 0, text: 'Not_used' },
  { key: 1, text: 'Used' },
];
