import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { Button, Input, Row, Col, Modal, message, Select, TreeSelect, Spin } from 'antd';
import useTable from '../../../hooks/useTable';
import ConditionPanel from '../../..//components/ConditionPanel';
import CommonTable from '../../../components/CommonTable';
import TemplateForm from '../../common/TemplateForm';
import { useParams } from '../../../hooks/utils';
// import {USE_STATUS} from '../../global/constants';
import { CLASSIFY, USE_STATUS } from '../../global/constants';
import moment from 'moment';
import { isMobileBrowser } from '../../../helper/until';
import { createMail, updateMail,test_Email, saveMail, delMail, getMail } from './service';
// import {getAllMenus} from '../../service';

const { SHOW_ALL } = TreeSelect;
const MailList = (props) => {
    const [actionLoading, setActionLoading] = useState(false);
    const [allMenus, setAllMenus] = useState([]);
    const [mailIds, setMailIds] = useState([]);
    const [visible, setVisible] = useState(false);
    const [type, setType] = useState('add');
    // const [name, setName] = useState('');
    const [searchParam, updateSearchParam] = useParams({
        product_name: '',
        product_code: '',
        classify: '',
        classify_sub: '',
        receive_email: '',
        cc_email: '',
        email_pass: '',
        email_host: '',
        status: true,
    });
    const [param, updateParam] = useParams({
        product_name: '',
        product_code: '',
        classify: '1',
        classify_sub: '1',
        receive_email: '',
        cc_email: '',
        email_pass: '',
        email_host: '',
        status: true,
    });
    const { tableProps, search, selectedKeys, data } = useTable(
        {
            url: '/email',
            params: searchParam,
        },
        {
            pagination: true,
            noFetch: true,
            rowSelection: true,
        }
    );

    const columns = [
        {
            title: intl.get('classify'),
            dataIndex: 'classify',
            key: 'classify',
            render: (t, r) =>
                <span>{intl.get(CLASSIFY.properties[t].text)}</span>,
        },
        {
            title: intl.get('classify_sub'),
            dataIndex: 'classify_sub',
            key: 'classify_sub',
            render: (t, r) => {
                let CLASSIFY_SUB = t ? CLASSIFY.properties[r.classify * 1].sub : [];
                let item = CLASSIFY_SUB ? CLASSIFY_SUB.find((m) => m.key === t * 1) : {};
                return <span>{item ? intl.get(item.text) : '--'}</span>;
            },
        },
        {
            title: intl.get('receive_email'), dataIndex: 'receive_email', key: 'receive_email',
        },
        {
            title: intl.get('email_pass'), dataIndex: 'email_pass', key: 'email_pass',
        },
        {
            title: intl.get('email_host'), dataIndex: 'email_host', key: 'email_host',
        },
        { title: intl.get('cc_email'), dataIndex: 'cc_email', key: 'cc_email' },
        {
            title: intl.get('status'),
            dataIndex: 'status',
            key: 'status',
            render: (t, r) =>
                <span>{USE_STATUS.properties[t ? 1 : 0] ? intl.get(USE_STATUS.properties[t ? 1 : 0].text) : '--'}</span>,
        },
        { title: intl.get('time_create'), dataIndex: 'create_time', key: 'create_time' },
        {
            title: intl.get('test_email'),
            dataIndex: 'test_email',
            key: 'test_email',
            render: (t, r) => (
                <Button size='small' type='primary' onClick={() => testEmail(r.id)}>
                    {intl.get('test_email')}
                </Button>
            ),
        },
    ];

    const testEmail = (id) => {
        setActionLoading(true);
        test_Email(id, (data) => {
            // debugger
            message.success(data);
            setActionLoading(false);
        });
    }


    const getEditClassifySub = (classify, classify_sub) => {
        let CLASSIFY_SUB = classify ? CLASSIFY.properties[classify * 1].sub : [];
        let item = CLASSIFY_SUB ? CLASSIFY_SUB.find((m) => m.key === classify_sub * 1) : {};
        return item ? intl.get(item.text) : '--';
    };

    const toggleModal = (type, r) => {
        // debugger
        updateParam({
            product_name: '',
            product_code: '',
            classify: '1',
            classify_sub: '1',
            receive_email: '',
            cc_email: '',
            email_pass: '',
            email_host: '',
            status: true,
        });
        // debugger
        if (type === 'edit' && selectedKeys.length != 1) {
            message.warning(intl.get('onlyOneByEdit'));
            return false;
        }
        if (type === 'edit') {
            let item = data.find((m) => m.id === selectedKeys[0]);
            // classify = item.classify;
            updateParam(item);
        }
        // if (type === 'add') {
        //     updateParam({
        //         product_name: '',
        //         product_code: '',
        //         classify: '',
        //         classify_sub: '',
        //         receive_email: '',
        //         cc_email: '',
        //         email_pass: '',
        //         email_host: '',
        //         status: true,
        //     });
        // }
        setVisible(!visible);
        setType(type);
    };

    const saveItem = () => {

        var reg = /^(\w)+(\.\w+)*@(\w)+((\.\w{2,3}){1,3})$/;

        if (!param.classify || param.classify == '') {
            message.warning(intl.get('prodducet_classify'));
            return false;
        }

        if (!param.classify_sub || param.classify_sub == '') {
            message.warning(intl.get('prodducet_classify_sub'));
            return false;
        }

        if (reg.test(param.receive_email) === false) {
            message.warning(intl.get('emailIsRequired'));
            return false;
        }

        if (reg.test(param.cc_email) === false) {
            message.warning(intl.get('emailIsRequired'));
            return false;
        }

        setActionLoading(true);
        if (type === 'add') {
            createMail(param, () => {
                message.success(intl.get('createSuccess'));
                search(1);
                toggleModal();
                setActionLoading(false);
            });
        } else {
            param.id = selectedKeys[0];
            updateMail(param, () => {
                message.success(intl.get('editSuccess'));
                search(1);
                toggleModal();
                setActionLoading(false);
            });
        }
    };

    const saveMailItem = () => {
        if (!mailIds || mailIds.length === 0) {
            message.warning(intl.get('empty'));
            return false;
        }
        setActionLoading(true);
        let item = {
            id: param.id,
            mailIds: mailIds,
        };
        saveMail(item, () => {
            message.success(intl.get('operationSuccess'));
            search(1);
            toggleModal();
            setActionLoading(false);
        });
    };

    const delList = () => {
        if (!selectedKeys || selectedKeys.length == 0) {
            message.warning(intl.get('empty'));
            return false;
        }
        setActionLoading(true);
        delMail(selectedKeys, () => {
            message.success(intl.get('deleteSuccess'));
            search(1);
            setActionLoading(false);
        });
        setTimeout(() => {
            setActionLoading(false);
        }, 5000);
    };

    return (
        <Spin spinning={actionLoading}>
            <ConditionPanel labelWidth={90}>
                {/* <ConditionPanel.Item label={intl.get('product_name') + ':'}>
                    <Input
                        onChange={(e) => {
                            setName(e.target.value);
                            updateSearchParam({ receive_email: e.target.value});
                        }}
                        style={{ width: '100%' }}
                        placeholder={intl.get('placeholder')}
                        allowClear
                    />
                </ConditionPanel.Item> */}

                <ConditionPanel.Item label={intl.get('classify') + ':'}>
                    <Select style={{ width: '100%' }}
                        onChange={(e) => updateSearchParam({ classify: e, classify_sub: '' })}>
                        {Object.values(CLASSIFY.properties).map((e) => (
                            <Select.Option key={e.key} value={e.key}>
                                {intl.get(e.text)}
                            </Select.Option>
                        ))}
                    </Select>
                </ConditionPanel.Item>
                <ConditionPanel.Item label={intl.get('classify_sub') + ':'}>
                    <Select style={{ width: '100%' }} onChange={(e) => updateSearchParam({ classify_sub: e })}>
                        {(CLASSIFY.properties[param.classify] ? CLASSIFY.properties[param.classify].sub : []).map((e) => (
                            <Select.Option key={e.key} value={e.key}>
                                {intl.get(e.text)}
                            </Select.Option>
                        ))}
                    </Select>
                </ConditionPanel.Item>
                <ConditionPanel.Item label={intl.get('receive_email') + ':'}>
                    <Input
                        onChange={(e) => {
                            updateSearchParam({ receive_email: e.target.value });
                        }}
                        style={{ width: '100%' }}
                        // placeholder={intl.get('placeholder')}
                        allowClear
                    />
                </ConditionPanel.Item>
                <ConditionPanel.Item enterSearch>
                    <Button type='primary' block onClick={() => search(1)}>
                        {intl.get('btn_search')}
                    </Button>
                </ConditionPanel.Item>
            </ConditionPanel>
            {props.userInfo.is_station && (
                <Row className='margin-bottom-5'>
                    <Col>
                        <Button type='primary' size='small' onClick={() => toggleModal('add')}>
                            {intl.get('btn_add')}
                        </Button>
                        <Button className='margin-left-5' type='primary' size='small'
                            onClick={() => toggleModal('edit')}>
                            {intl.get('btn_edit')}
                        </Button>
                        <Button className='margin-left-5' type='primary' size='small' onClick={delList}>
                            {intl.get('btn_del')}
                        </Button>
                    </Col>
                </Row>)
            }

            <CommonTable columns={columns} {...tableProps} />
            {/* 操作 */}
            <Modal
                width={isMobileBrowser() ? '100%' : '50%'}
                title={intl.get('operation')}
                visible={type !== 'menus' && visible}
                destroyOnClose={true}
                maskClosable={false}
                onOk={saveItem}
                onCancel={toggleModal}
            >

                <TemplateForm
                    title={intl.get('classify')}
                    span={[8, 12, 0]}
                    condition={
                        <Select style={{ width: '100%' }} defaultValue={intl.get(CLASSIFY.properties[param.classify].text)}
                            onChange={(e) => updateParam({ classify: e, classify_sub: '' })}>
                            {Object.values(CLASSIFY.properties).map((e) => (
                                <Select.Option key={e.key} value={e.key}>
                                    {intl.get(e.text)}
                                </Select.Option>
                            ))}
                        </Select>
                    }
                />

                <TemplateForm
                    title={intl.get('classify_sub')}
                    span={[8, 12, 0]}
                    condition={
                        <Select style={{ width: '100%' }}
                            // defaultValue={intl.get(CLASSIFY.properties[param.classify].sub.find(param.classify_sub).text)}
                            defaultValue={getEditClassifySub(param.classify, param.classify_sub)}
                            onChange={(e) => updateParam({ classify_sub: e })}>
                            {(CLASSIFY.properties[param.classify] ? CLASSIFY.properties[param.classify].sub : []).map((e) => (
                                <Select.Option key={e.key} value={e.key}>
                                    {intl.get(e.text)}
                                </Select.Option>
                            ))}
                        </Select>
                    }
                />

                <TemplateForm
                    title={intl.get('receive_email')}
                    span={[8, 12, 0]}
                    condition={<Input value={param.receive_email} onChange={(e) => updateParam({ receive_email: e.target.value })} />}
                />
                <TemplateForm
                    title={intl.get('email_pass')}
                    span={[8, 12, 0]}
                    condition={<Input value={param.email_pass} onChange={(e) => updateParam({ email_pass: e.target.value })} />}
                />
                <TemplateForm
                    title={intl.get('email_host')}
                    span={[8, 12, 0]}
                    condition={<Input value={param.email_host} onChange={(e) => updateParam({ email_host: e.target.value })} />}
                />
                <TemplateForm
                    title={intl.get('cc_email')}
                    span={[8, 12, 0]}
                    condition={<Input value={param.cc_email} onChange={(e) => updateParam({ cc_email: e.target.value })} />}
                />
                <TemplateForm
                    title={intl.get('status')}
                    span={[8, 12, 0]}
                    condition={
                        <Select defaultValue={param.status} onChange={(e) => updateParam({ use_status: e })}
                            style={{ width: '100%' }}>
                            {Object.values(USE_STATUS.properties).map((e) => (
                                <Select.Option key={e.key} value={e.key}>
                                    {intl.get(e.text)}
                                </Select.Option>
                            ))}
                        </Select>
                    }
                />
            </Modal>
            <Modal
                width={isMobileBrowser() ? '100%' : '50%'}
                title={intl.get('assignment_menu')}
                visible={type === 'menus' && visible}
                destroyOnClose={true}
                maskClosable={false}
                // onOk={saveMenusItem}
                onCancel={toggleModal}
            >
                <TemplateForm
                    title={intl.get('menu')}
                    span={[8, 12, 0]}
                    condition={
                        <TreeSelect
                            style={{ width: '100%' }}
                            // value={roleMenusIds}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            treeData={allMenus}
                            treeCheckable={true}
                            showCheckedStrategy={SHOW_ALL}
                            placeholder='Please select'
                            treeDefaultExpandAll
                        // onChange={(e) => setRoleMenusIds(e)}
                        />
                    }
                />
            </Modal>
        </Spin>
    );
};
const mapStateToProps = (state) => {
    const { GlobalReducer } = state;
    return {
        userInfo: GlobalReducer.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MailList);
