import React, { useState, useEffect } from 'react';
import { Descriptions, Card, Row, Col, Button } from 'antd';
import intl from 'react-intl-universal';
import moment from 'moment';
import { ORDER_STATUS, USE_SEX, CREDENTIAL_TYPE } from '../../global/constants';
import { isMobileBrowser, getUrlParam } from '../../../helper/until';
import { getInsureDetail } from './service';
// import {CREDENTIAL_TYPE} from '../../global/constants';
import Zmage from 'react-zmage';

const InsurancePoliciesDetail = (props) => {
    const insureId = getUrlParam('id');
    const [detail, setDetail] = useState({});

    useEffect(() => {
        getInsureDetail(insureId, (data) => {
            setDetail(data);
        });
    }, []);

    const getStatics = (statics) => {
        if (statics != null) {
            return intl.get(ORDER_STATUS.properties[statics]?.text);
        }
    };
    return (
        <Col>

            <Row style={{ marginBottom: '1.5em', marginLeft: '2em', marginRight: '2em' }}>
                <a style={{ fontSize: '15px' }}>{intl.get('order_no.') + '：' + detail.order_no}</a>
                <Button style={{ float: 'right' }} onClick={() => {
                    props.history.go(-1);
                }}>{intl.get('Back')}</Button>
            </Row>

            <Card
            >
                <Descriptions title={intl.get('order_info')} column={isMobileBrowser() ? 1 : 3}>
                    {/* <Descriptions.Item label='交易流水号'>{detail.trace_no}</Descriptions.Item> */}
                    <Descriptions.Item label={intl.get('insurer_no')}>{detail.insure_no}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('product_name')}>{detail.product_name}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('insurance_premiums')}>
                        {detail.order_amount ? detail.order_amount : '--'}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={intl.get('order_status')}>{getStatics(detail.order_status)}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('insurance_effective')}>
                        {detail.time_effect ? (moment(detail.time_effect).format('YYYY-MM-DD') + " 00:00:00") : '--'}
                    </Descriptions.Item>
                    <Descriptions.Item label={intl.get('Insurance_lapse')}>
                        {detail.time_effect ? (moment(detail.time_effect).add(1, 'years').subtract(1, 'days').format('YYYY-MM-DD') + ' 23:59:59') : '--'}
                    </Descriptions.Item>
                </Descriptions>
                {/**/}
                <Descriptions title={intl.get('policyholders') + intl.get('info')} column={isMobileBrowser() ? 1 : 3}
                    style={{ paddingTop: '10px' }}>
                    <Descriptions.Item label={intl.get('name_1')}>{detail.policyholders_name}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('Gender')}> {intl.get(USE_SEX.properties[detail.policyholders_sex || 1].text)}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('Birthday')}>{moment(Number(detail.policyholders_birthday)).format('YYYY-MM-DD')}</Descriptions.Item>
                    {/* <Descriptions.Item label={intl.get('credential_type')}>
                        {intl.get(CREDENTIAL_TYPE.properties[detail.policyholders_credential_type || 4].text)}
                    </Descriptions.Item> */}
                    {/* <Descriptions.Item label={intl.get('credential')}>{detail.policyholders_credential}</Descriptions.Item> */}
                    {/* <Descriptions.Item label={intl.get('email')}>{detail.policyholders_email}</Descriptions.Item> */}
                </Descriptions>
                {/**/}
                <Descriptions title={intl.get('insurer')}
                    column={isMobileBrowser() ? 1 : 3} style={{ paddingTop: '10px' }}>
                    <Descriptions.Item label={intl.get('insurer')}>{detail.insurer_name}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('Gender')}>{intl.get(USE_SEX.properties[detail.insurer_sex || 1].text)}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('Birthday')}>{moment(detail.insurer_birthday).format('YYYY-MM-DD')}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('flightNo')}>{detail.flight_no}</Descriptions.Item>
                    {/* <Descriptions.Item label={intl.get('credential_type')}>
                        {intl.get(CREDENTIAL_TYPE?.properties[detail.insurer_credential_type || 4]?.text)}
                    </Descriptions.Item>
                    <Descriptions.Item label={intl.get('credential')}>{detail.insurer_credential}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('credential_url')}><Zmage src={detail.insurer_credential_url} width="30px" alt="" /></Descriptions.Item> */}
                </Descriptions>
            </Card>
        </Col>
    );
};

export default InsurancePoliciesDetail;
