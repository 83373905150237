import {connect} from 'react-redux';
import intl from 'react-intl-universal';
import React from 'react';
import {Button, Input, Upload, message} from 'antd';
import useTable from '../../../hooks/useTable';
import ConditionPanel from '../../..//components/ConditionPanel';
import CommonTable from '../../../components/CommonTable';
import {exportExcel} from './service';
import {useParams} from '../../../hooks/utils';

const InsuranceImportList = (props) => {
    const [searchParam, updateSearchParam] = useParams({
        insure_no: '',
        status: -1,
        company_id: !props.userInfo.is_station ? props.userInfo.company_id : '',
    });
    const {tableProps, search} = useTable(
        {
            url: `/channel`,
            // method: 'POST',
        },
        {
            pagination: true,
            pageSize: 20,
            noFetch: true,
        }
    );

    const companyData = useTable(
        {
            url: '/company',
            params: {
                name: '',
            },
        },
        {
            pagination: true,
            noFetch: true,
            rowSelection: true,
        }
    );

    React.useEffect(() => {
        let id = '';
        if (!props.userInfo.is_station) {
            id = props.userInfo.company_id;
        } else {
            id = companyData.data && companyData.data.length > 0 ? companyData.data[0].id : '';
        }
        updateSearchParam({company_id: id});
    }, [companyData.data]);

    const uploadProps = {
        accept: 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        customRequest: (option) => {
            // @ts-ignore
            const file = option.file;

            if (file && file.name.match(/\.xls|.xlsx/)) {
                importExcelFile(file);
            } else {
                message.error(intl.get('file_type_error'));
            }
        },
    };

    function importExcelFile(file) {
        // 导入开始
        exportExcel(file, (res) => {
            if (res) {
                searchList(1);
            }
        });
    }

    const columns = [
        {title: '#', dataIndex: 'index', key: 'index', render: (t, r, i) => <span>{i++}</span>},
        {title: intl.get('channel_name'), dataIndex: 'name', key: 'name'},
        {title: intl.get('policyholders'), dataIndex: 'insurer_name', key: 'insurer_name'},
        {title: intl.get('insurer_no'), dataIndex: 'insure_no', key: 'insure_no'},
        {title: intl.get('product_name'), dataIndex: 'product_name', key: 'product_name'},
        {title: intl.get('email'), dataIndex: 'email', key: 'email'},
        {title: '状态', dataIndex: 'order_status', key: 'order_status'},
    ];

    const searchList = () => {
        if (!searchParam.company_id || searchParam.company_id == '') {
            message.warning(intl.get('companyIsRequired'));
            return false;
        }
        search();
    };

    return (
        <div>
            <ConditionPanel labelWidth={90}>
                <ConditionPanel.Item label={intl.get('channel_code') + ':'}>
                    <Input style={{width: '100%'}} placeholder={intl.get('channel_code')} allowClear/>
                </ConditionPanel.Item>
                <ConditionPanel.Item label={intl.get('channel_name') + ':'}>
                    <Input style={{width: '100%'}} placeholder={intl.get('channel_name')} allowClear/>
                </ConditionPanel.Item>
                <ConditionPanel.Item label={intl.get('contact') + ':'}>
                    <Input style={{width: '100%'}} placeholder={intl.get('contact')} allowClear/>
                </ConditionPanel.Item>
                <ConditionPanel.Item enterSearch>
                    <Button type='primary' onClick={() => searchList(1)} block className='margin-right-8'>
                        {intl.get('btn_search')}
                    </Button>
                    <Upload className='margin-left-8' {...uploadProps} showUploadList={false}>
                        <Button>{intl.get('btn_import')}</Button>
                    </Upload>
                </ConditionPanel.Item>
            </ConditionPanel>

            <CommonTable columns={columns} {...tableProps} />
        </div>
    );
};
const mapStateToProps = (state) => {
    const {GlobalReducer} = state;
    return {
        userInfo: GlobalReducer.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceImportList);
