import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Select } from 'antd';
import { emit } from '../../emit';
const SelectLanguage = (props) => {
  const handleChange = (val) => {
    // 发送消息
    emit.emit('change_language', val);
  };

  return (
    <Select  value={intl.get('language') === 'EN' ? 'en-US' : 'zh-CN'} onChange={handleChange} style={{ width: '100px',backgroundColor: '#0086ff' }} {...props}>
      <Select.Option value='zh-CN'>中文显示</Select.Option>
      <Select.Option value='en-US'>English </Select.Option>
    </Select>
  );
};

export default SelectLanguage;
