import http from '../../../helper/http';
import { Api_request } from '../../global/constants';

export const createMenus = async (params, success) => {
  const result = await http.post(Api_request + '/sys_menu', params);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const updateMenus = async (params, success) => {
  const result = await http.put(Api_request + '/sys_menu/' + params.id, params);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const delMenus = async (ids, success) => {
  const result = await http.post(Api_request + '/sys_menu', ids);
  if (result && result.data.errcode === 0) {
    success();
  }
};
