import React, { useState, useEffect } from 'react';
import { Upload, Button, Icon, Spin } from 'antd';
import oss from 'ali-oss';
import http from '../../helper/http';
import Zmage from 'react-zmage';
// let currentApi = window.location.hostname === 'localhost' ? 'http://main.yikagou8.com' : window.location.origin;
import { Api_request } from '../global/constants'; //`${currentApi}/api`;

let aliyunSign = {};

const TemplateUpload = (props) => {
  const [url, setUrl] = useState(props.url);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setUrl(props.url);
  }, [props.url]);

  const uploadOriginTable = async (file) => {
    setLoading(true);
    if (aliyunSign['sign']) {
      ossUplaod(aliyunSign['sign'], file);
      return;
    }
    const result = await http.get(`${Api_request}/oss/token`);
    if (result && result.data.errcode === 0) {
      aliyunSign['sign'] = result.data.data;
      ossUplaod(result.data.data, file);
    }
  };

  const ossUplaod = (sign, file) => {
    let filename = file.name;
    var ext = filename.substring(filename.lastIndexOf('.'));
    var storeAs = sign.path + '/' + new Date().getTime() + ext;

    let client = new oss({
      region: 'oss-cn-hongkong',
      secure: true,
      accessKeyId: sign.access_key_id,
      accessKeySecret: sign.access_key_secret,
      stsToken: sign.security_token,
      bucket: sign.bucket,
    });
    //TODO:临时修改
    // client.options.endpoint.href = 'http://oss-cn-hongkong.aliyuncs.com/';
    // client.options.endpoint.protocol = 'http:';
    client
      .multipartUpload(storeAs, file)
      .then(function (result) {
        setLoading(false);
        // debugger;
        let urlItem = result.res.requestUrls[0] ? result.res.requestUrls[0].split('?') : [];
        setUrl(urlItem[0]);
        props.fileSuccess(urlItem[0]);
      })
      .catch(function (ex) {
        console.log('错误信息', ex);
        setLoading(false);
      });
  };

  const uploadProps = {
    name: 'file',
    showUploadList: false,
    customRequest: (info) => {
      uploadOriginTable(info.file);
    },
    accept: ['image/*', '.pdf'],
  };

  return (
    <React.Fragment>
      {props.isEditor ? (
        <Upload {...uploadProps}>
          <Button size='small'>
            <Icon type='upload' /> 图片上传
          </Button>
        </Upload>
      ) : (
          <Spin spinning={loading} tip='图片上传中...'>
            {console.log('url', url)}
            <Zmage
              src={url || '/image/noimages.jpg'}
              style={{ width: '40px', height: '40px', border: '1px solid #ddd', marginRight: 8 }}
              edge={20}
              backdrop='#eee'
            ></Zmage>
            <Upload {...uploadProps}>
              <Button size='small'>
                <Icon type='upload' /> 图片上传
            </Button>
            </Upload>
          </Spin>
        )}
    </React.Fragment>
  );
};

export default TemplateUpload;
