import http from '../../../helper/http';
import { Api_request } from '../../global/constants';

export const createTemplate = async (params, success) => {
  const result = await http.post(Api_request + '/rate_template', params);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const updateTemplate = async (params, success) => {
  const result = await http.put(Api_request + '/rate_template/' + params.id, params);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const delTemplate = async (ids, success) => {
  const result = await http.post(Api_request + '/rate_template/delete', [ids]);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const bindTemplateProduct = async (params, success) => {
  const result = await http.post(Api_request + `/rate_template/${params.template_id}/products/${params.product_id}`);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const bindTemplateRate = async (params, success) => {
  const result = await http.put(Api_request + `/rate_template/${params.template_id}/products/${params.product_id}`, params);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const delTemplateProduct = async (params, success) => {
  const result = await http.post(Api_request + `/rate_template/${params.template_id}/products/delete`, [params.product_id]);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const exportExcel = async (id, success) => {
  const result = await http.blobGet(`${Api_request}/rate_template/${id}/products/export `);
  if (result && result.data) {
    success(result.data);
  }
};
