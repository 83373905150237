import React, {useState, useEffect} from 'react';
import intl from 'react-intl-universal';
import './index.css';
import {Descriptions, Card, Row, Col,Button, Tabs, DatePicker, Tooltip} from 'antd';
import {isMobileBrowser} from '../../helper/until';
import {
    getPerformanceAmountInfo,
    getCommissionInfo,
    getCommissionNum,
    getCustomersInfo,
    getCustomersNum,
    getProductInfo,
    performanceInfo,
    getProductNum
} from './service';
import moment from "moment";
import {RANGES} from "../common/DatePickerRanges";
import 'echarts/lib/chart/line';
import ReactEcharts from 'echarts-for-react';
import {useParams} from "../../hooks/utils";

const {RangePicker} = DatePicker;

const {Meta} = Card;
const {TabPane} = Tabs;
const Dashboard = (props) => {
        const [allPerformanceInfo, setPerformanceInfo] = useState({
            option: null,
            yesterday_order: 0,
            lastday_order: 0,
            yesterdaymount: '0.0USD',
            lastday_amount: '0.0USD',
            month_order: 0,
            last_month_order: 0,
            month_amount: '0.0USD',
            last_month_amount: '0.0USD',
            year_amout: '0.0USD',
            year_order: 0,
        });


        const [customersNum, setCustomersNum] = useState({
            yesterday_customers: 0,
            yesterday_new_customers: 0,
        });

        const [commissionNum, setCommissionNum] = useState({
            yesterday_customers: 0,
            yesterday_new_customers: 0,
        });

        const [productNum, setProductNum] = useState({
            yesterday_customers: 0,
            yesterday_new_customers: 0,
        });

        const [searchParam, updateSearchParam] = useParams({
            start_day: moment().subtract("7", "days").format("YYYY-MM-DD").valueOf(),
            end_day: moment().format("YYYY-MM-DD").valueOf(),
        });

        const [searchCustomerParam, updateSearchCustomerParam] = useParams({
            start_day: moment().subtract("7", "days").format("YYYY-MM-DD").valueOf(),
            end_day: moment().format("YYYY-MM-DD").valueOf(),
        });
        const [searchProductParam, updateSearchProductParam] = useParams({
            start_day: moment().subtract("7", "days").format("YYYY-MM-DD").valueOf(),
            end_day: moment().format("YYYY-MM-DD").valueOf(),
        });
        const [searchCommissionParam, updateSearchCommissionParam] = useParams({
            start_day: moment().subtract("7", "days").format("YYYY-MM-DD").valueOf(),
            end_day: moment().format("YYYY-MM-DD").valueOf(),
        });

        const [option, setOptions] = useState({});

        const [customersOption, setCustomersOptions] = useState({});
        const [productOption, setProductOptions] = useState({});
        const [commissionOption, setCommissionOptions] = useState({});

        useEffect(() => {
            getPerformanceAmountInfo(searchParam, (data) => {
                setOptions(data.options);
            });
            performanceInfo((data) => {
                setPerformanceInfo(data);
            });
            getCustomersInfo(searchCustomerParam, (data) => {
                setCustomersOptions(data.options);
            });
            getCustomersNum((data) => {
                setCustomersNum(data)
            })
            getProductNum((data) => {
                setProductNum(data)
            })
            getCommissionNum((data) => {
                setCommissionNum(data);
            });
            getProductInfo(searchProductParam, (data) => {
                setProductOptions(data.options);
            });
            getCommissionInfo(searchCommissionParam, (data) => {
                setCommissionOptions(data.options);
            });
        }, [])

        useEffect(() => {
            performanceInfo((data) => {
                setPerformanceInfo(data);
            });
        }, [])

        const setOption = (item, startDay, endDay) => {
            if (item == 1) {
                getPerformanceAmountInfo({start_day: startDay, end_day: endDay}, (data) => {
                    setOptions(data.options);
                });
            } else if (item == 2) {
                getCustomersInfo({start_day: startDay, end_day: endDay}, (data) => {
                    setCustomersOptions(data.options);
                });
            } else if (item == 3) {
                getProductInfo({start_day: startDay, end_day: endDay}, (data) => {
                    setProductOptions(data.options);
                });
            } else if (item == 4) {
                getCommissionInfo({start_day: startDay, end_day: endDay}, (data) => {
                    setProductOptions(data.options);
                });
            }
        }


        return (
            <div className={'dash-div'}>

                <div style={{position: 'fixed', bottom: '20px', right: '10px', zIndex: '999'}}>
                    <Tooltip>
                        <Button className='margin-right-8' shape='ellipse' type='primary'
                                href='https://tongji.baidu.com/web/homepage/index' target="_blank">
                            {intl.get('Statistics')}
                        </Button>
                    </Tooltip>
                </div>

                <Tabs>
                    <TabPane tab={intl.get('performance_statistic')} key='1'>
                        <div className='analysis-head'>
                            <p className='a-h-h m-b-20'>
                                <span className='span-style'>{intl.get('performance_statistic')}</span>
                                <span>• {intl.get('as_yesterday')}&nbsp;&nbsp;&nbsp;&nbsp;• {intl.get('Data_Subsidiary')}&nbsp;&nbsp;&nbsp;&nbsp;•{' '}
                                    {intl.get('successful_payment')} </span>
                            </p>
                            <Row style={{display: 'flex', position: 'sticky', bottom: 0}}>
                                <Col column={isMobileBrowser() ? 1 : 8}>
                                    <Descriptions>
                                        <Descriptions.Item>
                                            <p className='span-style-yeji'>{intl.get('Increased_Yesterday')}</p>
                                            <p className={'span-num'}>{allPerformanceInfo.yesterday_order}</p>
                                            <p className={'span-num-lastDay'}>
                                                {intl.get('last_Yesterday')}:
                                                <span>{allPerformanceInfo.lastday_order}</span>
                                            </p>
                                        </Descriptions.Item>
                                        <Descriptions.Item>
                                            <p className={'span-style-yeji'}>{intl.get('Added_Premium_yesterday')}</p>
                                            <p className={'span-num'}>{allPerformanceInfo.yesterdaymount}</p>
                                            <p className={'span-num-lastDay'}>
                                                {intl.get('last_Yesterday')}:
                                                <span>{allPerformanceInfo.lastday_amount}</span>
                                            </p>
                                        </Descriptions.Item>
                                        <Descriptions.Item>
                                            <p className='span-style-yeji'>{intl.get('Policies_Increased')}
                                            </p>
                                            <p className={'span-num'}>{allPerformanceInfo.month_order}</p>
                                            <p className={'span-num-lastDay'}>
                                                {intl.get('Last_Month')}:
                                                <span className=''>{allPerformanceInfo.last_month_order}</span>
                                            </p>
                                        </Descriptions.Item>
                                        <Descriptions.Item>
                                            <p className='span-style-yeji'>{intl.get('Added_Premium')}</p>
                                            <p className={'span-num'}>{allPerformanceInfo.month_amount}</p>
                                            <p className={'span-num-lastDay'}>
                                                {intl.get('Last_Month')}:
                                                <span className=''>{allPerformanceInfo.last_month_amount}</span>
                                            </p>
                                        </Descriptions.Item>
                                        <Descriptions.Item>
                                            <p className='span-style-yeji'>{intl.get('New_premium_year')}</p>
                                            <p className={'span-num'}>{allPerformanceInfo.year_amout}</p>
                                            <p className={'span-num-lastDay'}>
                                                {intl.get("New_order")}:<span
                                                className=''>{allPerformanceInfo.year_order}</span>
                                            </p>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <div style={{marginTop: '5px', marginBottom: '5px'}}>
                                <RangePicker
                                    // defaultValue={[searchParam.start_day, searchParam.end_day]}
                                    defaultValue={[moment().subtract('week', 1).startOf('day'), moment().endOf('day')]}
                                    onChange={date => setOption(1, date[0].startOf('day').format("YYYY-MM-DD").valueOf(), date[1].endOf('day').format("YYYY-MM-DD").valueOf())}
                                    ranges={RANGES}
                                />
                            </div>
                            <Card>
                                <ReactEcharts
                                    notMerge={true}
                                    option={option}
                                    // setOption(moment().subtract("7","days").format("YYYY-MM-DD").valueOf(),moment().format("YYYY-MM-DD").valueOf())}
                                    lazyUpdate={true} theme="Imooc"
                                    style={{height: '400px'}}/>
                            </Card>

                        </div>
                    </TabPane>
                    <TabPane tab={intl.get('Customers_Statistics')} key='2'>
                        <div className='analysis-head'>
                            <p className='a-h-h m-b-20'>
                                <span className='span-style'>{intl.get('Customers_Statistics')}</span>
                                <span>• {intl.get('as_yesterday')}&nbsp;&nbsp;&nbsp;&nbsp;• {intl.get('Data_includes')}</span>
                            </p>
                            <Row style={{display: 'flex', alignItems: 'center', position: 'sticky', bottom: 0}}>
                                <Col lg={18} md={16} xs={24} sm={24}>
                                    <Descriptions column={isMobileBrowser() ? 1 : 3}>
                                        <Descriptions.Item>
                                            <p>
                                                <span className='span-style-yeji'>{intl.get('Number_Customers')}</span>
                                            </p>
                                            <p className={'span-num'}>{customersNum.yesterday_customers}</p>
                                        </Descriptions.Item>

                                        <Descriptions.Item>
                                            <p>
                                                <span className='span-style-yeji'>{intl.get("New_customers")}</span>
                                            </p>
                                            <p className={'span-num'}>{customersNum.yesterday_new_customers}</p>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <div style={{marginTop: '5px', marginBottom: '5px'}}>
                                <RangePicker
                                    // defaultValue={[searchParam.start_day, searchParam.end_day]}
                                    defaultValue={[moment().subtract('week', 1).startOf('day'), moment().endOf('day')]}
                                    onChange={date => setOption(2, date[0].startOf('day').format("YYYY-MM-DD").valueOf(), date[1].endOf('day').format("YYYY-MM-DD").valueOf())}
                                    ranges={RANGES}
                                />
                            </div>
                            <Card>
                                <ReactEcharts
                                    notMerge={true}
                                    option={customersOption}
                                    // setOption(moment().subtract("7","days").format("YYYY-MM-DD").valueOf(),moment().format("YYYY-MM-DD").valueOf())}
                                    lazyUpdate={true} theme="Imooc"
                                    style={{height: '400px'}}/>
                            </Card>
                        </div>
                    </TabPane>
                    <TabPane tab={intl.get("Product_Statistics")} key='3'>
                        <div className='analysis-head'>
                            <p className='a-h-h m-b-20'>
                                <span className='span-style'>{intl.get("Product_Statistics")}</span>
                                <span>• {intl.get('as_yesterday')}&nbsp;&nbsp;&nbsp;&nbsp;• {intl.get('Data_includes')}</span>
                            </p>
                            <Row style={{display: 'flex', alignItems: 'center', position: 'sticky', bottom: 0}}>
                                <Col lg={18} md={16} xs={24} sm={24}>
                                    <Descriptions column={isMobileBrowser() ? 1 : 2}>
                                        <Descriptions.Item>
                                            <p>
                                                <span className='span-style-yeji'>{intl.get('Products_yesterday')}</span>
                                            </p>
                                            <p className={'span-num'}>{productNum.yesterday_customers}</p>
                                        </Descriptions.Item>
                                        <Descriptions.Item>
                                            <p>
                                                <span className='span-style-yeji'>{intl.get("Product_month")}</span>
                                            </p>
                                            <p className={'span-num'}>{productNum.yesterday_new_customers}</p>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <div style={{marginTop: '5px', marginBottom: '5px'}}>
                                <RangePicker
                                    // defaultValue={[searchParam.start_day, searchParam.end_day]}
                                    defaultValue={[moment().subtract('week', 1).startOf('day'), moment().endOf('day')]}
                                    onChange={date => setOption(3, date[0].startOf('day').format("YYYY-MM-DD").valueOf(), date[1].endOf('day').format("YYYY-MM-DD").valueOf())}
                                    ranges={RANGES}
                                />
                            </div>
                            <Card>
                                <ReactEcharts
                                    notMerge={true}
                                    option={productOption}
                                    // setOption(moment().subtract("7","days").format("YYYY-MM-DD").valueOf(),moment().format("YYYY-MM-DD").valueOf())}
                                    lazyUpdate={true} theme="Imooc"
                                    style={{height: '400px'}}/>
                            </Card>
                        </div>
                    </TabPane>

                    <TabPane tab={intl.get('Commission_statistics')} key='4'>
                        <div className='analysis-head'>
                            <p className='a-h-h m-b-20'>
                                <span className='span-style'>{intl.get('Commission_statistics')}</span>
                                <span>• {intl.get('as_yesterday')}&nbsp;&nbsp;&nbsp;&nbsp;• {intl.get('Data_includes')}</span>
                            </p>
                            <Row style={{display: 'flex', alignItems: 'center', position: 'sticky', bottom: 0}}>
                                <Col lg={18} md={16} xs={24} sm={24}>
                                    <Descriptions column={isMobileBrowser() ? 1 : 2}>
                                        <Descriptions.Item>
                                            <p>
                                                <span className='span-style-yeji'>{intl.get('Commission_Yesterday')}</span>
                                            </p>
                                            <p className={'span-num'}>{commissionNum.yesterday_customers}</p>
                                        </Descriptions.Item>
                                        <Descriptions.Item>
                                            <p>
                                                <span className='span-style-yeji'>{intl.get('Commission_Month')}</span>
                                            </p>
                                            <p className={'span-num'}>{commissionNum.yesterday_new_customers}</p>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <div style={{marginTop: '5px', marginBottom: '5px'}}>
                                <RangePicker
                                    // defaultValue={[searchParam.start_day, searchParam.end_day]}
                                    defaultValue={[moment().subtract('week', 1).startOf('day'), moment().endOf('day')]}
                                    onChange={date => setOption(4, date[0].startOf('day').format("YYYY-MM-DD").valueOf(), date[1].endOf('day').format("YYYY-MM-DD").valueOf())}
                                    ranges={RANGES}
                                />
                            </div>
                            <Card>
                                <ReactEcharts
                                    notMerge={true}
                                    option={commissionOption}
                                    // setOption(moment().subtract("7","days").format("YYYY-MM-DD").valueOf(),moment().format("YYYY-MM-DD").valueOf())}
                                    lazyUpdate={true} theme="Imooc"
                                    style={{height: '400px'}}/>
                            </Card>
                        </div>
                    </TabPane>

                </Tabs>
            </div>
        );
    }
;

export default Dashboard;
