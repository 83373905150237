import http from '../../../helper/http';
import { Api_request } from '../../global/constants';

export const createProduct = async (params, success) => {
  const result = await http.post(Api_request + '/product', params);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const updateProduct = async (params, success) => {
  const result = await http.put(Api_request + '/product/' + params.id, params);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const delProduct = async (ids, success) => {
  const result = await http.post(Api_request + '/product/delete', ids);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const copyProduct = async (id, success) => {
  const result = await http.get(Api_request + `/product/copy/${id}`);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const getProductDetail = async (id, success) => {
  const result = await http.get(Api_request + `/product/${id}`);
  if (result && result.data.errcode === 0) {
    success(result.data.data);
  }
};

export const getProductAllDetail = async (id, success) => {
  const result = await http.get(Api_request + `/product/admin/${id}`);
  if (result && result.data.errcode === 0) {
    success(result.data.data);
  }
};

export const getLinkWeb = async (id, success) => {
  const result = await http.get(Api_request + `/product/${id}/link_web`);
  if (result && result.data.errcode === 0) {
    success(result.data.data);
  }
};
export const getLinkMobileWeb = async (id, success) => {
  const result = await http.get(Api_request + `/product/${id}/link_mobile_web`);
  if (result && result.data.errcode === 0) {
    success(result.data.data);
  }
};
