import moment from "moment";
/**
 * AntDesign时间区域控件
 * 预设常用的日期范围
 */
let formatStart = "YYYY-MM-DD 00:00";
let formatEnd = "YYYY-MM-DD 23:59";
export const RANGES = {
    "今日": [moment().startOf('day'), moment().endOf('day')],
    "昨天": [moment(moment().subtract(1, 'day').format(formatStart)), moment().endOf('day')],
    "近7天": [moment(moment().subtract(1, 'week').format(formatStart)), moment(moment().format(formatEnd))],
    "近30天": [moment(moment().subtract(29, 'day').format(formatStart)), moment(moment().format(formatEnd))],
    "近3月": [moment(moment().subtract(3, 'month').format(formatStart)), moment(moment().format(formatEnd))],
    // "本周": [moment().startOf('week'), moment().endOf('week')],
    // "上周": [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
    // "本月": [moment().startOf('month'), moment().endOf('month')],
    // "上月": [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
};