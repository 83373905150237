import React, { useEffect, Fragment } from 'react';
import { Row, Col, Divider } from 'antd';
import './index.css';

const defaultQuery = {
  xs: 24,
  md: 12,
  xl: 8,
  xxl: 6,
};

const ConditionPanel = (props) => {
  const { children, labelWidth, query, span, labelStyle, divided, ...restProps } = props;

  const panelItems = React.Children.toArray(children); // 子元素

  const maxTextLength = Math.max.apply(
    null,
    panelItems.map((e) => (e.props.label ? e.props.label.length : 0))
  );

  const _labelWidth = labelWidth || 14 * maxTextLength + 'px';

  const _query = {
    ...defaultQuery,
    ...query,
  };
  const getOffset = (querySet = 0, i = 0) => {
    const count = panelItems.filter((e) => e.props.type !== 'end').length || 0; // 条件项个数
    const colCount = 24 / querySet; //每行个数
    const endsCount = panelItems.filter((e) => e.props.type === 'end').length || 0; // ends项个数
    let mod = 0;

    if (count < colCount) {
      mod = count;
    } else {
      mod = count % colCount;
    }
    const minus = colCount - endsCount - mod;
    if (minus <= 0) {
      return 0;
    } else {
      return minus * querySet;
    }
  };

  const queries = [];
  let j = 0;
  panelItems.forEach((e) => {
    if (e.props.type && e.props.type === 'end' && j === 0) {
      queries.push({
        xs: { offset: getOffset(_query.xs, j), span: _query.xs },
        sm: { offset: getOffset(_query.sm, j), span: _query.sm },
        md: { offset: getOffset(_query.md, j), span: _query.md },
        xl: { offset: getOffset(_query.xl, j), span: _query.xl },
        xxl: { offset: getOffset(_query.xxl, j), span: _query.xxl },
      });
      j++;
    } else {
      queries.push(_query);
    }
  });

  return (
    <Fragment>
      <Row gutter={16} type='flex' {...restProps}>
        {panelItems.map((Child, i) =>
          React.cloneElement(Child, { labelWidth: _labelWidth, queryParent: queries[i], spanParent: span, labelStyleParent: labelStyle })
        )}
      </Row>
      {divided && <Divider style={{ margin: '6px 0 24px 0' }} />}
    </Fragment>
  );
};

const Item = (props) => {
  useEffect(() => {
    // 回车键只对主按钮生效
    if (props.enterSearch) {
      const itemChildren = React.Children.toArray(props.children); // 子元素
      itemChildren.forEach((btn) => {
        if (btn.props.type === 'primary' && btn.props.htmlType === 'button') {
          const needEnterKeyListener = btn.props.onClick;
          const onkeyup = (e) => {
            if (e.keyCode === 13) {
              btn.props.onClick();
            }
          };
          if (needEnterKeyListener) {
            document.addEventListener('keyup', onkeyup);
          }
          return () => {
            if (needEnterKeyListener) {
              document.removeEventListener('keyup', onkeyup);
            }
          };
        }
      });
    }
  }, []);

  const span = props.span || props.spanParent;

  const query = span
    ? {}
    : {
        ...defaultQuery,
        ...props.queryParent,
        ...props.query,
      };

  if (props.type === 'end') {
    return (
      <Col span={span} {...query} className='search-wrapper' style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {props.children}
      </Col>
    );
  }

  // 子项样式
  const labelStyle = {
    minWidth: props.labelWidth,
    ...props.labelStyleParent,
    ...props.labelStyle,
  };

  return (
    <Col span={span} {...query} className='search-wrapper' style={props.style}>
      {props.label && (
        <span className='search-text' style={labelStyle}>
          {props.label}
        </span>
      )}
      <div className='item-component-wrap'>{props.children}</div>
    </Col>
  );
};

ConditionPanel.Item = Item;

export default ConditionPanel;

export { Item };
