import moment from 'moment';

export function getUrlParam(name) {
  //取地址带的参数
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return decodeURIComponent(r[2]);
  return null;
}

/**
 * 根据输入的时间返回距离当前的时间的字符串
 * @param {*} toDateStr 输入的时间
 */

export function dateGoOn(toDateStr) {
  var toDate = new Date(toDateStr);
  //toDate = new Date(toDate.getTime() - 8 * 60 * 60 * 1000);
  var nowDate = new Date();
  var diffTime = nowDate - toDate;
  var r = diffTime > 0 ? '前' : '后';
  //转成正整数
  diffTime = diffTime > 0 ? diffTime : diffTime * -1;

  var der = Math.floor(diffTime / (60 * 1000));

  var t = '';
  if (der < 2) return '刚刚';
  else if (der <= 0) {
  }
  if (der < 60) return der + '分钟' + r;

  der = Math.floor(diffTime / (3600 * 1000));
  if (der < 12) return der + '小时' + r;

  der = Math.floor((nowDate.setHours(0, 0, 0, 0) - toDate.setHours(0, 0, 0, 0)) / (24 * 3600 * 1000));
  if (der == -1) {
    return '明天';
  }
  if (der == -2) {
    return '后天';
  }
  if (der == 0) return '今天';
  if (der == 1) return '昨天';
  if (der == 2) return '前天';

  //转成正整数
  der = der > 0 ? der : der * -1;
  if (der < 8) return der + '天' + r;

  der = Math.floor(der / 7);
  if (der < 4) return der + '周' + r;

  var nowFullMonth = nowDate.getYear() * 12 + nowDate.getMonth();
  var thatFullMonth = toDate.getYear() * 12 + toDate.getMonth();

  der = nowFullMonth - thatFullMonth;
  //转成正整数
  der = der > 0 ? der : der * -1;
  if (der < 12) return der + '个月' + r;
  else return Math.floor(der / 12) + '年' + r;
  return '';
}

/**
 *
 * @param {*} url 例如：
 */
export function urlParamToArray(url) {
  let params = {};
  let urls = {};
  if (url.substring(0, 1) === '?') {
    urls = url.split('?');
  } else {
    url = '?' + url;
    urls = url.split('?');
  }
  let arr = urls[1].split('&');
  for (let i = 0, l = arr.length; i < l; i++) {
    let a = arr[i].split('=');
    params[a[0]] = a[1];
  }
  // console.log('5_结果:', params)
  return params;
}

/**
 *
 * @param {*} data  需要转化的对象
 * @param {*} isPrefix 输出的时候是否需要带上’？‘
 */
export function arrayToUrlParam(data, isPrefix = false) {
  let prefix = isPrefix ? '?' : '';
  let _result = [];
  for (let key in data) {
    let value = data[key];
    // 去掉为空的参数
    if (['', undefined, null].includes(value)) {
      continue;
    }
    if (value.constructor === Array) {
      value.forEach((_value) => {
        _result.push(encodeURIComponent(key) + '[]=' + encodeURIComponent(_value));
      });
    } else {
      _result.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
    }
  }
  return _result.length ? decodeURIComponent(prefix + _result.join('&')) : '';
}

/**
 * 操作cookie，包含写入，读取，删除
 */
export function setCookie(name, value, param) {
  //set
  let saveedValue = '';
  if (typeof value == 'object') {
    saveedValue = JSON.stringify(value);
  } else {
    saveedValue = value;
  }
  let cookie = [name, '=', saveedValue, ';path=/;'].join('');

  document.cookie = cookie;
}
export function getCookie(name) {
  let result = document.cookie.match(new RegExp(name + '=([^;]+)'));
  result = result != null ? result[1] : '';
  return result;
}
export function removeCookie(name, param) {
  document.cookie = [name, '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/; domain=', param.domain || ''].join('');
}
export function clearCookie(param) {
  var date = new Date();
  date.setTime(date.getTime() - 10000);
  var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
  if (keys) {
    for (var i = keys.length; i--; ) document.cookie = keys[i] + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT' + '; path=/;domain=' + param.domain || '';
  }
}
export function isMobileBrowser() {
  // device detection
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      navigator.userAgent.substr(0, 4)
    )
  ) {
    return true;
  }
  return false;
}

export function Utf8ArrayToStr(array) {
  var out, i, len, c;
  var char2, char3;

  out = '';
  len = array.length;
  i = 0;
  while (i < len) {
    c = array[i++];
    switch (c >> 4) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        // 0xxxxxxx
        out += String.fromCharCode(c);
        break;
      case 12:
      case 13:
        // 110x xxxx   10xx xxxx
        char2 = array[i++];
        out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
        break;
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode(((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0));
        break;
      default:
        break;
    }
  }

  return out;
}

export function downLoad(data, mainTitle, fileType, emimeType, customize) {
  //下载文件
  let blob = new Blob([data], { type: emimeType });
  var time = moment().format('YYYYMMDDHHmmss');
  var fileName = mainTitle + time + fileType;
  var a = document.createElement('a');
  document.body.appendChild(a);
  a.download = customize ? mainTitle + fileType : fileName;
  a.href = URL.createObjectURL(blob);
  a.click();
}

export default {
  getUrlParam,
  dateGoOn,
  urlParamToArray, //url参数转成对象
  arrayToUrlParam, //对象转成url参数
  setCookie,
  getCookie,
  removeCookie,
  clearCookie,
  isMobileBrowser,
  Utf8ArrayToStr,
  downLoad,
};
