import React, { useState, useEffect } from 'react';
import { Upload, message, Icon } from 'antd';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/table.css';
import Table from 'braft-extensions/dist/table';
import { ContentUtils } from 'braft-utils';
import { uploadToAliyun } from './uploadImg';
import 'braft-editor/dist/index.css';
import { imageDomain } from '../global/constants';
import intl from "react-intl-universal";
const options = {
  defaultColumns: 3, // 默认列数
  defaultRows: 3, // 默认行数
  withDropdown: true, // 插入表格前是否弹出下拉菜单
  columnResizable: false, // 是否允许拖动调整列宽，默认false
  exportAttrString: '', // 指定输出HTML时附加到table标签上的属性字符串
  includeEditors: ['editor-id-1'], // 指定该模块对哪些BraftEditor生效，不传此属性则对所有BraftEditor有效
  excludeEditors: ['editor-id-2'], // 指定该模块对哪些BraftEditor无效
};

BraftEditor.use(Table(options));

const TemplateEditor = (props) => {
  const [editorValue, setEditorValue] = useState(BraftEditor.createEditorState(props.value));
  // 富文本
  const handleBraftEditorChange = (editorValue) => {
    setEditorValue(editorValue);
    props.changeEditor(editorValue);
  };

  useEffect(() => {
    setEditorValue(BraftEditor.createEditorState(props.value));
  }, [props.value]);

  const controls = [
    'undo',
    'redo',
    'separator',
    'font-family',
    'font-size',
    'line-height',
    'letter-spacing',
    'separator',
    'text-color',
    'bold',
    'italic',
    'underline',
    'strike-through',
    'separator',
    'superscript',
    'subscript',
    'remove-styles',
    'emoji',
    'separator',
    'text-indent',
    'text-align',
    'separator',
    'headings',
    'list-ul',
    'list-ol',
    'blockquote',
    'code',
    'separator',
    'link',
    'separator',
    'hr',
    'separator',
    // 'media',
    'separator',
    'clear',
    'table',
  ];

  const upLoadImage = (e) => {
    // 自定义上传图片地址
    uploadToAliyun(100, e.file, (err, result) => {
      // debugger;
      if (err) {
        message.error('上传失败');
      } else {
        message.success('上传成功');
        setEditorValue(
          ContentUtils.insertMedias(editorValue, [
            {
              type: 'IMAGE',
              url: imageDomain + result.relativePath,
            },
          ])
        );
      }
    });
  };

  const extendControls = [
    {
      key: 'antd-uploader',
      type: 'component',
      component: (
        <Upload customRequest={upLoadImage} showUploadList={false}>
          <div style={{ lineHeight: '36px', margin: 5, cursor: 'pointer' }}>
            <Icon type='upload'></Icon> 上传图片
          </div>
        </Upload>
      ),
    },
  ]; //自定义工具栏

  return (
    <BraftEditor
      controls={controls}
      extendControls={extendControls}
      id='editor-id-1'
      style={{ border: '1px dashed #bfbfbf', borderRadius: '8px', padding: '8px' }}
      value={editorValue}
      onChange={(editorValue) => handleBraftEditorChange(editorValue)}
      placeholder={intl.get('placeholder')}
    />
  );
};

export default TemplateEditor;
