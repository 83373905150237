import React, {useState, useEffect} from 'react';
import intl from 'react-intl-universal';
import {connect} from 'react-redux';
import {Button, Input, Row, Col, Modal, Select, message, TreeSelect, Card, Tooltip, Icon} from 'antd';
import useTable from '../../../hooks/useTable';
import ConditionPanel from '../../..//components/ConditionPanel';
import TemplateForm from '../../common/TemplateForm';
import {useParams} from '../../../hooks/utils';
import moment from 'moment';
import QRCode from 'qrcode.react';
import {isMobileBrowser} from '../../../helper/until';
import {delProduct, getLinkWeb, getLinkMobileWeb, copyProduct} from './service';
import {CLASSIFY, USE_STATUS} from '../../global/constants';
import {getAllCompany} from '../../service';
import {Link} from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import './index.css';

const {Meta} = Card;
const ProductConfigList = (props) => {
    const [allCompany, setAllCompany] = useState([]);
    const [visible, setVisible] = useState(false);
    const [type, setType] = useState('add');
    const [linkWebUrl, setLinkWebUrl] = useState('');
    const [linkMobileWebUrl, setLinkMobileWebUrl] = useState('');
    const [searchParam, updateSearchParam] = useParams({
        product_name: '',
        product_code: '',
        classify: -1,
        classify_sub: -1,
        use_status: -1,
        insure_company_id: '',
    });
    const [param, updateParam] = useParams({
        product_name: '',
        product_code: '',
        classify: '',
        classify_sub: '',
        insure_company_id: '',
        use_status: true,
        link_url_web: '',
        link_url_mobile_web: '',
    });
    const {tableProps, search} = useTable(
        {
            url: '/product',
            params: searchParam,
        },
        {
            pagination: true,
            noFetch: true,
            rowSelection: true,
        }
    );

    const tablePropsLink = useTable(
        {
            url: '/product/list/link_web',
        },
        {
            pagination: true,
            noFetch: true,
            rowSelection: true,
        }
    );

    useEffect(() => {
        getAllCompany((data) => {
            setAllCompany(data);
        });
    }, []);

    const columns = [
        {title: intl.get('product_code'), dataIndex: 'product_code', key: 'product_code'},
        {title: intl.get('product_name'), dataIndex: 'product_name', key: 'product_name'},
        {
            title: intl.get('classify'),
            dataIndex: 'classify',
            key: 'classify',
            render: (t, r) =>
                <span>{CLASSIFY.properties[t * 1] ? intl.get(CLASSIFY.properties[t * 1].text) : '--'}</span>,
        },
        {
            title: intl.get('classify_sub'),
            dataIndex: 'classify_sub',
            key: 'classify_sub',
            render: (t, r) => {
                let CLASSIFY_SUB = CLASSIFY.properties[r.classify * 1].sub;
                let item = CLASSIFY_SUB ? CLASSIFY_SUB.find((m) => m.key === t * 1) : {};
                return <span>{item ? intl.get(item.text) : '--'}</span>;
            },
        },
        {
            title: intl.get('company'),
            dataIndex: 'insure_company_id',
            key: 'insure_company_id',
            render: (t) => {
                const item = allCompany.find((m) => m.id === t);
                return <span>{item ? item.company_name : '--'}</span>;
            },
        },
        {
            title: intl.get('status'),
            dataIndex: 'use_status',
            key: 'use_status',
            render: (t, r) =>
                <span>{USE_STATUS.properties[t ? 1 : 0] ? intl.get(USE_STATUS.properties[t ? 1 : 0].text) : '--'}</span>,
        },
        {
            title: intl.get('time_create'),
            dataIndex: 'time_create',
            key: 'time_create',
            render: (t) => <span>{t ? moment(t).format('YYYY-MM-DD HH:mm') : '--'}</span>,
        },
    ];

    const toggleModal = (type, item) => {
        setLinkWebUrl('');
        setLinkMobileWebUrl('');
        if (type === 'link' && item) {
            updateParam(item);
            getLinkWeb(item.id, (data) => {
                setLinkWebUrl(data);
            });
            getLinkMobileWeb(item.id, (data) => {
                setLinkMobileWebUrl(data);
            });
        }
        setVisible(!visible);
        setType(type);
    };

    const delList = (id) => {
        let ids = [id];
        delProduct(ids, () => {
            message.success(intl.get('deleteSuccess'));
            search(1);
        });
    };

    const copyList = (id) => {
        copyProduct(id, () => {
            message.success(intl.get('copy_product'));
            search(1);
        });
    };

    return (
        <div>
            <ConditionPanel labelWidth={90}>
                <ConditionPanel.Item label={intl.get('product_code') + ':'}>
                    <Input
                        onChange={(e) => updateSearchParam({product_code: e.target.value})}
                        style={{width: '100%'}}
                        placeholder={intl.get('placeholder')}
                        allowClear
                    />
                </ConditionPanel.Item>
                <ConditionPanel.Item label={intl.get('classify') + ':'}>
                    <Select style={{width: '100%'}}
                            onChange={(e) => updateSearchParam({classify: e, classify_sub: ''})}>
                        {Object.values(CLASSIFY.properties).map((e) => (
                            <Select.Option key={e.key} value={e.key}>
                                {intl.get(e.text)}
                            </Select.Option>
                        ))}
                    </Select>
                </ConditionPanel.Item>
                <ConditionPanel.Item label={intl.get('classify_sub') + ':'}>
                    <Select style={{width: '100%'}} onChange={(e) => updateSearchParam({classify_sub: e})}>
                        {(CLASSIFY.properties[searchParam.classify] ? CLASSIFY.properties[searchParam.classify].sub : []).map((e) => (
                            <Select.Option key={e.key} value={e.key}>
                                {intl.get(e.text)}
                            </Select.Option>
                        ))}
                    </Select>
                </ConditionPanel.Item>
                <ConditionPanel.Item label={intl.get('name') + ':'}>
                    <Input
                        onChange={(e) => updateSearchParam({product_name: e.target.value})}
                        style={{width: '100%'}}
                        placeholder={intl.get('placeholder')}
                        allowClear
                    />
                </ConditionPanel.Item>
                <ConditionPanel.Item label={intl.get('company') + ':'}>
                    <TreeSelect
                        style={{width: '100%'}}
                        value={searchParam.insure_company_id}
                        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                        treeData={allCompany}
                        placeholder='Please select'
                        treeDefaultExpandAll
                        onChange={(e) => updateSearchParam({insure_company_id: e})}
                    />
                </ConditionPanel.Item>
                <ConditionPanel.Item label={intl.get('status') + ':'}>
                    <Select value={searchParam.use_status} onChange={(e) => updateSearchParam({use_status: e})}
                            style={{width: '100%'}}>
                        <Select.Option key={-1} value={-1}>
                            {intl.get('All')}
                        </Select.Option>
                        {Object.values(USE_STATUS.properties).map((e) => (
                            <Select.Option key={e.key} value={e.key}>
                                {intl.get(e.text)}
                            </Select.Option>
                        ))}
                    </Select>
                </ConditionPanel.Item>
                <ConditionPanel.Item enterSearch type='end'>
                    <Button type='primary' block className='margin-right-8' onClick={() => search(1)}>
                        {intl.get('btn_search')}
                    </Button>
                    {props.userInfo.is_station && (
                        <Button type='primary' block className='margin-right-8'>
                            <Link to={'/page/product_config_create'}> {intl.get('btn_add')}</Link>
                        </Button>
                    )}
                    {/*{props.userInfo.is_station && (*/}
                        <Tooltip title={
                            <QRCode
                                value={tablePropsLink.data}  //value参数为生成二维码的链接
                                size={100} //二维码的宽高尺寸
                                fgColor="#000000"  //二维码的颜色
                            />
                        }>
                            <Button type='primary' block onClick={() => toggleModal('linkList')}>
                                {intl.get('link1')}
                            </Button></Tooltip>
                    {/*)}*/}
                </ConditionPanel.Item>
            </ConditionPanel>

            <Row gutter={16}>
                {tableProps.dataSource &&
                tableProps.dataSource.map((e) => (
                    <Col span={6}>
                        <div className={'shadow_div'}>
                            <Card
                                style={{width: '100%'}}
                                cover={
                                    <div style={{height: '150px', overflow: 'hidden'}}>
                                        <img style={{maxHeight: '150px', width: '100%'}} src={e.image_samll_url}/>
                                    </div>
                                }
                                actions={
                                    props.userInfo.is_station
                                        ? [
                                            <Link to={'/page/product_config_edit?id=' + e.id}>
                                                <Icon type='edit' key='edit'/>
                                            </Link>,
                                            <Icon type='delete' key='delete' onClick={() => delList(e.id)}/>,
                                            <Tooltip title={intl.get('copy_product')}>
                                                <Icon type='copy' key='copy' onClick={() => copyList(e.id)}/>
                                            </Tooltip>,
                                            <Icon type='link' key='link' onClick={() => toggleModal('link', e)}/>,
                                        ] : [<Icon type='link' key='link' onClick={() => toggleModal('link', e)}/>]
                                }
                            >
                                <Meta
                                    title={
                                        <div>
                                            <div style={{marginBottom: '1px'}}>
                                                <p style={{marginBottom: '1px'}}>{e.product_name}</p>
                                            </div>
                                        </div>
                                    }
                                    description={
                                        <Tooltip title={e.introduction}>
                                            <span>{e.introduction ? (e.introduction.length > 10 ? e.introduction.substring(0, 7) + '...' : e.introduction) : '--'}</span>
                                        </Tooltip>
                                    }
                                />
                            </Card>
                        </div>
                    </Col>
                ))}
            </Row>

            {/* 操作 */}
            <Modal
                width={isMobileBrowser() ? '100%' : '50%'}
                title={intl.get('link')}
                visible={visible && type !== 'linkList'}
                destroyOnClose={true}
                maskClosable={false}
                footer={null}
                onCancel={toggleModal}
            >
                <TemplateForm title={intl.get('product_name')} span={[8, 12, 0]}
                              condition={<Input value={param.product_code} disabled/>}/>
                <TemplateForm title={intl.get('product_code')} span={[8, 12, 0]}
                              condition={<Input value={param.product_name} disabled/>}/>
                <TemplateForm
                    title={intl.get('pcLink')}
                    span={[8, 10, 2]}
                    condition={<Input value={linkWebUrl} disabled/>}
                    des={
                        <Tooltip title={
                            /* eslint-disable */
                            <QRCode
                                value={linkWebUrl}  //value参数为生成二维码的链接
                                size={100} //二维码的宽高尺寸
                                fgColor="#000000"  //二维码的颜色
                            />
                            /* eslint-disable */
                        }>
                            <CopyToClipboard
                                text={linkWebUrl}
                                onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                            >
                                <Button size='small' type='primary'>
                                    {intl.get('copyLink')}
                                </Button>
                            </CopyToClipboard>
                        </Tooltip>
                    }
                />
                <TemplateForm
                    title={intl.get('mobileLink')}
                    span={[8, 10, 2]}
                    condition={<Input value={linkMobileWebUrl} disabled/>}
                    des={
                        <Tooltip title={
                            /* eslint-disable */
                            <QRCode
                                value={linkMobileWebUrl}  //value参数为生成二维码的链接
                                size={100} //二维码的宽高尺寸
                                fgColor="#000000"  //二维码的颜色
                            />
                            /* eslint-disable */
                        }>
                            <CopyToClipboard
                                text={linkMobileWebUrl}
                                onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                            >
                                <Button size='small' type='primary'>
                                    {intl.get('copyLink')}
                                </Button>
                            </CopyToClipboard>
                        </Tooltip>
                    }
                />
            </Modal>
            <Modal
                width={isMobileBrowser() ? '100%' : '50%'}
                title={intl.get('link1')}
                visible={visible && type === 'linkList'}
                destroyOnClose={true}
                maskClosable={false}
                footer={null}
                onCancel={toggleModal}
            >
                {console.log(tablePropsLink)}
                <TemplateForm
                    title={intl.get('pcLink')}
                    span={[8, 10, 2]}
                    condition={<Input value={tablePropsLink.data} disabled/>}
                    des={
                        <Tooltip title={
                            /* eslint-disable */
                            <QRCode
                                value={tablePropsLink.data}  //value参数为生成二维码的链接
                                size={100} //二维码的宽高尺寸
                                fgColor="#000000"  //二维码的颜色
                            />
                            /* eslint-disable */
                        }>
                            <CopyToClipboard
                                text={tablePropsLink.data}
                                onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                            >
                                <Button size='small' type='primary'>
                                    {intl.get('copyLink')}
                                </Button>
                            </CopyToClipboard>
                        </Tooltip>
                    }
                />
            </Modal>
        </div>
    );
};
const mapStateToProps = (state) => {
    const {GlobalReducer} = state;
    return {
        userInfo: GlobalReducer.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductConfigList);
