import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { Button, Input, Row, Col, Modal, Select, message, Spin } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import useTable from '../../../hooks/useTable';
import ConditionPanel from '../../..//components/ConditionPanel';
import CommonTable from '../../../components/CommonTable';
import TemplateForm from '../../common/TemplateForm';
import { useParams } from '../../../hooks/utils';
import { USE_STATUS } from '../../global/constants';
import { isMobileBrowser } from '../../../helper/until';
import { createUser, updateUser, delUser } from './service';
import CryptoJS from 'crypto-js';

const UserChannelList = (props) => {
  const [actionLoading, setActionLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState('add');
  const [searchParam, updateSearchParam] = useParams({
    name: '',
    nickName: '',
    channel_id: '',
  });

  const getDAesString =(encrypted) => {//解密
    let key = CryptoJS.enc.Utf8.parse("abcdef0123456789");
    let iv = CryptoJS.enc.Utf8.parse("abcdef0123456789");
    let decrypted =CryptoJS.AES.decrypt(encrypted,key,
        {
            iv:iv,
            mode:CryptoJS.mode.CBC,
            padding:CryptoJS.pad.Pkcs7
        });
    return decrypted.toString(CryptoJS.enc.Utf8);     
}

  const encodeAesString = (data) => {
    let key = CryptoJS.enc.Utf8.parse("abcdef0123456789");
    let iv = CryptoJS.enc.Utf8.parse("abcdef0123456789");
    let encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    //返回的是base64格式的密文 
    // debugger
    return encrypted.toString();
}


  const [param, updateParam] = useParams({
    name: '',
    nick_name: '',
    ref_id: props.userInfo.is_channel_user ? props.userInfo.channel_id : '',
    use_status: 1,
    pass_word: '',
    email: '',
    phone: '',
  });
  const { tableProps, search, selectedKeys, data, loading } = useTable(
    {
      url: '/channel_user',
      params: searchParam,
    },
    {
      pagination: true,
      pageSize: 20,
      noFetch: true,
      rowSelection: true,
      defaultLoading: false,
    }
  );

  const channelData = useTable(
    {
      url: '/channel',
      params: {
        code: '',
        name: '',
      },
    },
    {
      pagination: true,
      noFetch: true,
      rowSelection: true,
    }
  );

  React.useEffect(() => {
    let id = '';
    if (props.userInfo.is_channel_user) {
      id = props.userInfo.channel_id;
    } else {
      id = channelData.data && channelData.data.length > 0 ? channelData.data[0].id : '';
    }
    updateSearchParam({ channel_id: id });
  }, [channelData.data]);

  useEffect(() => {
    if (searchParam.channel_id) {
      search(1);
    }
  }, [searchParam.channel_id]);

  const columns = [
    { title: intl.get('name'), dataIndex: 'name', key: 'name' },
    { title: intl.get('nickName'), dataIndex: 'nick_name', key: 'nick_name' },
    {
      title: intl.get('channel'),
      dataIndex: 'channel_name',
      key: 'channel_name',
      // render: (t) => {
      //   const item = roleList.find((m) => m.id === t);
      //   return <span>{item ? item.name : '--'}</span>;
      // },
    },
    {
      title: intl.get('status'),
      dataIndex: 'use_status',
      key: 'use_status',
      render: (t, r) => <span>{USE_STATUS.properties[t ? 1 : 0] ? intl.get(USE_STATUS.properties[t ? 1 : 0].text) : '--'}</span>,
    },
    {
      title: intl.get('time_create'),
      dataIndex: 'time_create',
      key: 'time_create',
      render: (t) => <span>{t ? moment(t).format('YYYY-MM-DD HH:mm') : '--'}</span>,
    },
  ];

  const toggleModal = (type) => {
    updateParam({
      name: '',
      nick_name: '',
      ref_id: searchParam.channel_id,
      use_status: 1,
      pass_word: '',
      email: '',
      phone: '',
    });
    if (type === 'edit' && selectedKeys.length != 1) {
      message.warning(intl.get('onlyOneByEdit'));
      return false;
    }
    if (type === 'edit') {
      let item = data.find((m) => m.id === selectedKeys[0]);
      updateParam(item);
    }
    setVisible(!visible);
    setType(type);
  };

  const saveItem = () => {
    if (!param.name || param.name == '') {
      message.warning(intl.get('nameIsRequired'));
      return false;
    }
    if (!param.nick_name || param.nick_name == '') {
      message.warning(intl.get('nickNameIsRequired'));
      return false;
    }
    if (!param.pass_word || param.pass_word == '') {
      message.warning(intl.get('passwordIsRequired'));
      return false;
    }
    if (param.pass_word.length < 6) {
      message.warning(intl.get('passwordCheck'));
      return false;
    }
    if (!param.email || param.email == '') {
      message.warning(intl.get('emailIsRequired'));
      return false;
    }
    if (!param.phone || param.phone == '') {
      message.warning(intl.get('phoneIsRequired'));
      return false;
    }
    if (!param.ref_id || param.ref_id == '') {
      message.warning(intl.get('channelIsRequired'));
      return false;
    }
    setActionLoading(true);
    if (type === 'add') {
      createUser(param, () => {
        message.success(intl.get('createSuccess'));
        search(1);
        toggleModal();
        setActionLoading(false);
      });
    } else {
      param.id = selectedKeys[0];
      updateUser(param, () => {
        message.success(intl.get('editSuccess'));
        search(1);
        toggleModal();
        setActionLoading(false);
      });
    }
  };

  const delList = () => {
    if (!selectedKeys || selectedKeys.length == 0) {
      message.warning(intl.get('empty'));
      return false;
    }
    setActionLoading(true);
    delUser(selectedKeys, () => {
      message.success(intl.get('deleteSuccess'));
      search(1);
      setActionLoading(false);
    });
    setTimeout(() => {
      setActionLoading(false);
    }, 5000);
  };

  const searchList = () => {
    if (!searchParam.channel_id || searchParam.channel_id == '') {
      message.warning(intl.get('empty'));
      return false;
    }
    search(1);
  };
  return (
    <Spin spinning={actionLoading}>
      <ConditionPanel labelWidth={90}>
        <ConditionPanel.Item label={intl.get('name') + ':'}>
          <Input style={{ width: '100%' }} onChange={(e) => updateSearchParam({ name: e.target.value })} placeholder={intl.get('placeholder')} allowClear />
        </ConditionPanel.Item>
        <ConditionPanel.Item label={intl.get('nickName') + ':'}>
          <Input style={{ width: '100%' }} onChange={(e) => updateSearchParam({ nickName: e.target.value })} placeholder={intl.get('placeholder')} allowClear />
        </ConditionPanel.Item>
        <ConditionPanel.Item label={intl.get('channel') + ':'}>
          <Select
            value={searchParam.channel_id}
            disabled={props.userInfo.is_channel_user}
            style={{ width: '100%' }}
            onChange={(e) => updateSearchParam({ channel_id: e })}
          >
            {channelData &&
              channelData.data.map((e) => (
                <Select.Option key={e.id} value={e.id}>
                  {e.name}
                </Select.Option>
              ))}
          </Select>
        </ConditionPanel.Item>
        <ConditionPanel.Item enterSearch>
          <Button type='primary' block onClick={() => searchList(1)}>
            {intl.get('btn_search')}
          </Button>
        </ConditionPanel.Item>
      </ConditionPanel>
      <Row className='margin-bottom-5'>
        <Col>
          <Button type='primary' size='small' onClick={() => toggleModal('add')}>
            {intl.get('btn_add')}
          </Button>
          <Button className='margin-left-5' type='primary' size='small' onClick={() => toggleModal('edit')}>
            {intl.get('btn_edit')}
          </Button>
          <Button className='margin-left-5' type='primary' size='small' onClick={delList}>
            {intl.get('btn_del')}
          </Button>
        </Col>
      </Row>
      <CommonTable columns={columns} {...tableProps} />
      {/* 操作 */}
      <Modal
        width={isMobileBrowser() ? '100%' : '50%'}
        title={intl.get('operation')}
        visible={visible}
        destroyOnClose={true}
        maskClosable={false}
        onOk={saveItem}
        onCancel={toggleModal}
      >
        <TemplateForm
          title={intl.get('name')}
          span={[8, 12, 0]}
          condition={<Input disabled={type === 'edit'} value={param.name} onChange={(e) => updateParam({ name: e.target.value })} />}
        />
        <TemplateForm
          title={intl.get('nickName')}
          span={[8, 12, 0]}
          condition={<Input value={param.nick_name} onChange={(e) => updateParam({ nick_name: e.target.value })} />}
        />
        <TemplateForm
          title={intl.get('password')}
          span={[8, 12, 0]}
          condition={<Input value={getDAesString(param.pass_word)}
                            onChange={(e) => updateParam({pass_word: encodeAesString(e.target.value)})}/>}
        />
        <TemplateForm
          title={intl.get('email')}
          span={[8, 12, 0]}
          condition={<Input value={param.email} onChange={(e) => updateParam({ email: e.target.value })} />}
        />
        <TemplateForm
          title={intl.get('phone')}
          span={[8, 12, 0]}
          condition={<Input value={param.phone} onChange={(e) => updateParam({ phone: e.target.value })} />}
        />
        <TemplateForm
          title={intl.get('channel')}
          span={[8, 12, 0]}
          condition={
            <Select disabled={true} style={{ width: '100%' }} value={param.ref_id} onChange={(e) => updateParam({ ref_id: e })}>
              {channelData &&
                channelData.data.map((e) => (
                  <Select.Option key={e.id} value={e.id}>
                    {e.name}
                  </Select.Option>
                ))}
            </Select>
          }
        />
        <TemplateForm
          title={intl.get('status')}
          span={[8, 12, 0]}
          condition={
            <Select style={{ width: '100%' }} value={param.use_status ? 1 : 0} onChange={(e) => updateParam({ use_status: e })}>
              {Object.values(USE_STATUS.properties).map((e) => (
                <Select.Option key={e.key} value={e.key}>
                  {intl.get(e.text)}
                </Select.Option>
              ))}
            </Select>
          }
        />
      </Modal>
    </Spin>
  );
};
const mapStateToProps = (state) => {
  const { GlobalReducer } = state;
  return {
    userInfo: GlobalReducer.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserChannelList);
