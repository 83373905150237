import { createStore, applyMiddleware,compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/index';
import {routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import createHistory from "history/createBrowserHistory";
import LoadingMiddleware from '../middleware/LoadingMiddleware';
const history = createHistory();

const middlewares = [LoadingMiddleware,thunk, routerMiddleware(history)];
const enhancers = [applyMiddleware(...middlewares)];

export default function configureStore(initialState) {
  const composeEnhancers = compose;
  var store= createStore(
    rootReducer,
    initialState,
    composeEnhancers(...enhancers),
  );

  return store;
}
