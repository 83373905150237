import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { message, Input, Button, Row, Col, Layout, Select } from 'antd';
import { Api_request } from '../global/constants';
import { connect } from 'react-redux';
import { setUserInfoSuccess } from './actions';
import SelectLanguage from '../common/SelectLanguage';
import http from '../../helper/http';
import './index.css';
import CryptoJS from 'crypto-js';

const { Content } = Layout;
const Login = (props) => {
    const [account, setAccount] = useState('');
    const [password, setPassword] = useState('');

    const encodeAesString = (data) => {
        let key = CryptoJS.enc.Utf8.parse("abcdef0123456789");
        let iv = CryptoJS.enc.Utf8.parse("abcdef0123456789");
        let encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        //返回的是base64格式的密文 
        // debugger
        return encrypted.toString();
    }


    const login = async () => {
        if (!account) {
            message.warning(intl.get('accountIsRequired'));
            return;
        }

        if (!password) {
            message.warning(intl.get('passwordIsRequired'));
            return;
        }

        let params = {
            user_name: account,
            password: encodeAesString(password),
        };
        // debugger
        const result = await http.put(Api_request + `/login/admin`, params);
        if (result && result.data.errcode === 0) {
            // message.success(intl.get('loginSuccess'));
            localStorage.setItem('userInfo', JSON.stringify([result.data.data]));
            props.setUserInfoSuccess(result.data.data);
            props.history.push('/page/dashboard');
        }
    };
    
    let ant_layout = {
        background: 'transparent',
        textAlign: 'center',
    };
    
    return (
        <Row className='login-container'>
            <SelectLanguage style={{ position: 'absolute', top: '10px', right: '10px' }} />
            <Col lg={10} md={14} sm={18} xs={20} className='loginContent'>
                <Layout style={ant_layout}>
                    <Content>
                        <Row>
                            <Col className='contentForm'>
                                <div className='login-main'>
                                    <div id='login-middle'>
                                        <div className='header-login'>
                                            <div className='login-header-title'>
                                                <h3>{intl.get('systemName')}</h3>
                                            </div>
                                            <div className='tang-pass-login' style={{ marginTop: '28PX' }}>
                                                <p className='pass-form-item pass-form-item-userName'>
                                                    <label
                                                        className='pass-label pass-label-userName'>{intl.get('account')}</label>
                                                    <Input
                                                        onChange={(e) => setAccount(e.target.value)}
                                                        className='pass-text-input pass-text-input-userName'
                                                        autocomplete='off'
                                                        placeholder={intl.get('account')}
                                                        type='text'
                                                        required=''
                                                        onPressEnter={login}
                                                    />
                                                </p>
                                                <p className='pass-form-item pass-form-item-password'
                                                    style={{ marginTop: '10px', border: '1px solid #e7e7e7' }}>
                                                    <label
                                                        className='pass-label pass-label-password'>{intl.get('password')}</label>
                                                    <Input
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        className='pass-text-input pass-text-input-password'
                                                        placeholder={intl.get('password')}
                                                        type='password'
                                                        name='password'
                                                        required=''
                                                        onPressEnter={login}
                                                    />
                                                </p>
                                                <p className='pass-form-item pass-form-item-submit'>
                                                    <Button onClick={login} onKeyUp={login} className='pass-button pass-button-submit'
                                                        type='submit'>
                                                        {intl.get('login')}
                                                    </Button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </Col>
        </Row>
    );
};

const mapStateToProps = (state) => {
    const { } = state;
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfoSuccess: (userInfo) => {
            dispatch(setUserInfoSuccess(userInfo));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
