import React, {useState, useEffect} from 'react';
import intl from 'react-intl-universal';
import {connect} from 'react-redux';
import {Button, Input, Row, Col, Modal, message, Icon, Spin, Divider} from 'antd';
import {Link} from 'react-router-dom';
import useTable from '../../../hooks/useTable';
import CommonTable from '../../../components/CommonTable';
import TemplateForm from '../../common/TemplateForm';
import moment from 'moment';
import {isMobileBrowser} from '../../../helper/until';
import {useParams} from '../../../hooks/utils';
import {createTemplate, updateTemplate, delTemplate} from './service';

const PremiumRateConfigList = (props) => {
    const [actionLoading, setActionLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [type, setType] = useState('add');
    const [param, updateParam] = useParams({
        name: '',
    });
    const {tableProps, search} = useTable(
        {
            url: '/rate_template',
        },
        {
            pagination: true,
            noFetch: true,
        }
    );

    const columns = [
        {title: intl.get('template_name'), dataIndex: 'rate_name', key: 'rate_name'},
        {title: intl.get('channel_used_count'), dataIndex: 'channel_used_count', key: 'channel_used_count'},
        {
            title: intl.get('time_update'),
            dataIndex: 'time_update',
            key: 'time_update',
            render: (t) => <span>{t ? moment(t).format('YYYY-MM-DD HH:mm') : '--'}</span>,
        },
        {
            title: props.userInfo.is_station && intl.get('operation'),
            dataIndex: 'action',
            key: 'action',
            render: (t, r) =>
                props.userInfo.is_station && (
                    <React.Fragment>
            <span className='font-link' onClick={() => toggleModal('edit', r)}>
              {intl.get('btn_edit')}
            </span>
                        <Divider type='vertical'/>
                        <span className='font-link'>
              <Link to={`/page/premium_rate_config_Detail?id=${r.id}`}>{intl.get('Set_rate')}</Link>
            </span>
                        <Divider type='vertical'/>

                        <span className='font-link' onClick={() => delList(r.id)}>
              {intl.get('btn_del')}
            </span>
                    </React.Fragment>
                ),
        },
    ];

    const toggleModal = (type, item) => {
        let a = !visible;
        updateParam({
            name: '',
        });
        if (type === 'edit' && a) {
            updateParam(item);
        }
        setVisible(a);
        setType(type);
    };

    const saveItem = () => {
        if (!param.rate_name || param.rate_name == '') {
            message.warning(intl.get('templateNameIsRequired'));
            return false;
        }
        setActionLoading(true);
        if (type === 'add') {
            createTemplate(param, () => {
                message.success(intl.get('createSuccess'));
                search(1);
                toggleModal();
                setActionLoading(false);
            });
        } else {
            updateTemplate(param, () => {
                message.success(intl.get('editSuccess'));
                search(1);
                toggleModal();
                setActionLoading(false);
            });
        }
    };

    const delList = (id) => {
        setActionLoading(true);
        delTemplate(id, () => {
            message.success(intl.get('deleteSuccess'));
            search(1);
            setActionLoading(false);
        });
        setTimeout(() => {
            setActionLoading(false);
        }, 5000);
    };

    return (
        <Spin spinning={actionLoading}>
            <Row>
                <Col className='text-algin-right'>
          <span className='font-link' onClick={() => toggleModal('tip')}>
            {intl.get('Description_rate')}
          </span>
                    {!props.userInfo.is_channel_user && (
                        <Button className='margin-left-16' type='primary' size='small'
                                onClick={() => toggleModal('add')}>
                            {intl.get('btn_add')}
                        </Button>
                    )}
                </Col>
            </Row>
            <CommonTable columns={columns} {...tableProps} />
            {/* 操作 */}
            <Modal
                width={isMobileBrowser() ? '100%' : '50%'}
                title={intl.get('operation')}
                visible={visible && (type === 'add' || type === 'edit')}
                destroyOnClose={true}
                maskClosable={false}
                onOk={saveItem}
                onCancel={toggleModal}
            >
                <TemplateForm
                    title={intl.get('template_name')}
                    span={[8, 12, 0]}
                    condition={<Input value={param.rate_name}
                                      onChange={(e) => updateParam({rate_name: e.target.value})}/>}
                />
            </Modal>
            <Modal
                width={isMobileBrowser() ? '100%' : '50%'}
                title={intl.get('Description_rate')}
                visible={visible && type === 'tip'}
                destroyOnClose={true}
                maskClosable={false}
                footer={null}
                onCancel={toggleModal}
            >
                <Row className='text-algin-center'>
                    <Col>
                        <div
                            style={{
                                width: '150px',
                                height: '150px',
                                borderRadius: '100%',
                                backgroundColor: 'rgb(0 171 255 / 55%)',
                                margin: '0 auto 20px',
                                fontSize: '50px',
                                lineHeight: '150px',
                                color: '#fff',
                            }}
                        >
                            <Icon type='snippets'/>
                        </div>
                    </Col>
                    <Col>
                        <p>1、{intl.get('desc_1')}</p>
                        <p>2、{intl.get('desc_2')}</p>
                    </Col>
                </Row>
            </Modal>
        </Spin>
    );
};
const mapStateToProps = (state) => {
    const {GlobalReducer} = state;
    return {
        userInfo: GlobalReducer.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(PremiumRateConfigList);
