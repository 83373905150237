import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Table } from 'antd';
import classNames from 'classnames';
import './index.css';

const CommonTable = (props) => {
  const { columns = [], scroll: { y } = {} } = props;
  const tableRef = useRef(null);
  const [hasResize, setHasResize] = useState(false);
  const hasFixed = columns.some((e) => e.fixed); //判断columns中是否有fixed设置
  const hasScrollY = y ? true : false; //判断是否需要y轴滚动
  const isFixedWithScrollY = hasFixed && hasScrollY;

  // 处理fixed列不对齐的bug
  useEffect(() => {
    if (typeof ResizeObserver !== 'undefined') {
      // 判断浏览器是否支持ResizeObserver
      let resizeObserver = null;
      const listenDom = ReactDOM.findDOMNode(tableRef.current).querySelector('.ant-table-fixed');
      if (listenDom) {
        resizeObserver = new ResizeObserver((entries) => {
          window.dispatchEvent(new Event('resize'));
        });
        resizeObserver.observe(listenDom);
      }
      return () => {
        if (resizeObserver && listenDom) {
          resizeObserver.unobserve(listenDom);
        }
      };
    }
  }, []);

  // 处理fixed列不对齐的bug： safari等浏览器的兼容处理
  useEffect(() => {
    if (typeof ResizeObserver === 'undefined' && !hasResize && props.dataSource && props.dataSource.length > 0) {
      setHasResize(true);
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 100);
    }
  }, [props.dataSource]);

  return (
    <Table
      ref={tableRef}
      bordered={false}
      rowKey='id'
      scroll={{ x: true }}
      size='small'
      {...props}
      style={{
        whiteSpace: hasScrollY ? 'normal' : 'nowrap',
        ...props.style,
      }}
      className={classNames(
        {
          ['table-fixed-xy-scroll']: isFixedWithScrollY,
          ['common-table-style']: true,
        },
        props.className
      )}
    />
  );
};

export default CommonTable;
