import React from 'react';
import { Col } from 'antd';
import './index.css';
const Description = (props) => {
  return (
    <Col span={props.span ? props.span : 8} {...props}>
      <div className='detail-inline-box'>
        {props.label && (
          <span className='detail-inline-box-label' style={{ width: 130 }}>
            {props.label ? props.label : ''}&nbsp;:&nbsp;&nbsp;&nbsp;
          </span>
        )}
        <div className='detail-inline-box-value' style={{ width: '100%', wordBreak: 'break-all' }}>
          {props.children}
        </div>
      </div>
    </Col>
  );
};

export default Description;
