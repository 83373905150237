import React from 'react'
import CommonTable from './CommonTable'
import EditableTable from './EditableTable'

const _CommonTable = props => {
  let isEditable = false

  // 判断列是否有editable属性
  for (const col of props.columns) {
    if(col.editable) {
      isEditable = true;
      break;
    }
  }
  
  return (
    isEditable ? <EditableTable {...props}/> : <CommonTable {...props}/>
  );
};

export default _CommonTable;
