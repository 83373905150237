import React, {useState, useEffect} from 'react';
import intl from 'react-intl-universal';
import {Descriptions, Card, Row, Col, Button, Tabs, Tooltip, Anchor} from 'antd';
import {isMobileBrowser, getUrlParam} from '../../../helper/until';
import Description from '../../common/Description';
import {getProductDetail} from './service';
import SelectLanguage from "../../common/SelectLanguage";

const {Link} = Anchor;

const {Meta} = Card;
const {TabPane} = Tabs;
const ProductConfigDetail = (props) => {
    const productId = getUrlParam('id');
    const userId = getUrlParam('user_id') || '';
    const channelId = getUrlParam('channel_id') || '';
    const [detail, setDetail] = useState({});
    const [currentAnchor, setCurrentAnchor] = useState('#product_detail');
    useEffect(() => {
        getProductDetail(productId, (data) => {
            setDetail(data);
        });
    }, []);

    const downLoadFile = (url) => {
        window.open(url);
    };

    const goToShop1 = () => {
        props.history.push(`/client/PaySteps?id=${productId}&user_id=${userId}&channel_id=${channelId}`);
    };

    const goToShop = () => {
        props.history.push(`/client/PaySteps?id=${productId}&user_id=${userId}&channel_id=${channelId}`);
    };

    const getAmount = (amount) => {
        if (intl.get('language') === 'CN'){
            return amount+"美元 "+intl.get('begin');
        }else {
            return intl.get('begin')+' US $'+amount;

        }
    }

    return (
        <div style={{backgroundColor: '#f6f6f6', paddingTop: '35px'}}>

            <div style={{position: 'fixed',top:'60px', bottom: '80px', right: '10px', zIndex: '999'}}>
                {/*<Tooltip title={intl.get('Choose_language')}>*/}
                    <SelectLanguage style={{marginLeft:'3px'}}/>
                {/*</Tooltip>*/}
            </div>
            <div style={{position: 'fixed',bottom: '80px', right: '10px', zIndex: '999'}}>
                <Tooltip title={intl.get('click_1')}>
                    <Button onClick={goToShop1} className='margin-right-8' shape='ellipse' type='primary'>
                        {intl.get('Insured_immediately')}
                    </Button>
                </Tooltip>
                <Tooltip title={intl.get('click_2')}>
                    <Button
                        onClick={() => {
                            props.history.go(-1);
                        }}
                        shape='circle'
                        icon='rollback'
                    />
                </Tooltip>
            </div>
            <Card
                style={{width: '97%', margin: '16px auto'}}
                cover={
                    <div style={{maxHeight: '400px', width: '100%', overflow: 'hidden'}}>
                        <img width='100%' height='100%' src={detail.image_main_url}/>
                    </div>
                }
            >
                <Meta
                    description={
                        <Row style={{display: 'flex', alignItems: 'center', position: 'sticky', bottom: 0}}>
                            <Col lg={18} md={16} xs={24} sm={24}>
                                <Descriptions title={detail.product_name} column={isMobileBrowser() ? 1 : 2}>
                                    <Descriptions.Item
                                        label={intl.get('suitable_for_age')}>{detail.suitable_for_age}</Descriptions.Item>
                                    {/* <Descriptions.Item label='保险期限'>--</Descriptions.Item> */}
                                    <Descriptions.Item
                                        label={intl.get('sale_range')}>{detail.sale_range}</Descriptions.Item>
                                    {/* <Descriptions.Item label='保单形式'>--</Descriptions.Item> */}
                                    {detail.contract_terms_url ||
                                    detail.contract_terms_url1 ||
                                    (detail.contract_terms_url2 && (
                                        <Descriptions.Item label={intl.get('Insurance_liability')}>
                                            {intl.get('part_1')}
                                            {detail.contract_terms_url && (
                                                <span onClick={() => downLoadFile(detail.contract_terms_url)}
                                                      className='font-link'>
                            {intl.get('part_2')}
                          </span>
                                            )}
                                            {detail.contract_terms_url1 && (
                                                <span onClick={() => downLoadFile(detail.contract_terms_url1)}
                                                      className='font-link'>
                            ，{intl.get('part_6')}
                          </span>
                                            )}
                                            {detail.contract_terms_url2 && (
                                                <span onClick={() => downLoadFile(detail.contract_terms_url2)}
                                                      className='font-link'>
                            ，{intl.get('part_7')}
                          </span>
                                            )}
                                            {intl.get('part_3')}
                                            <span
                                                className='font-color-orange'> {intl.get('part_4')}</span> {intl.get('part_5')}
                                        </Descriptions.Item>
                                    ))}
                                </Descriptions>
                            </Col>
                        </Row>
                    }
                />
                <Row>
                    <Descriptions.Item style={{width: '100%'}}>
                        <Description span={24}>
                            {intl.get('insurance_premiums')}:<span
                            style={{color: 'orange', fontSize: '22px'}}>{getAmount((detail.min_price * 1).toFixed(2))}</span>
                            {/*<Button type='primary' onClick={goToShop} style={{marginLeft: '15px'}}*/}
                            {/*        className='pull-right'>*/}
                            {/*    {intl.get('Insured_immediately')}*/}
                            {/*/!*</Button>*!/float: right;*/}
                            <Button onClick={goToShop1} shape='ellipse' type='primary' float='right'
                                    style={{marginLeft: '15px',zIndex: '999',float:'right'}}>
                                {intl.get('Insured_immediately')}
                            </Button>

                        </Description>
                    </Descriptions.Item>
                </Row>
            </Card>
            <Card style={{width: '100%'}}>
                <div style={{width: '100%', overflowX: 'auto'}}>
                    <Anchor style={{width: 'auto'}} getCurrentAnchor={currentAnchor} showInkInFixed={true}
                            offsetBottom='1000'>
                        <Link href='#product_detail' title={intl.get('product_detail')} style={{width: 'auto'}}/>
                        <Link href='#Information_insurance' title={intl.get('Information_insurance')}
                              style={{width: 'auto'}}/>
                        <Link href='#protection_terms' title={intl.get('protection_terms')} style={{width: 'auto'}}/>
                        <Link href='#disclosure_information' title={intl.get('disclosure_information')}
                              style={{width: 'auto'}}/>
                    </Anchor>
                </div>

                <Row className='media-img-style'>
                    <Col id='product_detail'>
                        <div dangerouslySetInnerHTML={{__html: detail.product_detail, align: 'center'}}/>
                    </Col>
                    <Col id='Information_insurance'>
                        <div dangerouslySetInnerHTML={{__html: detail.insurance_notice}}/>
                    </Col>
                    <Col id='protection_terms'>
                        <div dangerouslySetInnerHTML={{__html: detail.safeguard_clause}}/>
                    </Col>
                    <Col id='disclosure_information'>
                        <div dangerouslySetInnerHTML={{__html: detail.product_qa}}/>
                    </Col>
                </Row>
            </Card>
            <div style={{height: '45px'}}></div>
        </div>
    );
};

export default ProductConfigDetail;
