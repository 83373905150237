import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import http from '../helper/http';
import { Api_request } from '../containers/global/constants';

const MAX_TOTAL = 10000; // 最多显示10000条数据

/**
 * 表格通用hooks
 * @param {*} httpConfig http请求配置, 属性：host域名（java,javaAdmin,aapi），params请求参数，url路径,method请求方法（默认GET），onSuccess成功回调，onError失败回调, getData自定请求数据方法（可选，如果有，前面的参数可不写）
 * @param {*} tableConfig 表格配置, 属性：pagination分页，pageSize单页显示条数，noFetch是否前端请求,rowSelection是否启用多选, defaultLoading初始是否加载数据
 *
 */
const useTable = (httpConfig, tableConfig = {}) => {
  const { noFetch = false, defaultLoading = true } = tableConfig;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(defaultLoading);
  const [pagination, setPagination] = useState(
    tableConfig.pagination !== false
      ? {
          pageSize: tableConfig.pageSize || 10,
          current: 1,
          total: 0,
          showTotal: (total) => `${intl.get('page_desc_1')} ${total} ${intl.get('page_desc_2')}`,
        }
      : false
  );
  const [sort, setSort] = useState({});
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (loading) {
      getData();
    }
  }, [loading]);

  // 表格多选配置
  let rowSelection = undefined;
  if (tableConfig.rowSelection) {
    rowSelection = {
      selectedRowKeys: selectedKeys,
      onChange: (keys, rows) => {
        setSelectedKeys(keys);
        setSelectedRows(rows);
      },
    };
    if (tableConfig.rowSelection.getCheckboxProps) {
      rowSelection.getCheckboxProps = tableConfig.rowSelection.getCheckboxProps;
    }
  }

  // 获取数据
  const getData = async () => {
    if (httpConfig) {
      const { params = {}, url, method, onSuccess, onError, getData } = httpConfig;
      let result = null;
      if (getData) {
        //自定义请求数据方法
        result = getData();
      } else {
        const reqParams = JSON.parse(JSON.stringify(params));
        if (pagination && !noFetch) {
          reqParams.page = pagination.current;
          reqParams.size = pagination.pageSize;
        }

        if (sort.order) {
          reqParams.sort = `${sort.field},${sort.order === 'ascend' ? 'asc' : 'desc'}`;
        }
        let reqUrl = `${Api_request}${url}`;
        if (url.indexOf('http') !== -1) {
          // 特殊url，http开头的使用绝对地址
          reqUrl = url;
        }
        if (method === 'POST') {
          result = await http.post(reqUrl, reqParams);
        } else {
          result = await http.get(reqUrl, { params: reqParams });
        }
      }
      if (result && result.data.errcode === 0) {
        setData(result.data.data);
        if (pagination && result.data.pager) {
          const total = result.data.pager.total_item_count > MAX_TOTAL ? MAX_TOTAL : result.data.pager.total_item_count || 0;
          setPagination({
            ...pagination,
            total,
            showTotal: () => `${intl.get('page_desc_1')} ${result.data.pager.total_item_count || 0} ${intl.get('page_desc_2')}`,
          });
        }
        if (tableConfig.rowSelection) {
          setSelectedKeys([]);
          setSelectedRows([]);
        }
        onSuccess && onSuccess(result.data);
      } else {
        setData([]);
        onError && onError(result.data);
      }
      setLoading(false);
    }
  };

  /**
   * 查询
   * @param {*} current 指定跳转页
   */
  const search = (current) => {
    if (current != null && pagination) {
      setPagination({
        ...pagination,
        current,
      });
    }
    setLoading(true);
  };

  /**
   * 表格change
   * @param {*} p 分页
   * @param {*} f 过滤
   * @param {*} s 排序
   */
  const handleTableChange = (p, f, s) => {
    if (s) {
      setSort(s);
    }
    if (pagination) {
      setPagination(p);
    }
    if (!noFetch) {
      // 仅前端更新数据，不请求后端。
      setLoading(true);
    }
  };

  // 暴露表格属性, 用法: <Table {...tableProps} />
  const tableProps = {
    loading,
    dataSource: data,
    pagination,
    rowSelection,
    onChange: handleTableChange,
  };
  // 暴露清楚选择项
  const clearSelectedRows = () => {
    setSelectedKeys([]);
    setSelectedRows([]);
  };

  return {
    data,
    loading,
    pagination,
    setSelectedKeys,
    setSelectedRows,
    clearSelectedRows,
    setData,
    search,
    handleTableChange,
    tableProps,
    selectedKeys,
    selectedRows,
  };
};

export default useTable;
