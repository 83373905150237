import React, {useState, useEffect} from 'react';
import intl from 'react-intl-universal';
import {Button, Input, Row, Col, Modal, Select, message, TreeSelect, Descriptions, Spin, Icon} from 'antd';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import TemplateUpload from '../../common/TemplateUpload';
import TemplateEditor from '../../common/TemplateEditor';
import {useParams} from '../../../hooks/utils';
import moment from 'moment';
import {isMobileBrowser} from '../../../helper/until';
import {Link} from 'react-router-dom';
import {createProduct} from './service';
import {CLASSIFY, USE_STATUS} from '../../global/constants';
import {getAllCompany} from '../../service';

const ProductConfigCreate = (props) => {
    const [actionLoading, setActionLoading] = useState(false);
    const [allCompany, setAllCompany] = useState([]);
    const [param, updateParam] = useParams({
        product_name: '',
        product_code: '',
        image_main_url: '',
        image_samll_url: '',
        contract_terms_url: '',
        contract_terms_url1: '',
        contract_terms_url2: '',
        classify: '',
        classify_sub: '',
        insure_company_id: '',
        programme_one: '',
        programme_two: '',
        programme_three: '',
        programme_four: '',
        programme_five: '',
        use_status: true,
        introduction: '',
        sale_range: '',
        suitable_for_age: '',
        min_price: 0,
        product_detail: '',
        product_qa: '',
        safeguard_clause: '',
        insurance_notice: '',
        rate_percent: 0,
    });
    const [programmeArr, setProgrammeArr] = useState([{id: 1, isShow: true}]);
    const [contractTermsUrlArr, setContractTermsUrlArr] = useState([{id: 1, isShow: true}]);

    useEffect(() => {
        getAllCompany((data) => {
            setAllCompany(data);
        });
    }, []);

    const createProductRequest = (type) => {
        let p = {...param};
        if (!p.rate_percent || p.rate_percent <= 0) {
            message.warning(intl.get('ratePercentIsRequired'));
            return false;
        }
        if (!p.programme_one || p.programme_one <= 0) {
            message.warning(intl.get('ratefangan'));
            return false;
        }
        if (!p.product_name || p.product_name <= 0) {
            message.warning(intl.get('p_prodducet_name'));
            return false;
        }
        if (type == 0) {
            p.use_status = false;
        } else {
            p.use_status = true;
        }
        p.product_detail = param.product_detail.toHTML();
        p.product_detail_en = param.product_detail_en.toHTML();
        p.safeguard_clause = param.safeguard_clause.toHTML();
        p.safeguard_clause_en = param.safeguard_clause_en.toHTML();
        p.product_qa = param.product_qa.toHTML();
        p.product_qa_en = param.product_qa_en.toHTML();
        p.insurance_notice = param.insurance_notice.toHTML();
        p.insurance_notice_en = param.insurance_notice_en.toHTML();

        p.programme_two = programmeArr.find((m) => m.id == 2)?.isShow ? param.programme_two : '';
        p.programme_three = programmeArr.find((m) => m.id == 3)?.isShow ? param.programme_three : '';
        p.programme_four = programmeArr.find((m) => m.id == 4)?.isShow ? param.programme_four : '';
        p.programme_five = programmeArr.find((m) => m.id == 5)?.isShow ? param.programme_five : '';

        p.contract_terms_url1 = contractTermsUrlArr.find((m) => m.id == 1)?.isShow ? param.contract_terms_url1 : '';
        p.contract_terms_url2 = contractTermsUrlArr.find((m) => m.id == 2)?.isShow ? param.contract_terms_url2 : '';

        p.contract_terms_url3 = contractTermsUrlArr.find((m) => m.id == 3)?.isShow ? param.contract_terms_url3 : '';
        p.contract_terms_url4 = contractTermsUrlArr.find((m) => m.id == 4)?.isShow ? param.contract_terms_url4 : '';
        p.contract_terms_url5 = contractTermsUrlArr.find((m) => m.id == 5)?.isShow ? param.contract_terms_url5 : '';
        p.contract_terms_url6 = contractTermsUrlArr.find((m) => m.id == 6)?.isShow ? param.contract_terms_url6 : '';
        p.contract_terms_url7 = contractTermsUrlArr.find((m) => m.id == 7)?.isShow ? param.contract_terms_url7 : '';
        p.contract_terms_url8 = contractTermsUrlArr.find((m) => m.id == 8)?.isShow ? param.contract_terms_url8 : '';
        p.contract_terms_url9 = contractTermsUrlArr.find((m) => m.id == 9)?.isShow ? param.contract_terms_url9 : '';
        p.contract_terms_url10 = contractTermsUrlArr.find((m) => m.id == 10)?.isShow ? param.contract_terms_url10 : '';
        p.contract_terms_url11 = contractTermsUrlArr.find((m) => m.id == 11)?.isShow ? param.contract_terms_url11 : '';
        p.contract_terms_url12 = contractTermsUrlArr.find((m) => m.id == 12)?.isShow ? param.contract_terms_url12 : '';
        p.contract_terms_url13 = contractTermsUrlArr.find((m) => m.id == 13)?.isShow ? param.contract_terms_url13 : '';
        p.contract_terms_url14 = contractTermsUrlArr.find((m) => m.id == 14)?.isShow ? param.contract_terms_url14 : '';
        p.contract_terms_url15 = contractTermsUrlArr.find((m) => m.id == 15)?.isShow ? param.contract_terms_url15 : '';
        p.contract_terms_url16 = contractTermsUrlArr.find((m) => m.id == 16)?.isShow ? param.contract_terms_url16 : '';
        p.contract_terms_url17 = contractTermsUrlArr.find((m) => m.id == 17)?.isShow ? param.contract_terms_url17 : '';
        p.contract_terms_url18 = contractTermsUrlArr.find((m) => m.id == 18)?.isShow ? param.contract_terms_url18 : '';
        p.contract_terms_url19 = contractTermsUrlArr.find((m) => m.id == 19)?.isShow ? param.contract_terms_url19 : '';
        p.contract_terms_url20 = contractTermsUrlArr.find((m) => m.id == 20)?.isShow ? param.contract_terms_url20 : '';
        p.contract_terms_url21 = contractTermsUrlArr.find((m) => m.id == 21)?.isShow ? param.contract_terms_url21 : '';
        p.contract_terms_url22 = contractTermsUrlArr.find((m) => m.id == 22)?.isShow ? param.contract_terms_url22 : '';
        p.contract_terms_url23 = contractTermsUrlArr.find((m) => m.id == 23)?.isShow ? param.contract_terms_url23 : '';
        p.contract_terms_url24 = contractTermsUrlArr.find((m) => m.id == 24)?.isShow ? param.contract_terms_url24 : '';
        p.contract_terms_url25 = contractTermsUrlArr.find((m) => m.id == 25)?.isShow ? param.contract_terms_url25 : '';
        p.contract_terms_url26 = contractTermsUrlArr.find((m) => m.id == 26)?.isShow ? param.contract_terms_url26 : '';
        p.contract_terms_url27 = contractTermsUrlArr.find((m) => m.id == 27)?.isShow ? param.contract_terms_url27 : '';
        p.contract_terms_url28 = contractTermsUrlArr.find((m) => m.id == 28)?.isShow ? param.contract_terms_url28 : '';
        p.contract_terms_url29 = contractTermsUrlArr.find((m) => m.id == 29)?.isShow ? param.contract_terms_url29 : '';
        p.contract_terms_url30 = contractTermsUrlArr.find((m) => m.id == 30)?.isShow ? param.contract_terms_url30 : '';
        p.contract_terms_url31 = contractTermsUrlArr.find((m) => m.id == 31)?.isShow ? param.contract_terms_url31 : '';
        p.contract_terms_url32 = contractTermsUrlArr.find((m) => m.id == 32)?.isShow ? param.contract_terms_url32 : '';
        p.contract_terms_url33 = contractTermsUrlArr.find((m) => m.id == 33)?.isShow ? param.contract_terms_url33 : '';
        p.contract_terms_url34 = contractTermsUrlArr.find((m) => m.id == 34)?.isShow ? param.contract_terms_url34 : '';
        p.contract_terms_url35 = contractTermsUrlArr.find((m) => m.id == 35)?.isShow ? param.contract_terms_url35 : '';
        p.contract_terms_url36 = contractTermsUrlArr.find((m) => m.id == 36)?.isShow ? param.contract_terms_url36 : '';
        p.contract_terms_url37 = contractTermsUrlArr.find((m) => m.id == 37)?.isShow ? param.contract_terms_url37 : '';
        p.contract_terms_url38 = contractTermsUrlArr.find((m) => m.id == 38)?.isShow ? param.contract_terms_url38 : '';
        p.contract_terms_url39 = contractTermsUrlArr.find((m) => m.id == 39)?.isShow ? param.contract_terms_url39 : '';

        setActionLoading(true);
        createProduct(p, () => {
            message.success(intl.get('createSuccess'));
            setActionLoading(false);
            if (!type) {
                setTimeout(() => {
                    props.history.push('/page/product_config_list');
                }, 200);
            }
        });
    };

    const addProgramme = () => {
        let a = [...programmeArr];
        if (programmeArr.length < 5) {
            a.push({
                id: programmeArr.length + 1,
                isShow: true,
            });
            setProgrammeArr(a);
        } else {
            message.warning('最多只能添加5个方案');
        }
    };
    const delProgramme = () => {
        let a = [...programmeArr];
        let len = a.length;
        if (len == 1) {
            message.warning('至少保留一个方案');
        } else {
            a.splice(len - 1, 1);
            // debugger;
            setProgrammeArr(a);
        }
    };

    const addContractTermsUrlArr = () => {
        let a = [...contractTermsUrlArr];
        // if (a.length < 3) {
        a.push({
            id: a.length + 1,
            isShow: true,
        });
        setContractTermsUrlArr(a);
        // } else {
        //   message.warning('最多只能添加3个条款');
        // }
    };
    const delContractTermsUrlArr = () => {
        let a = [...contractTermsUrlArr];
        let len = a.length;
        if (len == 1) {
            message.warning('至少保留一个条款');
        } else {
            a.splice(len - 1, 1);
            setContractTermsUrlArr(a);
        }
    };
    return (
        <Spin spinning={actionLoading}>
            <Descriptions column={isMobileBrowser() ? 1 : 3}>
                <Descriptions.Item label={<span className='title-div'>{intl.get('product_code')}</span>}>
                    <Input value={param.product_code} onChange={(e) => updateParam({product_code: e.target.value})}/>
                </Descriptions.Item>
                <Descriptions.Item label={<span className='title-div'>{intl.get('product_name')}</span>}>
                    <Input value={param.product_name} onChange={(e) => updateParam({product_name: e.target.value})}/>
                </Descriptions.Item>
                <Descriptions.Item label={<span className='title-div'>{intl.get('product_name_en')}</span>}>
                    <Input value={param.product_name_en}
                           onChange={(e) => updateParam({product_name_en: e.target.value})}/>
                </Descriptions.Item>
                <Descriptions.Item label={<span className='title-div'>{intl.get('classify')}</span>}>
                    <Select value={param.classify} style={{width: '170px'}}
                            onChange={(e) => updateParam({classify: e, classify_sub: ''})}>
                        {Object.values(CLASSIFY.properties).map((e) => (
                            <Select.Option key={e.key} value={e.key}>
                                {intl.get(e.text)}
                            </Select.Option>
                        ))}
                    </Select>
                </Descriptions.Item>
                <Descriptions.Item label={<span className='title-div'>{intl.get('picture')}</span>}>
                    <TemplateUpload url={param.image_main_url}
                                    fileSuccess={(url) => updateParam({image_main_url: url})}/>
                </Descriptions.Item>
                <Descriptions.Item label={<span className='title-div'>{intl.get('Thumbnails')}</span>}>
                    <TemplateUpload url={param.image_samll_url}
                                    fileSuccess={(url) => updateParam({image_samll_url: url})}/>
                </Descriptions.Item>

                <Descriptions.Item label={<span className='title-div'>{intl.get('classify_sub')}</span>}>
                    <Select value={param.classify_sub} style={{width: '170px'}}
                            onChange={(e) => updateParam({classify_sub: e})}>
                        {(CLASSIFY.properties[param.classify * 1] ? CLASSIFY.properties[param.classify * 1].sub : []).map((e) => (
                            <Select.Option key={e.key} value={e.key}>
                                {intl.get(e.text)}
                            </Select.Option>
                        ))}
                    </Select>
                </Descriptions.Item>
                <Descriptions.Item label={<span className='title-div'>{intl.get('introduction')}</span>}>
                    <Input value={param.introduction} onChange={(e) => updateParam({introduction: e.target.value})}/>
                </Descriptions.Item>
                <Descriptions.Item label={<span className='title-div'>{intl.get('introduction_en')}</span>}>
                    <Input value={param.introduction_en}
                           onChange={(e) => updateParam({introduction_en: e.target.value})}/>
                </Descriptions.Item>
                <Descriptions.Item label={<span className='title-div'>{intl.get('insurance_premiums')}</span>}>
                    <Input value={param.min_price} onChange={(e) => updateParam({min_price: e.target.value})}/>
                </Descriptions.Item>
                <Descriptions.Item label={<span className='title-div'>{intl.get('suitable_for_age')}</span>}>
                    <Input value={param.suitable_for_age}
                           onChange={(e) => updateParam({suitable_for_age: e.target.value})}/>
                </Descriptions.Item>
                <Descriptions.Item label={<span className='title-div'>{intl.get('suitable_for_age_en')}</span>}>
                    <Input value={param.suitable_for_age_en}
                           onChange={(e) => updateParam({suitable_for_age_en: e.target.value})}/>
                </Descriptions.Item>
                <Descriptions.Item label={<span className='title-div'>{intl.get('Commission_ratio')}</span>}>
                    <Input
                        style={{width: '170px'}}
                        value={param.rate_percent / 100}
                        addonAfter='%'
                        onChange={(e) => updateParam({rate_percent: e.target.value * 100})}
                    />
                </Descriptions.Item>
                <Descriptions.Item label={<span className='title-div'>{intl.get('sale_range')}</span>}>
                    <Input value={param.sale_range} onChange={(e) => updateParam({sale_range: e.target.value})}/>
                </Descriptions.Item>
                <Descriptions.Item label={<span className='title-div'>{intl.get('sale_range_en')}</span>}>
                    <Input value={param.sale_range_en} onChange={(e) => updateParam({sale_range_en: e.target.value})}/>
                </Descriptions.Item>

                <Descriptions.Item label={<span className='title-div'>{intl.get('status')}</span>}>
                    <Select value={param.use_status ? 1 : 0} onChange={(e) => updateParam({use_status: e})}
                            style={{width: '170px'}}>
                        {Object.values(USE_STATUS.properties).map((e) => (
                            <Select.Option key={e.key} value={e.key}>
                                {intl.get(e.text)}
                            </Select.Option>
                        ))}
                    </Select>
                </Descriptions.Item>
                <Descriptions.Item label={<span className='title-div'>{intl.get('company')}</span>}>
                    <TreeSelect
                        style={{width: '170px'}}
                        value={param.insure_company_id}
                        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                        treeData={allCompany}
                        placeholder='Please select'
                        treeDefaultExpandAll
                        onChange={(e) => updateParam({insure_company_id: e})}
                    />
                </Descriptions.Item>
            </Descriptions>
            <Descriptions>
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_1'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url}
                                        fileSuccess={(url) => updateParam({contract_terms_url: url})}/>
                        <CopyToClipboard
                            text={param.contract_terms_url}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                        <Button onClick={addContractTermsUrlArr} style={{marginLeft: '5px'}} shape='circle'
                                icon='plus'/>
                        <Button onClick={delContractTermsUrlArr} style={{marginLeft: '5px'}} shape='circle'
                                icon='minus'/>
                    </div>
                </Descriptions.Item>
                {contractTermsUrlArr.find((m) => m.id == 2)?.isShow && (
                    <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_2'}</span>}>
                        <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                            <TemplateUpload url={param.contract_terms_url1}
                                            fileSuccess={(url) => updateParam({contract_terms_url1: url})}/>

                            <CopyToClipboard
                                text={param.contract_terms_url1}
                                onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                            >
                                <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                            </CopyToClipboard>
                        </div>
                    </Descriptions.Item>
                )}
                {contractTermsUrlArr.find((m) => m.id == 3)?.isShow && (
                    <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_3'}</span>}>
                        <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                            <TemplateUpload url={param.contract_terms_url2}
                                            fileSuccess={(url) => updateParam({contract_terms_url2: url})}/>
                            <CopyToClipboard
                                text={param.contract_terms_url2}
                                onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                            >
                                <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                            </CopyToClipboard>
                        </div>
                    </Descriptions.Item>
                )}
                {contractTermsUrlArr.find((m) => m.id == 4)?.isShow && (
                    <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_4'}</span>}>
                        <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                            <TemplateUpload url={param.contract_terms_url3}
                                            fileSuccess={(url) => updateParam({contract_terms_url3: url})}/>

                            <CopyToClipboard
                                text={param.contract_terms_url3}
                                onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                            >
                                <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                            </CopyToClipboard>
                        </div>
                    </Descriptions.Item>
                )}
                {contractTermsUrlArr.find((m) => m.id == 5)?.isShow && (
                    <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_5'}</span>}>
                        <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                            <TemplateUpload url={param.contract_terms_url4}
                                            fileSuccess={(url) => updateParam({contract_terms_url4: url})}/>

                            <CopyToClipboard
                                text={param.contract_terms_url4}
                                onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                            >
                                <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                            </CopyToClipboard>
                        </div>
                    </Descriptions.Item>
                )}{contractTermsUrlArr.find((m) => m.id == 6)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_6'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url5}
                                        fileSuccess={(url) => updateParam({contract_terms_url5: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url5}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 7)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_7'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url6}
                                        fileSuccess={(url) => updateParam({contract_terms_url6: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url6}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 8)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_8'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url7}
                                        fileSuccess={(url) => updateParam({contract_terms_url7: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url7}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 9)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_9'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url8}
                                        fileSuccess={(url) => updateParam({contract_terms_url8: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url8}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 10)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_10'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url9}
                                        fileSuccess={(url) => updateParam({contract_terms_url9: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url9}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 11)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_11'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url10}
                                        fileSuccess={(url) => updateParam({contract_terms_url10: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url10}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 12)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_12'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url11}
                                        fileSuccess={(url) => updateParam({contract_terms_url11: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url11}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 13)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_13'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url12}
                                        fileSuccess={(url) => updateParam({contract_terms_url12: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url12}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 14)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_14'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url13}
                                        fileSuccess={(url) => updateParam({contract_terms_url13: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url13}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 15)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_15'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url14}
                                        fileSuccess={(url) => updateParam({contract_terms_url14: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url14}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 16)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_16'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url15}
                                        fileSuccess={(url) => updateParam({contract_terms_url15: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url15}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 17)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_17'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url16}
                                        fileSuccess={(url) => updateParam({contract_terms_url16: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url16}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 18)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_18'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url17}
                                        fileSuccess={(url) => updateParam({contract_terms_url17: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url17}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 19)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_19'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url18}
                                        fileSuccess={(url) => updateParam({contract_terms_url18: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url18}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 20)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_20'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url19}
                                        fileSuccess={(url) => updateParam({contract_terms_url19: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url19}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 21)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_21'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url20}
                                        fileSuccess={(url) => updateParam({contract_terms_url20: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url20}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 22)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_22'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url21}
                                        fileSuccess={(url) => updateParam({contract_terms_url21: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url21}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 23)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_23'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url22}
                                        fileSuccess={(url) => updateParam({contract_terms_url22: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url22}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 24)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_24'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url23}
                                        fileSuccess={(url) => updateParam({contract_terms_url23: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url23}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 25)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_25'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url24}
                                        fileSuccess={(url) => updateParam({contract_terms_url24: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url24}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 26)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_26'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url25}
                                        fileSuccess={(url) => updateParam({contract_terms_url25: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url25}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 27)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_27'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url26}
                                        fileSuccess={(url) => updateParam({contract_terms_url26: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url26}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 28)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_28'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url27}
                                        fileSuccess={(url) => updateParam({contract_terms_url27: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url27}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 29)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_29'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url28}
                                        fileSuccess={(url) => updateParam({contract_terms_url28: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url28}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 30)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_30'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url29}
                                        fileSuccess={(url) => updateParam({contract_terms_url29: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url29}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 31)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_31'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url30}
                                        fileSuccess={(url) => updateParam({contract_terms_url30: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url30}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 32)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_32'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url31}
                                        fileSuccess={(url) => updateParam({contract_terms_url31: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url31}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 33)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_33'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url32}
                                        fileSuccess={(url) => updateParam({contract_terms_url32: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url32}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 34)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_34'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url33}
                                        fileSuccess={(url) => updateParam({contract_terms_url33: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url33}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 35)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_35'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url34}
                                        fileSuccess={(url) => updateParam({contract_terms_url34: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url34}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 36)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_36'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url35}
                                        fileSuccess={(url) => updateParam({contract_terms_url35: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url35}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 37)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_37'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url36}
                                        fileSuccess={(url) => updateParam({contract_terms_url36: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url36}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 38)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_38'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url37}
                                        fileSuccess={(url) => updateParam({contract_terms_url37: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url37}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 39)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_39'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url38}
                                        fileSuccess={(url) => updateParam({contract_terms_url38: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url38}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}{contractTermsUrlArr.find((m) => m.id == 40)?.isShow && (
                <Descriptions.Item label={<span className='title-div'>{intl.get('contract_terms') + '_40'}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <TemplateUpload url={param.contract_terms_url39}
                                        fileSuccess={(url) => updateParam({contract_terms_url39: url})}/>

                        <CopyToClipboard
                            text={param.contract_terms_url39}
                            onCopy={(text, result) => (result ? message.success(intl.get('copySuccess')) : message.warning(intl.get('copyFailed')))}
                        >
                            <Button style={{marginLeft: '5px'}} shape='circle' icon='copy'/>
                        </CopyToClipboard>
                    </div>
                </Descriptions.Item>
            )}

            </Descriptions>
            <Descriptions>
                <Descriptions.Item label={<span className='title-div'>{intl.get('programme_one')}</span>}>
                    <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <Input
                            value={param.programme_one}
                            onChange={(e) => updateParam({programme_one: e.target.value})}
                            placeholder={intl.get('programme_placeholder')}
                        />
                        <Button onClick={addProgramme} style={{marginLeft: '5px'}} shape='circle' icon='plus'/>
                        <Button onClick={delProgramme} style={{marginLeft: '5px'}} shape='circle' icon='minus'/>
                    </div>
                </Descriptions.Item>
                {programmeArr.find((m) => m.id == 2)?.isShow && (
                    <Descriptions.Item label={<span className='title-div'>{intl.get('programme_two')}</span>}>
                        <Input
                            value={param.programme_two}
                            onChange={(e) => updateParam({programme_two: e.target.value})}
                            placeholder={intl.get('programme_placeholder')}
                        />
                    </Descriptions.Item>
                )}
                {programmeArr.find((m) => m.id == 3)?.isShow && (
                    <Descriptions.Item label={<span className='title-div'>{intl.get('programme_three')}</span>}>
                        <Input
                            value={param.programme_three}
                            onChange={(e) => updateParam({programme_three: e.target.value})}
                            placeholder={intl.get('programme_placeholder')}
                        />
                    </Descriptions.Item>
                )}
                {programmeArr.find((m) => m.id == 4)?.isShow && (
                    <Descriptions.Item label={<span className='title-div'>{intl.get('programme_four')}</span>}>
                        <Input
                            value={param.programme_four}
                            onChange={(e) => updateParam({programme_four: e.target.value})}
                            placeholder={intl.get('programme_placeholder')}
                        />
                    </Descriptions.Item>
                )}
                {programmeArr.find((m) => m.id == 5)?.isShow && (
                    <Descriptions.Item label={<span className='title-div'>{intl.get('programme_five')}</span>}>
                        <Input
                            value={param.programme_five}
                            onChange={(e) => updateParam({programme_five: e.target.value})}
                            placeholder={intl.get('programme_placeholder')}
                        />
                    </Descriptions.Item>
                )}
            </Descriptions>
            <Descriptions column={1}>
                <Descriptions.Item label={intl.get('product_detail')}>
                    <TemplateEditor value={param.product_detail}
                                    changeEditor={(editorValue) => updateParam({product_detail: editorValue})}/>
                </Descriptions.Item>
                <Descriptions.Item label={intl.get('product_detail_en')}>
                    <TemplateEditor value={param.product_detail_en}
                                    changeEditor={(editorValue) => updateParam({product_detail_en: editorValue})}/>
                </Descriptions.Item>
                <Descriptions.Item label={intl.get('Information_insurance')}>
                    <TemplateEditor value={param.insurance_notice}
                                    changeEditor={(editorValue) => updateParam({insurance_notice: editorValue})}/>
                </Descriptions.Item>
                <Descriptions.Item label={intl.get('Information_insurance_en')}>
                    <TemplateEditor value={param.insurance_notice_en}
                                    changeEditor={(editorValue) => updateParam({insurance_notice_en: editorValue})}/>
                </Descriptions.Item>
                <Descriptions.Item label={intl.get('protection_terms')}>
                    <TemplateEditor value={param.safeguard_clause}
                                    changeEditor={(editorValue) => updateParam({safeguard_clause: editorValue})}/>
                </Descriptions.Item>
                <Descriptions.Item label={intl.get('protection_terms_en')}>
                    <TemplateEditor value={param.safeguard_clause_en}
                                    changeEditor={(editorValue) => updateParam({safeguard_clause_en: editorValue})}/>
                </Descriptions.Item>
                <Descriptions.Item label={intl.get('disclosure_information')}>
                    <TemplateEditor value={param.product_qa}
                                    changeEditor={(editorValue) => updateParam({product_qa: editorValue})}/>
                </Descriptions.Item>
                <Descriptions.Item label={intl.get('disclosure_information_en')}>
                    <TemplateEditor value={param.product_qa_en}
                                    changeEditor={(editorValue) => updateParam({product_qa_en: editorValue})}/>
                </Descriptions.Item>
            </Descriptions>
            <Row>
                <Col className='text-algin-center'>
                    <Button type='primary' className='margin-right-8' onClick={() => createProductRequest(0)}>
                        {intl.get('btn_submit1')}
                    </Button>
                    <Button type='primary' className='margin-right-8' onClick={() => createProductRequest(1)}>
                        {intl.get('btn_submit')}
                    </Button>
                    <Button type='default'>
                        <Link to={'/page/product_config_list'}> {intl.get('btn_cancel')}</Link>
                    </Button>
                </Col>
            </Row>
        </Spin>
    );
};

export default ProductConfigCreate;
