import React from 'react';
import { Row, Col } from 'antd';
import './index.css';
const TemplateForm = (props) => {
  return (
    <Row gutter={8} className='margin-bottom-16'>
      <Col span={props.span && props.span[0] ? props.span[0] : 4} className='text-algin-right'>
        {props.title ? props.title + (props.condition ? '：' : '') : ''}
      </Col>
      <Col span={props.span && props.span[1] ? props.span[1] : props.des ? 10 : 20}>{props.condition}</Col>
      {props.des && <Col span={props.span && props.span[2] ? props.span[2] : 10}>{props.des}</Col>}
    </Row>
  );
};

export default TemplateForm;
