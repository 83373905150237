import React, {useState, useEffect} from 'react';
import intl from 'react-intl-universal';
import {Button, Input, Row, Col, Modal, message, Select, TreeSelect, Spin} from 'antd';
import useTable from '../../../hooks/useTable';
import ConditionPanel from '../../..//components/ConditionPanel';
import CommonTable from '../../../components/CommonTable';
import TemplateForm from '../../common/TemplateForm';
import moment from 'moment';
import {useParams} from '../../../hooks/utils';
import {USE_STATUS} from '../../global/constants';
import {isMobileBrowser} from '../../../helper/until';
import {createMenus, updateMenus, delMenus} from './service';
import {getAllMenus} from '../../service';
import {connect} from "react-redux";

const MenusList = (props) => {
    const [actionLoading, setActionLoading] = useState(false);
    const [allMenus, setAllMenus] = useState([]);
    const [visible, setVisible] = useState(false);
    const [type, setType] = useState('add');
    const [name, setName] = useState('');
    const [param, updateParam] = useParams({
        menu_code: '',
        menu_name: '',
        menu_url: '',
        parent_id: '',
        remark: '',
        use_status: true,
    });
    const {tableProps, search, selectedKeys, data} = useTable(
        {
            url: '/sys_menu',
            params: {
                name: name,
            },
        },
        {
            pagination: true,
            noFetch: true,
            rowSelection: true,
        }
    );

    useEffect(() => {
        getAllMenusList();
    }, []);

    const getAllMenusList = () => {
        getAllMenus((data) => {
            setAllMenus(data);
        });
    };

    const columns = [
        {title: intl.get('name'), dataIndex: 'menu_name', key: 'menu_name'},
        {title: intl.get('parent_menu'), dataIndex: 'parent_name', key: 'parent_name'},
        {title: intl.get('sort_num'), dataIndex: 'menu_code', key: 'menu_code'},
        {title: intl.get('menu_url'), dataIndex: 'menu_url', key: 'menu_url'},
        {
            title: intl.get('status'),
            dataIndex: 'use_status',
            key: 'use_status',
            render: (t, r) =>
                <span>{USE_STATUS.properties[t ? 1 : 0] ? intl.get(USE_STATUS.properties[t ? 1 : 0].text) : '--'}</span>,
        },
        {
            title: intl.get('time_create'),
            dataIndex: 'time_create',
            key: 'time_create',
            render: (t) => <span>{t ? moment(t).format('YYYY-MM-DD HH:mm') : '--'}</span>,
        },
    ];

    const toggleModal = (type) => {
        updateParam({
            menu_code: '',
            menu_name: '',
            menu_url: '',
            parent_id: '',
            remark: '',
            use_status: true,
        });
        if (type === 'edit' && selectedKeys.length != 1) {
            message.warning(intl.get('onlyOneByEdit'));
            return false;
        }
        if (type === 'edit') {
            let item = data.find((m) => m.id === selectedKeys[0]);
            updateParam(item);
        }
        setVisible(!visible);
        setType(type);
    };

    const saveItem = () => {
        if (!param.menu_name || param.menu_name == '') {
            message.warning(intl.get('nameIsRequired'));
            return false;
        }
        if (!param.menu_code || param.menu_code == '') {
            message.warning(intl.get('sortIsRequired'));
            return false;
        }
        if (!param.menu_url || param.menu_url == '') {
            message.warning(intl.get('menuUrlIsRequired'));
            return false;
        }
        // if (!param.parent_id || param.parent_id == '') {
        //   message.warning('请先选择上级菜单');
        //   return false;
        // }
        setActionLoading(true);
        if (type === 'add') {
            createMenus(param, () => {
                message.success(intl.get('createSuccess'));
                search(1);
                toggleModal();
                getAllMenusList();
                setActionLoading(false);
            });
        } else {
            param.id = selectedKeys[0];
            updateMenus(param, () => {
                message.success(intl.get('editSuccess'));
                search(1);
                toggleModal();
                getAllMenusList();
                setActionLoading(false);
            });
        }
    };

    const delList = () => {
        if (!selectedKeys || selectedKeys.length == 0) {
            message.warning(intl.get('empty'));
            return false;
        }
        setActionLoading(true);
        delMenus(selectedKeys, () => {
            message.success(intl.get('deleteSuccess'));
            search(1);
            getAllMenusList();
            setActionLoading(false);
        });
        setTimeout(() => {
            setActionLoading(false);
        }, 5000);
    };

    return (
        <Spin spinning={actionLoading}>
            <ConditionPanel labelWidth={90}>
                <ConditionPanel.Item label={intl.get('name') + ':'}>
                    <Input
                        style={{width: '100%'}}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                        placeholder={intl.get('placeholder')}
                        allowClear
                    />
                </ConditionPanel.Item>
                <ConditionPanel.Item enterSearch>
                    <Button type='primary' block onClick={() => search(1)}>
                        {intl.get('btn_search')}
                    </Button>
                </ConditionPanel.Item>
            </ConditionPanel>

            {props.userInfo.is_station && (
                <Row className='margin-bottom-5'>
                    <Col>
                        <Button type='primary' size='small' onClick={() => toggleModal('add')}>
                            {intl.get('btn_add')}
                        </Button>
                        <Button className='margin-left-5' type='primary' size='small'
                                onClick={() => toggleModal('edit')}>
                            {intl.get('btn_edit')}
                        </Button>
                        <Button className='margin-left-5' type='primary' size='small' onClick={delList}>
                            {intl.get('btn_del')}
                        </Button>
                    </Col>
                </Row>)}


            <CommonTable columns={columns} {...tableProps} />
            {/* 操作 */}
            <Modal
                width={isMobileBrowser() ? '100%' : '50%'}
                title={intl.get('operation')}
                visible={visible}
                destroyOnClose={true}
                maskClosable={false}
                onOk={saveItem}
                onCancel={toggleModal}
            >
                <TemplateForm
                    title={intl.get('name')}
                    span={[8, 12, 0]}
                    condition={<Input value={param.menu_name}
                                      onChange={(e) => updateParam({menu_name: e.target.value})}/>}
                />

                <TemplateForm
                    title={intl.get('parent_menu')}
                    span={[8, 12, 0]}
                    condition={
                        <TreeSelect
                            style={{width: '100%'}}
                            value={param.parent_id}
                            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                            treeData={allMenus}
                            placeholder='Please select'
                            treeDefaultExpandAll
                            allowClear
                            onChange={(e) => updateParam({parent_id: e})}
                        />
                    }
                />
                <TemplateForm
                    title={intl.get('menu_url')}
                    span={[8, 12, 0]}
                    condition={<Input value={param.menu_url}
                                      onChange={(e) => updateParam({menu_url: e.target.value})}/>}
                />
                <TemplateForm
                    title={intl.get('sort_num')}
                    span={[8, 12, 0]}
                    condition={<Input value={param.menu_code}
                                      onChange={(e) => updateParam({menu_code: e.target.value})}/>}
                />
                <TemplateForm
                    title={intl.get('status')}
                    span={[8, 12, 0]}
                    condition={
                        <Select defaultValue={param.use_status ? 1 : 0} onChange={(e) => updateParam({use_status: e})}
                                style={{width: '100%'}}>
                            {Object.values(USE_STATUS.properties).map((e) => (
                                <Select.Option key={e.key} value={e.key}>
                                    {intl.get(e.text)}
                                </Select.Option>
                            ))}
                        </Select>
                    }
                />
            </Modal>
        </Spin>
    );
};
const mapStateToProps = (state) => {
    const {GlobalReducer} = state;
    return {
        userInfo: GlobalReducer.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(MenusList);
// export default MenusList;
