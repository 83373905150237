import React, {useEffect} from 'react';
import intl from 'react-intl-universal';
import {Button, Input, Row, Col, Empty, Card, Tooltip, Tabs} from 'antd';
import useTable from '../../../hooks/useTable';
import ConditionPanel from '../../..//components/ConditionPanel';
import SelectLanguage from '../../common/SelectLanguage';
import {useParams} from '../../../hooks/utils';
import {getUrlParam} from '../../../helper/until';
import {CLASSIFY} from '../../global/constants';
import {Link} from 'react-router-dom';
import './index.css';
import forte from '../../../image/forte.png';

const {Meta} = Card;
const {TabPane} = Tabs;
const ProductConfigList = (props) => {
    const userId = getUrlParam('user_id') || '';
    const channelId = getUrlParam('channel_id') || '';
    const [searchParam, updateSearchParam] = useParams({
        product_name: '',
        product_code: '',
        classify: -1,
        classify_sub: -1,
        use_status: 1,
        insure_company_id: '',
        language: 'EN',
        channel_id: channelId,
    });

    const {tableProps, search} = useTable(
        {
            url: '/product',
            params: searchParam,
        },
        {
            pagination: true,
            noFetch: true,
            rowSelection: true,
        }
    );

    useEffect(() => {
        search(1);
    }, [searchParam.product_name, searchParam.classify, searchParam.classify_sub]);

    const searchList = (value) => {
        updateSearchParam({product_name: value});
    };

    const changeClassify = (e) => {
        updateSearchParam({classify: e, classify_sub: -1});
    };

    const getAmount = (amount) => {
        // message.warning(intl.get('language'))
        if (intl.get('language') === 'CN') {
            return amount + "美元 " + intl.get('begin');
        } else {
            return intl.get('begin') + ' US $' + amount;

        }
    }

    return (
        <div className={'head_Top'}>
            <div style={{position: 'fixed', top: '60px', right: '10px', zIndex: '999'}}>
                <SelectLanguage style={{marginLeft: '3px'}}/>
            </div>
            <div className={'head_TopIn'}>
                <img src={forte} style={{width: '100%', height: 'auto', marginBottom: '10px', borderRadius: '3px'}}/>
                <div>
                    <Button type='default' className='pull-right'>
                        <Link to={'/page/login'}>{intl.get('systemName')}</Link>
                    </Button>
                    <ConditionPanel labelWidth={690}>
                        <ConditionPanel.Item>
                            <Input.Search
                                placeholder={intl.get('placeholder_product')}
                                enterButton={intl.get('btn_search')}
                                size='large'
                                onSearch={(value) => searchList(value)}
                            />
                        </ConditionPanel.Item>
                    </ConditionPanel>
                    <Tabs defaultActiveKey={searchParam.classify} onChange={(e) => changeClassify(e)}>
                        <TabPane tab={intl.get('All')} key={-1}></TabPane>
                        {Object.values(CLASSIFY.properties).map((e) => (
                            <TabPane tab={intl.get(e.text)} key={e.key}></TabPane>
                        ))}
                    </Tabs>
                    <Row gutter={16}>
                        {searchParam.classify != -1 && (
                            <Col style={{marginBottom: '30px'}}>
                                <span>{intl.get('classify_sub')}：</span>
                                <span
                                    onClick={() => updateSearchParam({classify_sub: -1})}
                                    className={-1 == searchParam.classify_sub ? 'tab-style active' : 'tab-style'}
                                >
                  {intl.get('All')}
                </span>
                                {(CLASSIFY.properties[searchParam.classify] ? CLASSIFY.properties[searchParam.classify].sub : []).map((e) => (
                                    <span
                                        onClick={() => updateSearchParam({classify_sub: e.key})}
                                        className={e.key == searchParam.classify_sub ? 'tab-style active' : 'tab-style'}
                                    >
                    {intl.get(e.text)}
                  </span>
                                ))}
                            </Col>
                        )}

                        {tableProps.dataSource && tableProps.dataSource.length > 0 ? (
                            tableProps.dataSource.map((e) => (
                                <Col lg={6} md={8} xs={24} sm={24}>
                                    <div className={'shadow_div'}>
                                        <Link
                                            to={`/client/product_detail?id=${e.id}&user_id=${userId}&channel_id=${channelId}`}>
                                            <Card
                                                style={{
                                                    marginTop: '3px',
                                                    marginLeft: '3px',
                                                    marginRight: '3px',
                                                    marginBottom: '3px',
                                                }}
                                                cover={
                                                    <div style={{height: '150px', overflow: 'hidden'}}>
                                                        <img style={{height: '100%', width: '100%'}}
                                                             src={e.image_samll_url}/>
                                                    </div>
                                                }
                                            >
                                                <Meta
                                                    title={
                                                        <div style={{marginBottom: '1px'}}>
                                                            <p style={{marginBottom: '1px'}}>{e.product_name}</p>
                                                        </div>

                                                    }
                                                    description={
                                                        <Tooltip title={e.introduction}>
                                                            <span>
                                                                {e.introduction ? (e.introduction.length > 17 ? e.introduction.substring(0, 15) + '...' : e.introduction) : '--'}
                                                            </span>
                                                        </Tooltip>
                                                    }
                                                />
                                                <div style={{marginTop: '15px', fontWeight: 'bold', color: 'red'}}
                                                     className='pull-right'>
                                                    <span
                                                        style={{
                                                            color: 'red',
                                                            fontWeight: 'bold',
                                                            fontSize: '13px'
                                                        }}>{getAmount(e.min_price.toFixed(2))}</span>
                                                    {/*<p style={{marginBottom: '1px', fontSize: '18px'}}>USD{e.min_price}</p>{intl.get('begin')}*/}
                                                </div>
                                            </Card>
                                        </Link>
                                    </div>
                                </Col>
                            ))
                        ) : (
                            <Empty imageStyle={{height: '100px'}} style={{marginTop: '150px'}}/>
                        )}
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default ProductConfigList;
