import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { Button, Input, Row, Col, Modal, message, Tabs, Radio, Divider, InputNumber } from 'antd';
import { connect } from 'react-redux';
import useTable from '../../../hooks/useTable';
import useTableRadio from '../../../hooks/useTableRadio';
import CommonTable from '../../../components/CommonTable';
import TemplateForm from '../../common/TemplateForm';
import { CLASSIFY, USE_STATUS } from '../../global/constants';
import { isMobileBrowser, getUrlParam, downLoad } from '../../../helper/until';
import { useParams } from '../../../hooks/utils';
import { delTemplate, bindTemplateProduct, bindTemplateRate, delTemplateProduct, exportExcel } from './service';
import moment from 'moment';

const { TabPane } = Tabs;
const PremiumRateConfigDetail = (props) => {
  const templateId = getUrlParam('id');
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState('add');
  const [param, updateParam] = useParams({});
  const [searchProductParam, updateSearchProductParam] = useParams({
    product_name: '',
    product_code: '',
    classify: -1,
    classify_sub: -1,
    use_status: '',
    insure_company_id: '',
  });
  const [selectedId, setSelectedId] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const { tableProps, search } = useTable(
    {
      url: `/rate_template/${templateId}/products`,
    },
    {
      pagination: true,
      noFetch: true,
    }
  );

  const productTableProps = useTableRadio(
    {
      url: '/product',
      params: searchProductParam,
    },
    {
      pageSize: 10,
      rowSelection: true,
      curKeys: selectedId,
      setCurSelectedKeys: setSelectedId,
      setCurSelectedRows: setSelectedRow,
    }
  );

  useEffect(() => {
    productTableProps.search(1);
  }, [searchProductParam]);

  const columns = [
    { title: '#', dataIndex: 'index', key: 'index', render: (t, r, i) => <span>{i + 1}</span> },
    {
      title: intl.get('classify'),
      dataIndex: 'classify',
      key: 'classify',
      render: (t, r) => <span>{CLASSIFY.properties[t * 1] ? intl.get(CLASSIFY.properties[t * 1].text) : '--'}</span>,
    },
    {
      title: intl.get('classify_sub'),
      dataIndex: 'classify_sub',
      key: 'classify_sub',
      render: (t, r) => {
        let CLASSIFY_SUB = t ? CLASSIFY.properties[r.classify * 1].sub : [];
        // debugger
        let item = CLASSIFY_SUB ? CLASSIFY_SUB.find((m) => m.key === t * 1) : {};
        return <span>{item ? intl.get(item.text) : '--'}</span>;
      },
    },
    { title: intl.get('product_name'), dataIndex: 'product_name', key: 'product_name' },
    {
      title: intl.get('my_rate'),
      dataIndex: 'rate_percent',
      key: 'rate_percent',
      render: (t, r) => <span>{t ? t / 100 : 0}%</span>,
    },
    {
      title: intl.get('rate_percent_last'),
      dataIndex: 'rate_percent_last',
      key: 'rate_percent_last',
      render: (t, r) => <span>{((r.rate_percent || 0) - (r.rate_percent_sub_level || 0)) / 100}%</span>,
    },
    {
      title: intl.get('rate_percent_sub_level'),
      dataIndex: 'rate_percent_sub_level',
      key: 'rate_percent_sub_level',
      render: (t, r) => <span>{t ? t / 100 : 0}%</span>,
    },
    {
      title: intl.get('operation'),
      dataIndex: 'action',
      key: 'action',
      className: props.userInfo.is_channel_user ? 'hide-columns' : '',
      render: (t, r) => (
        <React.Fragment>
          {console.log('123', props.userInfo.is_channel_user)}
          <span className='font-link' onClick={() => toggleModal('edit', r)}>
            {intl.get('btn_edit')}
          </span>
          <Divider type='vertical' />
          <span className='font-link' onClick={() => delList(r.product_id)}>
            {intl.get('btn_del')}
          </span>
        </React.Fragment>
      ),
    },
  ];

  const toggleModal = (type, item) => {
    let a = !visible;
    if (type === 'edit' && a) {
      updateParam(item);
    }
    setVisible(a);
    setType(type);
  };

  const saveItem = () => {
    // if (param.rate_percent < 0) {
    //   message.warning('请正确填写我的费率');
    //   return false;
    // }
    if (param.rate_percent_sub_level < 0) {
      message.warning(intl.get('rate_percent_sub_level_is_required'));
      return false;
    }
    param.template_id = templateId;
    bindTemplateRate(param, () => {
      message.success(intl.get('editSuccess'));
      search(1);
      toggleModal();
    });
  };

  const delList = (id) => {
    let p = {
      template_id: templateId,
      product_id: id,
    };
    delTemplateProduct(p, () => {
      message.success(intl.get('deleteSuccess'));
      search(1);
    });
  };

  const bindProduct = () => {
    let a = {
      template_id: templateId,
      product_id: selectedId[0],
    };
    bindTemplateProduct(a, () => {
      message.success(intl.get('addSuccess'));
      setTimeout(() => {
        search(1);
        toggleModal();
      }, 200);
    });
  };

  const exportExcelRequest = async () => {
    exportExcel(templateId, (data) => {
      downLoad(data, '费率模版', '.xlsx', 'application/vnd.ms-excel');
      message.success(intl.get('exportSuccess'));
    });
  };
  return (
    <div>
      <Row>
        {!props.userInfo.is_channel_user && (
          <Col className='text-algin-right'>
            <Button className='margin-left-16' type='primary' size='small' onClick={() => toggleModal('product')}>
              {intl.get('btn_add')}
            </Button>
            <Button className='margin-left-16' size='small' onClick={exportExcelRequest}>
              {intl.get('btn_export')}
            </Button>
          </Col>
        )}
      </Row>
      <CommonTable columns={columns} {...tableProps} />
      {/* 操作 */}
      <Modal
        width={isMobileBrowser() ? '100%' : '50%'}
        title={intl.get('operation')}
        visible={visible && type === 'edit'}
        destroyOnClose={true}
        maskClosable={false}
        onOk={saveItem}
        onCancel={toggleModal}
      >
        <TemplateForm title={intl.get('product_name')} span={[8, 12, 0]} condition={<Input value={param.product_name} disabled />} />
        <TemplateForm
          title={intl.get('my_rate')}
          span={[8, 12, 0]}
          condition={<InputNumber disabled value={param.rate_percent / 100} onChange={(e) => updateParam({ rate_percent: e * 100 })} />}
        />
        <TemplateForm
          title={intl.get('rate_percent_sub_level')}
          span={[8, 12, 0]}
          condition={
            <InputNumber min={0} value={param.rate_percent_sub_level / 100} onChange={(e) => updateParam({ rate_percent_sub_level: e * 100 })} />
          }
        />
      </Modal>
      <Modal
        width={isMobileBrowser() ? '100%' : '50%'}
        title={intl.get('all_product')}
        visible={visible && type === 'product'}
        destroyOnClose={true}
        maskClosable={false}
        onOk={bindProduct}
        onCancel={toggleModal}
      >
        <Tabs
          defaultActiveKey='-1'
          onChange={(e) =>
            updateSearchProductParam({
              classify: e,
              classify_sub: CLASSIFY.properties[e]?.sub[0]?.key,
            })
          }
        >
          <TabPane tab={intl.get('ALl')} key={-1}></TabPane>
          {Object.values(CLASSIFY.properties).map((e) => (
            <TabPane tab={intl.get(e.text)} key={e.key}></TabPane>
          ))}
        </Tabs>
        <Row>
          {searchProductParam.classify != '-1' && (
            <Col>
              <TemplateForm
                title={intl.get('classify_sub')}
                span={[4, 20, 0]}
                condition={
                  <Radio.Group
                    value={searchProductParam.classify_sub}
                    size='small'
                    onChange={(e) => updateSearchProductParam({ classify_sub: e.target.value })}
                  >
                    {(CLASSIFY.properties[searchProductParam.classify] ? CLASSIFY.properties[searchProductParam.classify].sub : []).map((e) => (
                      <Radio.Button key={e.key} value={e.key} className='margin-bottom-5'>
                        {intl.get(e.text)}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                }
              />
            </Col>
          )}
          <Col>
            <CommonTable
              columns={[
                { title: intl.get('product_code'), dataIndex: 'product_code', key: 'product_code' },
                { title: intl.get('product_name'), dataIndex: 'product_name', key: 'product_name' },
                {
                  title: intl.get('status'),
                  dataIndex: 'use_status',
                  key: 'use_status',
                  render: (t, r) => <span>{USE_STATUS.properties[t ? 1 : 0] ? intl.get(USE_STATUS.properties[t ? 1 : 0].text) : '--'}</span>,
                },
                {
                  title: intl.get('time_create'),
                  dataIndex: 'time_create',
                  key: 'time_create',
                  render: (t) => <span>{t ? moment(t).format('YYYY-MM-DD HH:mm') : '--'}</span>,
                },
              ]}
              {...productTableProps.tableProps}
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { GlobalReducer } = state;
  return {
    userInfo: GlobalReducer.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PremiumRateConfigDetail);
// export default PremiumRateConfigDetail;
