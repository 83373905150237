import http from '../../../helper/http';
import { Api_request } from '../../global/constants';

export const createUser = async (params, success) => {
  const result = await http.post(Api_request + '/channel_user', params);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const updateUser = async (params, success) => {
  const result = await http.put(Api_request + '/channel_user/' + params.id, params);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const delUser = async (ids, success) => {
  const result = await http.post(Api_request + '/channel_user/delete', ids);
  if (result && result.data.errcode === 0) {
    success();
  }
};
