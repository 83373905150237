import http from '../../../helper/http';
import { Api_request } from '../../global/constants';

export const createCompany = async (params, success) => {
  const result = await http.post(Api_request + '/company', params);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const updateCompany = async (params, success) => {
  const result = await http.put(Api_request + '/company/' + params.id, params);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const delCompany = async (ids, success) => {
  const result = await http.post(Api_request + '/company/delete', ids);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const getCompanyUser = async (id, success) => {
  const result = await http.get(Api_request + `/company/${id}/user`);
  if (result && result.data.errcode === 0) {
    success(result.data.data);
  }
};

export const createCompanyUser = async (id, params, success) => {
  const result = await http.post(Api_request + `/company/${id}/user`, params);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const delCompanyUser = async (id, params, success) => {
  const result = await http.post(Api_request + `/company/${id}/user/delete`, params);
  if (result && result.data.errcode === 0) {
    success();
  }
};
