import { message } from 'antd';
import axios from 'axios';
import oss from 'ali-oss';
import http from '../../helper/http';
import { Api_request } from '../global/constants';

let aliyunSign = {};
let maxRetryTime = 1;
export function getSign(type, reget) {
  return new Promise((resolve, reject) => {
    if (!reget && aliyunSign[type]) {
      resolve(aliyunSign[type]);
    } else {
      axios({
        url: `${Api_request}/oss/token`,
        method: 'get',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).then((result) => {
        if (result && result.data && result.data.errcode == 0) {
          aliyunSign[type] = result.data.data;
          resolve(aliyunSign[type]);
        }
      });
    }
  });
}
export async function uploadOnce(type, reget, file, retryTime, cb) {
  var sign = await getSign(type, reget);
  var filename = file.name;
  var ext = filename.substring(filename.lastIndexOf('.'));
  var storeAs = sign.path + '/' + new Date().getTime() + ext;
  var client = new oss({
    region: 'oss-cn-hongkong',
    secure: true,
    accessKeyId: sign.access_key_id,
    accessKeySecret: sign.access_key_secret,
    stsToken: sign.security_token,
    bucket: sign.bucket,
  });
  // multipartUpload(上传文件路径，文件)
  client
    .multipartUpload(storeAs, file)
    .then(function (result) {
      var result = { relativePath: storeAs, size: file.size };
      cb(null, result);
    })
    .catch(function (ex) {
      if (retryTime < maxRetryTime) {
        uploadOnce(type, true, file, ++retryTime, cb);
      } else cb(ex);
    });
}

/**
 * 上传至阿里云
 * @param {*} type category
 * @param {*} file 上传文件实体
 * @param {*} callBack 回调函数(错误信息，成功信息)
 */
export function uploadToAliyun(type, file, callBack) {
  uploadOnce(type, false, file, 0, function (ex, result) {
    callBack(ex, result);
  });
}

export default { uploadToAliyun };
