import http from '../../helper/http';
import {Api_request} from '../global/constants';

export const performanceInfo = async (success) => {
    const result = await http.get(Api_request + `/dashboard/performance_statistics`);
    if (result && result.data.errcode === 0) {
        success(result.data.data);
    }
};

export const getPerformanceAmountInfo = async (params, success) => {
    const result = await http.get(Api_request + `/dashboard/performance_amount`, {params: params});
    if (result && result.data.errcode === 0) {
        success(result.data.data);
    }
};

export const getCustomersInfo = async (params, success) => {
    const result = await http.get(Api_request + `/dashboard/customer_line`, {params: params});
    if (result && result.data.errcode === 0) {
        success(result.data.data);
    }
};

export const getCustomersNum = async (success) => {
    const result = await http.get(Api_request + `/dashboard/customs_num`);
    if (result && result.data.errcode === 0) {
        success(result.data.data);
    }
};

//产品

export const getProductInfo = async (params, success) => {
    const result = await http.get(Api_request + `/dashboard/product_line`, {params: params});
    if (result && result.data.errcode === 0) {
        success(result.data.data);
    }
};


export const getProductNum = async (success) => {
    const result = await http.get(Api_request + `/dashboard/product_num`);
    if (result && result.data.errcode === 0) {
        success(result.data.data);
    }
};

//佣金

export const getCommissionInfo = async (params, success) => {
    const result = await http.get(Api_request + `/dashboard/commission_line`, {params: params});
    if (result && result.data.errcode === 0) {
        success(result.data.data);
    }
};

export const getCommissionNum = async (success) => {
    const result = await http.get(Api_request + `/dashboard/commission_num`);
    if (result && result.data.errcode === 0) {
        success(result.data.data);
    }
};

