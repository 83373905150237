import http from '../../../helper/http';
import { Api_request } from '../../global/constants';

export const createMail = async (params, success) => {
  const result = await http.post(Api_request + '/email', params);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const updateMail = async (params, success) => {
  const result = await http.put(Api_request + '/email/' + params.id, params);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const delMail = async (ids, success) => {
  const result = await http.post(Api_request + '/email/delete', ids);
  if (result && result.data.errcode === 0) {
    success();
  }
};

export const getMail = async (id, success) => {
  const result = await http.get(Api_request + `/email/${id}/menu`);
  if (result && result.data.errcode === 0) {
    success(result.data.data);
  }
};

export const saveMail = async (param, success) => {
  const result = await http.post(Api_request + `/email/${param.id}`, param);
  if (result && result.data.errcode === 0) {
    success(result.data.data);
  }
};

export const test_Email = async (id, success) => {
  const result = await http.post(Api_request + `/email/test/${id}`);
  if (result && result.data.errcode === 0) {
    success(result.data.data);
  }
};
