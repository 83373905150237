import React, { Component } from 'react';
import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom';
import DocumentTitle from 'react-document-title';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import zh_CN from 'antd/es/locale/zh_CN';
import en_US from 'antd/es/locale/en_US';
import intl from 'react-intl-universal';
import RouterPath from './containers/global/Router';
import configureStore from './store/configureStore';
import { emit } from './emit.js';

import './index.css';

const locales = {
  'en-US': require('./locales/en-US.json'),
  'zh-CN': require('./locales/zh-CN.json'),
};

let createHistory = require('history').createBrowserHistory;
const history = createHistory();
const store = configureStore();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      antdLang: localStorage.getItem('language') ? (localStorage.getItem('language') === 'en-US' ? en_US : zh_CN) : en_US, // 修改antd  组件的国际化
    };
  }
  componentDidMount() {
    emit.on('change_language', (lang) => this.loadLocales(lang, 1)); // 监听语言改变事件
    const language = localStorage.getItem('language') || 'en-US';
    this.loadLocales(language); // 初始化语言
  }
  loadLocales(lang = 'en-US', isRefresh) {
    intl
      .init({
        currentLocale: lang, // 设置初始语音
        locales,
      })
      .then(() => {
        this.setState({
          antdLang: lang === 'en-US' ? en_US : zh_CN,
        });
        localStorage.setItem('language', lang);
        isRefresh && window.location.reload();
      });
  }
  render() {
    return (
      <DocumentTitle title='新丝路保'>
        <Provider store={store}>
          <ConfigProvider locale={this.state.antdLang}>
            <Router history={history}>
              <RouterPath />
            </Router>
          </ConfigProvider>
        </Provider>
      </DocumentTitle>
    );
  }
}

export default App;
