/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState, useReducer} from 'react'

export const useCompute = (fn, keysArr, init) => {
  const [_state, setState] = useState(init)
  useEffect(() => {
    const sync = async () => {
      const newState = await fn()
      setState(newState)
    }
    sync()
  }, keysArr)
  return _state
}

export const useParams = (initParams) => {
  const paramsReducer = (state, action) => {
      switch (action.type) {
        case 'reset': {
          const resetParams = action.params || initParams
          return {
            ...resetParams
          };
        }
        case 'delete': {
          const deleteKeys = action.deleteKeys
          const newState = Object.keys(state).filter(key => {
            return !deleteKeys.some(delKey => delKey === key)
          })
          return newState
        }
        case 'update': {
          const newParams = action.params
          return {
            ...state,
            ...newParams
          };
        }
        default:
          throw new Error();
      }
    }
    const [params, dispatchParams] = useReducer(paramsReducer, initParams)
    const updateParams = (params) => {
      dispatchParams({
          type: 'update',
          params,
      })
    }
    return [params, updateParams, dispatchParams]
}

// 会根据key进行去重，key默认为序号id，未做map优化
export const useArray = (initArr, key = 'id') => {
  const paramsReducer = (arr, action) => {
    switch (action.type) {
      case 'add': {
        const newArr = [ ...arr, ...action.arr]
        return newArr
      }
      case 'delete': {
        const deleteArr = action.arr

        const newArr = arr.filter((_item) => {
            return deleteArr.some(delItem => {
              return delItem[key] === _item[key]
            })
        })
        return newArr
      }
      case 'update': {
        const updateArr = action.arr

        const newArr = arr.map((_item) => {
            const newItem = updateArr.find((updateItem) => {
              return _item[key] === updateItem[key]
            }) || {}
            return {..._item, ...newItem}
        })
        return newArr
      }
      case 'reset': {
        const newArr = action.arr || initArr
        return newArr
      }
      default:
        throw new Error();
    }
    }
    const [arr, dispatchArr] = useReducer(paramsReducer, initArr)
  
    return [arr, dispatchArr]
}