import intl from 'react-intl-universal';
import {Button, Input, DatePicker, Select, message, Divider} from 'antd';
import {Link} from 'react-router-dom';
import React from 'react';
import {connect} from 'react-redux';
import useTable from '../../../hooks/useTable';
import moment from 'moment';
import ConditionPanel from '../../../components/ConditionPanel';
import CommonTable from '../../../components/CommonTable';
import {RANGES} from '../../common/DatePickerRanges';
import {useParams} from '../../../hooks/utils';
import {ORDER_STATUS, imageDomain} from '../../global/constants';
import {exportExcel} from './service';
import {downLoad} from '../../../helper/until';
import CryptoJS from 'crypto-js';

const {RangePicker} = DatePicker;

const ChannelPoliciesList = (props) => {
    const [searchParam, updateSearchParam] = useParams({
        insure_no: '',
        insurer_name: '',
        channel_name:'',
        insurer_credential:'',
        insurance_source: -1,
        order_status: -1,
        policyholders_name: '',
        product_name: '',
        time_from: moment().subtract('week', 1).startOf('day').valueOf(),
        time_to: moment().endOf('day').valueOf(),
    });
    const {tableProps, search} = useTable(
        {
            url: '/order',
            params: searchParam,
        },
        {
            pagination: true,
            pageSize: 20,
        }
    );

    const handleTimeChange = (date) => {
        updateSearchParam({
            // time_from: date[0].startOf('day').valueOf(),
            // time_to: date[1].endOf('day').valueOf(),
            time_from: date[0].startOf('day').add('hours',1).valueOf(),
            time_to: date[1].endOf('day').add('hours',1).valueOf(),
        });
    };

    // const downPdf = (r) => {
    //     if (r.insure_no) {
    //         let insure_no = r.insure_no?.replace(/\//g, '-');
    //         let url = imageDomain + 'sai/' + insure_no + '.pdf';
    //         window.open(url);
    //     } else {
    //         message.warning('文件不存在');
    //     }
    // };

    const encodeAesString = (data) => {
        let key = CryptoJS.enc.Utf8.parse("abcdef0123456789");
        let iv = CryptoJS.enc.Utf8.parse("abcdef0123456789");
        let encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        //返回的是base64格式的密文 
        // debugger
        return encrypted.toString();
    }

    const downPdf = (r) => {
        if (r.insure_no) {
            // let insure_no = r.insure_no?.replace(/\//g, '-');
            // let ins = encodeAesString(insure_no);
            // let url = imageDomain + 'sai/' + ins + '.pdf';

            let ins = encodeAesString(r.insure_no);
            let insure_no = (ins?.replace(/\//g, '-')).replaceAll('+','-');
            // let ins = encodeAesString(insure_no);
            let url = imageDomain + 'sai/' + insure_no + '.pdf';

            window.open(url);
        } else {
            message.warning('文件不存在');
        }
    };

    const exportExcelRequest = async () => {
        let type = 'station';
        if (props.userInfo.is_channel_user) {
            type = 'channel';
        }
        if (props.userInfo.is_company_user) {
            type = 'company';
        }
        if (props.userInfo.is_station) {
            type = 'station';
        }
        searchParam.type = type;
        exportExcel(searchParam, (data) => {
            downLoad(data, '数据', '.xls', 'application/vnd.ms-excel');
            message.success(intl.get('exportSuccess'));
        });
    };

    const columns = [
        {title: '#', dataIndex: 'index', key: 'index'},
        { title: intl.get('order_no.'), dataIndex: 'order_no', key: 'order_no' },
        {title: intl.get('insurer_no'), dataIndex: 'insure_no', key: 'insure_no'},
        {title: intl.get('insurer'), dataIndex: 'insurer_name', key: 'insurer_name'},
        {title: intl.get('policyholders'), dataIndex: 'policyholders_name', key: 'policyholders_name'},
        {
            title: intl.get('insurer_time'),
            dataIndex: 'time_create',
            key: 'time_create',
            render: (t, r) => <span>{t ? moment(t).subtract(1, 'hours').format('YYYY-MM-DD HH:mm:ss') : '--'}</span>,
        },
        {
            title: intl.get('order_status'),
            dataIndex: 'order_status',
            key: 'order_status',
            render: (t, r) => <span> {intl.get(ORDER_STATUS.properties[t]?.text)}</span>,
        },
        {title: intl.get('product_name'), dataIndex: 'product_name', key: 'product_name'},
        {title: intl.get('channel_name'), dataIndex: 'channel_name', key: 'channel_name'},
        {
            title: intl.get('Insurance_start'),
            dataIndex: 'time_effect',
            key: 'time_effect',
            render: (t, r) => <span>{r.time_effect ? (moment(r.time_effect).format('YYYY-MM-DD') +" 00:00:00"): '--'}</span>,
        },
        {
            title: intl.get("Insurance_expiry"),
            dataIndex: 'time_invalid',
            key: 'time_invalid',
            render: (t, r) =>
                <span>{r.time_invalid ? (moment(r.time_invalid).format('YYYY-MM-DD') + " 23:59:59" ): '--'}</span>,
        },
        {
            title: intl.get("flightNo"),
            dataIndex: 'flight_no',
            key: 'flight_no'
        },
        // {
        //     title: intl.get('insurer') + intl.get('credential'),
        //     dataIndex: 'insurer_credential',
        //     key: 'insurer_credential',
        // },
        {
            title: intl.get('operation'),
            dataIndex: 'action',
            key: 'action',
            className: props.userInfo.is_channel_user ? 'hide-columns' : '',
            render: (t, r) => (
                <>
                    <Link to={'/page/insurance_channel_detail?id=' + r.id}>{intl.get('btn_detail')}</Link>
                    <Divider type='vertical'/>
                    <span onClick={() => downPdf(r)} className='font-link'>
            {intl.get('btn_download')}
          </span>
                </>
            ),
        },
    ];

    return (
        <div>
            <ConditionPanel labelWidth={90}>
                <ConditionPanel.Item label={intl.get('insurer_no') + ':'}>
                    <Input
                        value={searchParam.insure_no}
                        onChange={(e) => updateSearchParam({insure_no: e.target.value})}
                        style={{width: '100%'}}
                        placeholder={intl.get('placeholder')}
                        allowClear
                    />
                </ConditionPanel.Item>
                <ConditionPanel.Item label={intl.get('insurer') + ':'}>
                    <Input
                        value={searchParam.insurer_name}
                        onChange={(e) => updateSearchParam({insurer_name: e.target.value})}
                        style={{width: '100%'}}
                        placeholder={intl.get('placeholder')}
                        allowClear
                    />
                </ConditionPanel.Item>
                <ConditionPanel.Item label={intl.get('policyholders') + ':'}>
                    <Input
                        value={searchParam.policyholders_name}
                        onChange={(e) => updateSearchParam({policyholders_name: e.target.value})}
                        style={{width: '100%'}}
                        placeholder={intl.get('placeholder')}
                        allowClear
                    />
                </ConditionPanel.Item>
                <ConditionPanel.Item label={intl.get('order_status') + ':'}>
                    <Select value={searchParam.order_status} style={{width: '100%'}}
                            onChange={(e) => updateSearchParam({order_status: e})}>
                        <Select.Option key={-1} value={-1}>
                            {intl.get('All')}
                        </Select.Option>
                        {Object.values(ORDER_STATUS.properties).map((e) => (
                            <Select.Option key={e.key} value={e.key}>
                                {intl.get(e.text)}
                            </Select.Option>
                        ))}
                    </Select>
                </ConditionPanel.Item>
                <ConditionPanel.Item label={intl.get('product_name') + ':'}>
                    <Input
                        value={searchParam.product_name}
                        onChange={(e) => updateSearchParam({product_name: e.target.value})}
                        style={{width: '100%'}}
                        placeholder={intl.get('placeholder')}
                        allowClear
                    />
                </ConditionPanel.Item>



                <ConditionPanel.Item label={intl.get('channel_name') + ':'}>
                    <Input
                        value={searchParam.channel_name}
                        onChange={(e) => updateSearchParam({channel_name: e.target.value})}
                        style={{width: '100%'}}
                        placeholder={intl.get('placeholder')}
                        allowClear
                    />
                </ConditionPanel.Item>

                {/* <ConditionPanel.Item label={intl.get('insurer_credential') + ':'}>
                    <Input
                        value={searchParam.insurer_credential}
                        onChange={(e) => updateSearchParam({insurer_credential: e.target.value})}
                        style={{width: '100%'}}
                        placeholder={intl.get('placeholder')}
                        allowClear
                    />
                </ConditionPanel.Item> */}


                <ConditionPanel.Item label={intl.get('Insured_range')}>
                    <RangePicker
                        format='DD-MM-YYYY'
                        defaultValue={[moment().subtract('week', 1).startOf('day'), moment().endOf('day')]}
                        onChange={handleTimeChange}
                        ranges={RANGES}
                    />
                </ConditionPanel.Item>
                <ConditionPanel.Item enterSearch>
                    <Button type='primary' block className='margin-right-8' onClick={() => search(1)}>
                        {intl.get('btn_search')}
                    </Button>
                    <Button type='primary' block onClick={exportExcelRequest}>
                        {intl.get('btn_export')}
                    </Button>
                </ConditionPanel.Item>
            </ConditionPanel>
            <CommonTable columns={columns} {...tableProps} />
        </div>
    );
};
const mapStateToProps = (state) => {
    const {GlobalReducer} = state;
    return {
        userInfo: GlobalReducer.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelPoliciesList);
