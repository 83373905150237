import React, {useState, useEffect} from 'react';
import intl from 'react-intl-universal';
import {Button, Input, Row, Col, Modal, message, Select, TreeSelect} from 'antd';
import useTable from '../../../hooks/useTable';
import ConditionPanel from '../../..//components/ConditionPanel';
import CommonTable from '../../../components/CommonTable';
import TemplateForm from '../../common/TemplateForm';
import moment from 'moment';
import {isMobileBrowser} from '../../../helper/until';
import {useParams} from '../../../hooks/utils';
import {createCompany, updateCompany, delCompany, getCompanyUser, createCompanyUser, delCompanyUser} from './service';
import {getAllCompany} from '../../service';
import Description from '../../common/Description';
import {connect} from "react-redux";

const CompanyConfigList = (props) => {
    const [allCompany, setAllCompany] = useState([]);
    const [visible, setVisible] = useState(false);
    const [type, setType] = useState('add');
    const [name, setName] = useState('');
    const [userList, setUserList] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedUserRowKeys, setSelectedUserRowKeys] = useState([]);
    const [param, updateParam] = useParams({
        company_name: '',
        company_nick_name: '',
        email: '',
        // parent_id: '',
        phone: '',
        user_name: '',
    });
    const {tableProps, search, selectedKeys, data} = useTable(
        {
            url: '/company',
            params: {
                name: name,
            },
        },
        {
            pagination: true,
            noFetch: true,
            rowSelection: true,
        }
    );

    const userData = useTable(
        {
            url: '/company_user',
            params: {
                name: '',
                nick_name: '',
            },
        },
        {
            pagination: false,
        }
    );

    useEffect(() => {
        getAllList();
    }, []);

    const getAllList = () => {
        getAllCompany((data) => {
            setAllCompany(data);
        });
    };

    const columns = [
        {title: intl.get('name'), dataIndex: 'company_name', key: 'company_name'},
        {title: intl.get('abbreviation'), dataIndex: 'company_nick_name', key: 'company_nick_name'},
        // { title: '上级公司', dataIndex: 'parent_name', key: 'parent_name' },
        {title: intl.get('contact'), dataIndex: 'user_name', key: 'user_name'},
        {title: intl.get('phone'), dataIndex: 'phone', key: 'phone'},
        {title: intl.get('email'), dataIndex: 'email', key: 'email'},
        {
            title: intl.get('time_create'),
            dataIndex: 'time_create',
            key: 'time_create',
            render: (t) => <span>{t ? moment(t).format('YYYY-MM-DD HH:mm') : '--'}</span>,
        },
    ];

    const toggleModal = (type) => {
        updateParam({
            company_name: '',
            company_nick_name: '',
            email: '',
            // parent_id: '',
            phone: '',
            user_name: '',
        });
        if (type === 'edit' || type === 'user') {
            if (selectedKeys.length !== 1) {
                message.warning(intl.get('onlyOneByEdit'));
                return false;
            }
            let item = data.find((m) => m.id === selectedKeys[0]);
            updateParam(item);
            if (type === 'user') {
                //TODO:看下返回结果什么样
                getCompanyUser(item.id, (data) => {
                    setUserList(data || []);
                });
            }
        }
        setVisible(!visible);
        setType(type);
    };

    const saveItem = () => {
        if (!param.company_name || param.company_name == '') {
            message.warning(intl.get('nameIsRequired'));
            return false;
        }
        if (!param.company_nick_name || param.company_nick_name == '') {
            message.warning(intl.get('abbreviationIsRequired'));
            return false;
        }
        if (!param.phone || param.phone == '') {
            message.warning(intl.get('phoneIsRequired'));
            return false;
        }
        if (!param.user_name || param.user_name == '') {
            message.warning(intl.get('contactIsRequired'));
            return false;
        }
        if (!param.email || param.email == '') {
            message.warning(intl.get('emailIsRequired'));
            return false;
        }
        // if (!param.parent_id || param.parent_id == '') {
        //   message.warning('请先选择上级菜单');
        //   return false;
        // }
        if (type === 'add') {
            createCompany(param, () => {
                message.success(intl.get('createSuccess'));
                search(1);
                getAllList();
                toggleModal();
            });
        } else {
            param.id = selectedKeys[0];
            updateCompany(param, () => {
                message.success(intl.get('editSuccess'));
                search(1);
                getAllList();
                toggleModal();
            });
        }
    };

    const delList = () => {
        if (!selectedKeys || selectedKeys.length == 0) {
            message.warning(intl.get('empty'));
            return false;
        }
        delCompany(selectedKeys, () => {
            message.success(intl.get('deleteSuccess'));
            search(1);
        });
    };

    const addUser = () => {
        let a = [...userList];
        a.push({
            name: '',
            nick_name: '',
            user_id: '',
        });
        setUserList(a);
    };

    //user
    const onChangeUser = (value, index) => {
        let a = [...userList];
        let item = a[index];
        let userItem = userData.data.find((m) => m.id === value);
        item.user_id = value;
        item.name = userItem ? userItem.name : '';
        item.nick_name = userItem ? userItem.nick_name : '';
        setUserList(a);
    };

    const createChannelUserItem = () => {
        if (!selectedUserRowKeys || selectedUserRowKeys.length === 0) {
            message.warning(intl.get('empty'));
            return false;
        }
        let item = [];
        selectedUserRowKeys.map((e, i) => {
            item.push(userList[e]);
        });
        let a = item.filter((e) => !e.id && e.user_id);
        let ids = [];
        a.forEach((e) => {
            ids.push(e.user_id);
        });
        if (a && a.length > 0) {
            createCompanyUser(param.id, ids, () => {
                userData.search();
                message.success(intl.get('createSuccess'));
            });
        } else {
            message.warning(intl.get('empty'));
        }
    };

    const delChannelUserItem = () => {
        if (!selectedUserRowKeys || selectedUserRowKeys.length === 0) {
            message.warning(intl.get('empty'));
            return false;
        }
        let a = userList.filter((e) => selectedUserRowKeys.includes(e.id));
        delCompanyUser(param.id, selectedUserRowKeys, () => {
            setUserList(a);
            message.success(intl.get('deleteSuccess'));
        });
    };
    return (
        <div>
            <ConditionPanel labelWidth={90}>
                <ConditionPanel.Item label={intl.get('name') + ':'}>
                    <Input
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                        style={{width: '100%'}}
                        placeholder={intl.get('placeholder')}
                        allowClear
                    />
                </ConditionPanel.Item>
                <ConditionPanel.Item enterSearch>
                    <Button type='primary' onClick={() => search(1)} block>
                        {intl.get('btn_search')}
                    </Button>
                </ConditionPanel.Item>
            </ConditionPanel>

            {props.userInfo.is_station && (
                <Row className='margin-bottom-5'>
                    <Col>
                        <Button type='primary' size='small' onClick={() => toggleModal('add')}>
                            {intl.get('btn_add')}
                        </Button>
                        <Button className='margin-left-5' type='primary' size='small'
                                onClick={() => toggleModal('edit')}>
                            {intl.get('btn_edit')}
                        </Button>
                        <Button className='margin-left-5' type='primary' size='small' onClick={delList}>
                            {intl.get('btn_del')}
                        </Button>
                    </Col>
                </Row>)
            }

            <CommonTable columns={columns} {...tableProps} />
            {/* 操作 */}
            <Modal
                width={isMobileBrowser() ? '100%' : '50%'}
                title={intl.get('operation')}
                visible={visible && type !== 'user'}
                destroyOnClose={true}
                maskClosable={false}
                onOk={saveItem}
                onCancel={toggleModal}
            >
                <TemplateForm
                    title={intl.get('name')}
                    span={[8, 12, 0]}
                    condition={<Input value={param.company_name}
                                      onChange={(e) => updateParam({company_name: e.target.value})}/>}
                />
                <TemplateForm
                    title={intl.get('abbreviation')}
                    span={[8, 12, 0]}
                    condition={<Input value={param.company_nick_name}
                                      onChange={(e) => updateParam({company_nick_name: e.target.value})}/>}
                />
                {/* <TemplateForm
          title='上级公司'
          span={[8, 12, 0]}
          condition={
            <TreeSelect
              style={{ width: '100%' }}
              value={param.parent_id}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={allCompany}
              placeholder='Please select'
              treeDefaultExpandAll
              onChange={(e) => updateParam({ parent_id: e })}
            />
          }
        /> */}
                <TemplateForm
                    title={intl.get('contact')}
                    span={[8, 12, 0]}
                    condition={<Input value={param.user_name}
                                      onChange={(e) => updateParam({user_name: e.target.value})}/>}
                />
                <TemplateForm
                    title={intl.get('phone')}
                    span={[8, 12, 0]}
                    condition={<Input value={param.phone} onChange={(e) => updateParam({phone: e.target.value})}/>}
                />
                <TemplateForm
                    title={intl.get('email')}
                    span={[8, 12, 0]}
                    condition={<Input value={param.email} onChange={(e) => updateParam({email: e.target.value})}/>}
                />
            </Modal>
            <Modal
                width={isMobileBrowser() ? '100%' : '50%'}
                title={intl.get('operation')}
                visible={visible && type === 'user'}
                destroyOnClose={true}
                maskClosable={false}
                onCancel={toggleModal}
                footer={null}
            >
                <Description label={intl.get('name')} span={24}>
                    {param.company_name}
                </Description>
                <Row className='margin-top-16'>
                    <Col className='margin-bottom-5'>
                        <Button type='primary' size='small' onClick={createChannelUserItem}>
                            {intl.get('btn_add')}
                        </Button>
                        <Button className='margin-left-5' type='primary' size='small' onClick={delChannelUserItem}>
                            {intl.get('btn_del')}
                        </Button>
                    </Col>
                    <Col>
                        <CommonTable
                            columns={[
                                {
                                    title: intl.get('user_name'),
                                    dataIndex: 'user_id',
                                    key: 'user_id',
                                    render: (t, r, i) => (
                                        <Select style={{width: '100px'}} value={t} disabled={r.id ? true : false}
                                                onChange={(e) => onChangeUser(e, i)}>
                                            {userData.data.map((e) => (
                                                <Select.Option key={e.id} value={e.id}>
                                                    {e.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    ),
                                },
                                {title: intl.get('nickName'), dataIndex: 'nick_name', key: 'nick_name'},
                            ]}
                            pagination={false}
                            dataSource={userList}
                            rowSelection={{
                                onChange: (selectedRowKeys, selectedRows) => {
                                    setSelectedUserRowKeys(selectedRowKeys);
                                },
                            }}
                        />
                        <Button block type='dashed' className='margin-top-5' onClick={addUser}>
                            {intl.get('btn_add')}
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state) => {
    const {GlobalReducer} = state;
    return {
        userInfo: GlobalReducer.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(CompanyConfigList);
