import React, {useState, useEffect} from 'react';
import intl from 'react-intl-universal';
import {Button, Input, Select, message, TreeSelect} from 'antd';
import useTable from '../../../hooks/useTable';
import ConditionPanel from '../../..//components/ConditionPanel';
import CommonTable from '../../../components/CommonTable';
import {useParams} from '../../../hooks/utils';
import {CLASSIFY, USE_STATUS} from '../../global/constants';
import {getAllCompany} from '../../service';
import {getWebLink, getMobileLink} from './service';
import copy from 'copy-to-clipboard';

const InsuredNavList = (props) => {
    const [allCompany, setAllCompany] = useState([]);
    const [searchParam, updateSearchParam] = useParams({
        classify: '',
        classify_sub: '',
        insure_company_id: '',
        product_code: '',
        product_name: '',
        use_status: '',
    });
    const {tableProps, search} = useTable(
        {
            url: '/product/insure',
            params: searchParam,
        },
        {
            pagination: true,
            noFetch: true,
        }
    );

    useEffect(() => {
        getAllCompany((data) => {
            setAllCompany(data);
        });
    }, []);

    const getLinks = (type, id) => {
        if (type === 'PC') {
            getWebLink(id, (data) => {
                copy(data);
                message.success(intl.get('copySuccess'));
            });
        } else {
            getMobileLink(id, (data) => {
                copy(data);
                message.success(intl.get('copySuccess'));
            });
        }
    };

    const columns = [
        {title: '#', dataIndex: 'index', key: 'index', render: (t, r, i) => <span>{i + 1}</span>},
        {title: intl.get('product_code'), dataIndex: 'product_code', key: 'product_code'},
        {title: intl.get('product_name'), dataIndex: 'product_name', key: 'product_name'},
        {
            title: intl.get('classify'),
            dataIndex: 'classify',
            key: 'classify',
            render: (t, r) =>
                <span>{CLASSIFY.properties[t * 1] ? intl.get(CLASSIFY.properties[t * 1].text) : '--'}</span>,
        },
        {
            title: intl.get('classify_sub'),
            dataIndex: 'classify_sub',
            key: 'classify_sub',
            render: (t, r) => {
                let CLASSIFY_SUB = CLASSIFY.properties[r.classify * 1] ? CLASSIFY.properties[r.classify * 1].sub : [];
                let item = CLASSIFY_SUB ? CLASSIFY_SUB.find((m) => m.key === t * 1) : {};
                return <span>{item ? intl.get(item.text) : '--'}</span>;
            },
        },
        {
            title: intl.get('company'),
            dataIndex: 'insure_company_id',
            key: 'insure_company_id',
            render: (t) => {
                const item = allCompany.find((m) => m.id === t);
                return <span>{item ? item.company_name : '--'}</span>;
            },
        },
        {
            title: intl.get('status'),
            dataIndex: 'use_status',
            key: 'use_status',
            render: (t, r) =>
                <span>{USE_STATUS.properties[t ? 1 : 0] ? intl.get(USE_STATUS.properties[t ? 1 : 0].text) : '--'}</span>,
        },
        {
            title: intl.get('operation'),
            dataIndex: 'action',
            key: 'action',
            render: (t, r) => (
                <>
                    <Button size='small' type='primary' className='margin-right-5' onClick={() => getLinks('PC', r.id)}>
                        {intl.get('pcLink')}
                    </Button>
                    <Button size='small' type='primary' onClick={() => getLinks('PC', r.id)}>
                        {intl.get('mobileLink')}
                    </Button>
                </>
            ),
        },
    ];

    return (
        <div>
            <ConditionPanel labelWidth={90}>
                <ConditionPanel.Item label={intl.get('product_code') + ':'}>
                    <Input onChange={(e) => updateSearchParam({product_code: e.target.value})} style={{width: '100%'}}
                           placeholder={intl.get('placeholder')} allowClear/>
                </ConditionPanel.Item>
                <ConditionPanel.Item label={intl.get('classify') + ':'}>
                    <Select style={{width: '100%'}}
                            onChange={(e) => updateSearchParam({classify: e, classify_sub: ''})}>
                        {Object.values(CLASSIFY.properties).map((e) => (
                            <Select.Option key={e.key} value={e.key}>
                                {intl.get(e.text)}
                            </Select.Option>
                        ))}
                    </Select>
                </ConditionPanel.Item>
                <ConditionPanel.Item label={intl.get('classify_sub') + ':'}>
                    <Select style={{width: '100%'}} onChange={(e) => updateSearchParam({classify_sub: e})}>
                        {(CLASSIFY.properties[searchParam.classify] ? CLASSIFY.properties[searchParam.classify].sub : []).map((e) => (
                            <Select.Option key={e.key} value={e.key}>
                                {intl.get(e.text)}
                            </Select.Option>
                        ))}
                    </Select>
                </ConditionPanel.Item>
                <ConditionPanel.Item label={intl.get('product_name') + ':'}>
                    <Input onChange={(e) => updateSearchParam({product_name: e.target.value})} style={{width: '100%'}}
                           placeholder={intl.get('placeholder')} allowClear/>
                </ConditionPanel.Item>
                <ConditionPanel.Item label={intl.get('company') + ':'}>
                    <TreeSelect
                        style={{width: '100%'}}
                        value={searchParam.insure_company_id}
                        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                        treeData={allCompany}
                        placeholder='Please select'
                        treeDefaultExpandAll
                        onChange={(e) => updateSearchParam({insure_company_id: e})}
                    />
                </ConditionPanel.Item>
                <ConditionPanel.Item label={intl.get('status') + ':'}>
                    <Select defaultValue={searchParam.use_status ? 1 : 0}
                            onChange={(e) => updateSearchParam({use_status: e})} style={{width: '100%'}}>
                        {Object.values(USE_STATUS.properties).map((e) => (
                            <Select.Option key={e.key} value={e.key}>
                                {intl.get(e.text)}
                            </Select.Option>
                        ))}
                    </Select>
                </ConditionPanel.Item>
                <ConditionPanel.Item enterSearch type='end'>
                    <Button type='primary' block onClick={() => search(1)}>
                        {intl.get('btn_search')}
                    </Button>
                </ConditionPanel.Item>
            </ConditionPanel>
            <CommonTable columns={columns} {...tableProps} />
        </div>
    );
};

export default InsuredNavList;
